import { useEventsQuery } from "../../../../generated/graphql"
import { range } from "../../../../services/Utils"
import transformEvents from "./transformEvents"

export default function useGetCircus2024Sessions() {
    const ids = range(1583, 1596).map(id => `${id}`)
    const { data } = useEventsQuery({ variables: { paging: { limit: 999 }, filter: { id: { in: ids } } } })
    return transformEvents(data)
}

