import { Grid } from '@material-ui/core';
import React from 'react';
import homepage_desktop_hero from '../../assets/images/o_coliseu/espacos/homepage/hero_desktop.webp';
import homepage_mobile_hero from '../../assets/images/o_coliseu/espacos/homepage/hero_mobile.webp';
import { useGtXs } from '../../hooks/useGtXs';
import useSpaces from '../../hooks/useSpaces';
import ActionButton from '../ActionButton/ActionButton';
import Hero from '../Hero/Hero';
import MoreSpaces from '../MoreSpaces/MoreSpaces';
import Typography from '../Typography/Typography';
import styles from './SpacesView.module.scss';

export default function SpacesView() {

    const { spaces } = useSpaces()
    const gtXs = useGtXs()
    const space = spaces[0]

    const homepageHero = {
        xs: homepage_mobile_hero,
        gtXs: homepage_desktop_hero
    }

    const description = "Uma das maiores e mais nobres salas de Portugal, o Coliseu Porto Ageas inaugurou em 1941 e mantém-se como um ícone do Modernismo, classificado pelo Estado português como Monumento de Interesse Público. Localizado em pleno centro do Porto, aqui convergem públicos de todas as idades, origens e gostos culturais, em eventos de todos os géneros e para diferentes dimensões de lotação. Por este palco já passaram os maiores artistas de sempre, nas mais diversas áreas e estéticas. Desde Bob Dylan a Barack Obama, muitos foram os espétaculos e os momentos. Mais de 80 anos de história e experiência, onde o património se funde com o conforto e a tecnologia moderna. Ingredientes únicos que transformam cada evento numa experiência inesquecível."

    const { name, action, url } = space.actions[0]
    const variant = gtXs ? "RocGroteskMedium25" : "RocGroteskMedium20"

    return (
        <div className={styles.Wrapper}>
            <Hero images={homepageHero} />
            <Grid container justify="center">
                <main>

                    <MoreSpaces id={[3, 4, 5]} itemsPerRow={gtXs ? 3 : 1} />
                    <MoreSpaces id={[1, 2]} itemsPerRow={gtXs ? 3 : 1} />

                    <Grid container className={styles.Description}>
                        <Typography variant={variant as any}>
                            {description}
                        </Typography>
                    </Grid>

                    <Grid container justify="center" className={styles.Action}>
                        <ActionButton name={name} action={action} url={url} fullWidth />
                    </Grid>

                </main>
            </Grid>
        </div>
    )
}
