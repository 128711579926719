import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from "apollo-link-http";
import { Config } from "./Config";

const httpLink = createHttpLink({
    uri: Config.GRAPHQL_API
});

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: httpLink,
    resolvers: {
        Mutation: {
            setMenu: (_root, variables, { cache }) => {
                const state = variables.state;;
                cache.writeData({ data: { isMenuOpen: state } });
            }
        },
    }
});

export default client;
