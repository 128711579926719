import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useGtXs } from '../../hooks/useGtXs';
import OutlineButton from '../OutlineButton/OutlineButton';
import Paragraph from '../Paragraph/Paragraph';
import styles from './MantrasView.module.scss';

export default function MantrasView() {

    const url = "https://mantras.coliseu.pt"

    const gtXs = useGtXs()

    useEffect(() => {
        const timer = setTimeout(() => {
            window.open(url);
        }, 2000)

        return () => clearTimeout(timer)
    }, [])

    const variant = gtXs ? "RocGroteskBold25" : "RocGroteskBold20"

    return (
        <div>
            <div className={styles.SectionBanner} />
            <Grid container justify="center" className={styles.Wrapper}>
                <main>
                    <Grid container direction="column" justify="center">
                        <div>
                            <Paragraph variant={variant} style={{ textAlign: "center" }}>
                                Bem-vindo/a aos Mantras do Coliseu!
                            </Paragraph>
                            <Paragraph variant={variant} style={{ textAlign: "center" }}>
                                Será redirecionado/a para a página onde poderá ouvir as composições originais de Pedro Abrunhosa, Manel Cruz, Pedro Burmester & Ricardo Burmester, Surma, Noiserv, Carlos Azevedo e Alexandre Soares.
                            </Paragraph>
                        </div>
                        <div>
                            <Paragraph variant={variant} style={{ textAlign: "center" }}>
                                Se isso não acontecer automaticamente, clique no botão em baixo.
                            </Paragraph>
                        </div>
                        <Grid container justify="center" className={styles.Action}>
                            <OutlineButton
                                name="Mantras Coliseu"
                                route={url}
                                fullWidth={false}
                            />
                        </Grid>
                    </Grid>
                </main>
            </Grid>
        </div>
    )
}
