import { Grid } from '@material-ui/core';
import React from 'react';
import Paragraph from '../Paragraph/Paragraph';
import Typography from '../Typography/Typography';
import styles from './CookiesView.module.scss';

export default function CookiesView() {
    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid item xs={12} sm={4} className={styles.Image} />
            <Grid item xs={12} sm={8} className={styles.Text}>
                <main>

                    <Typography variant="h1colisseum">
                        Política de Utilização de Cookies
		    </Typography>

                    <Typography variant="subtitle2">
                        A Associação Amigos Coliseu do Porto utiliza cookies no seu web site de forma a melhorar a experiência do utilizador.
		    </Typography>

                    <section>

                        <Typography variant="subtitle2">
                            O que são Cookies?
			</Typography>
                        <Paragraph>As cookies são pequenos ficheiros de texto que são copiados para o seu computador ou dispositivo móvel, quando acede ao web site, através do navegador (browser) que estiver a utilizar, com o objectivo de facilitar o reconhecimento do dispositivo em utilizações posteriores. Estes ficheiros permitem que, durante um certo período de tempo, o sítio Web se «lembre» das suas ações e preferências, nomeadamente do nome de utilizador, da língua escolhida e outras definições de visualização, melhorando assim a experiência de utilização do site.</Paragraph>
                        <Paragraph>O utilizador do web site tem a possibilidade de configurar o seu browser, de modo a que seja apresentada uma notificação sobre a recepção de cookies, assim como desactivar as cookies de forma total ou selectiva. Convém salientar que a inactivação das cookies pode afectar algumas funcionalidades das páginas.
			</Paragraph>
                        <Typography variant="subtitle2">
                            Cookies utilizadas no nosso web site
			</Typography>
                        <Paragraph>Persistentes ou Permanentes – Permitem uma navegação mais personalizada porque ficam guardados no browser utilizado e são acedidos sempre que o utilizador visita novamente o web site, encaminhando e ajudando na navegação, de acordo com as suas anteriores pesquisas.</Paragraph>
                        <Paragraph>Essenciais – Este tipo de ficheiros permite a navegação no web site e a utilização das suas funcionalidades, como aceder a áreas concretas ou áreas seguras que requerem autenticação. Se forem inactivados, as funcionalidades que os exigem deixam de estar disponíveis.</Paragraph>
                        <Paragraph>Analíticos – Permitem analisar a forma como os utilizadores navegam pelo web site, monitorizando o comportamento do mesmo e disponibilizam uma experiência mais eficiente e rápida, auxiliando a correcção de quaisquer problemas imediatamente. Através destas cookies, contabilizam-se dados como as páginas mais visitadas, os conteúdos mais visualizados, os fluxos de navegação e as mensagens de erro.</Paragraph>
                        <Typography variant="subtitle2">
                            Inactivação de Cookies
			</Typography>
                        <Paragraph>A autorização da utilização de cookies que tenha sido concedida pelo utilizador, pode ser inactivada a qualquer momento, de forma parcial ou total. Relembramos que esta inactivação poderá afectar algumas funcionalidades das páginas.</Paragraph>
                        <Typography variant="subtitle2">Gestão de Cookies</Typography>
                        <Paragraph>A possibilidade de activar, inactivar, ou eliminar cookies pode ser efectuada ao nível do browser. Para o fazer deve seguir as instruções indicadas para o seu browser (geralmente localizadas nas funcionalidades "Ajuda", "Ferramentas" ou "Editar"). Saiba que a inactivação de uma cookie ou de uma categoria de cookies, não as elimina do seu browser. Dependendo do browser que utiliza, pode efectuar ajustes nas configurações e eliminar as cookies instaladas.</Paragraph>
                        <Paragraph>Disponibilizamos instruções (links externos) de gestão de cookies para os browsers mais utilizados. Caso o seu browser não esteja aqui listado, deverá consultar essas opções na ajuda, manual, ferramentas ou no web site do browser que utiliza. A informação aqui apresentada é da exclusiva responsabilidade dos fabricantes dos browsers.</Paragraph>

                        <Paragraph>Google Chrome<br />
                            <a href="https://support.google.com">
                                https://support.google.com
			    </a>
                        </Paragraph>

                        <Paragraph>Internet Explorer<br />
                            <a href="https://support.microsoft.com">
                                https://support.microsoft.com
			    </a>
                        </Paragraph>

                        <Paragraph>Mozilla Firefox<br />
                            <a href="https://support.mozilla.org">
                                https://support.mozilla.org
			    </a>
                        </Paragraph>

                        <Paragraph>Safari<br />
                            <a href="https://support.apple.com">
                                https://support.apple.com
			    </a>
                        </Paragraph>

                        <Typography variant="subtitle2">
                            Contactos
			</Typography>

                        <Paragraph>Qualquer questão, reclamação ou pedido relacionado com a Política de Utilização de Cookies, deverá ser enviada por email para o endereço <a href="mailto:privacidade@coliseu.pt">privacidade@coliseu.pt</a></Paragraph>

                        <Paragraph>A Associação Amigos Coliseu do Porto poderá alterar, rectificar ou aditar termos na presente Política de Utilização de Cookies, de forma unilateral e em qualquer momento, sem necessidade de qualquer aviso prévio.</Paragraph>

                    </section>
                </main>
            </Grid>
        </Grid>
    )
}
