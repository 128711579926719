import React from "react";
import { eventStates } from "../../services/Utils";
import Typography from "../Typography/Typography";
import styles from './EventUnavailable.module.scss';

interface EventUnavailableProps {
    event: any
}

export default function EventUnavailable(props: EventUnavailableProps) {
    const { event } = props
    return (
        <Typography variant="body2" className={styles.Unavailable}>
            {eventStates(event.state)}
        </Typography>
    )
}
