import { Grid } from '@material-ui/core';
import React from 'react';
import Paragraph from '../Paragraph/Paragraph';
import Typography from '../Typography/Typography';
import styles from './ContactsView.module.scss';

export default function ContactsView() {
    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid item xs={12} sm={4} className={styles.Image} />
            <Grid item xs={12} sm={8} className={styles.Text}>
                <main>
                    <Typography variant="h1colisseum">
                        Contactos
		    </Typography>

                    <section>

                        <Paragraph>
                            Telefone (351) 223 394 940 (chamada para a rede fixa nacional)
			</Paragraph>

                        <Paragraph>
                            <b>E-mail</b><br />
			    coliseu@coliseu.pt<br />
                        </Paragraph>

                        <Paragraph>
                            <b>Morada</b><br />
			    Rua Passos Manuel 137<br />
			    4000-385 Porto<br />
                        </Paragraph>
                    </section>
                </main>
            </Grid>
        </Grid>
    )
}
