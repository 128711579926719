import { Grid } from '@material-ui/core';
import { clsx } from 'clsx';
import React, { useState } from 'react';
import { EduActivityPreview } from '../EduActivityPreview/EduActivityPreview';
import { activities, targetAudiences } from '../SchoolsView/Activities';
import styles from './EducationalActivitySearch.module.scss';
import filterByTargetAudience from './Helpers/filterActivities';

export default function EducationalActivitySearch() {

    const [selected, setSelected] = useState<number>(targetAudiences[0].id)

    const filteredActivities = filterByTargetAudience(activities, selected)

    return (
        <div>
            <Grid container className={styles.ActivitySearch} wrap="wrap">
                {targetAudiences.map(targetAudience => {
                    const isSelected = selected === targetAudience.id
                    const classes = clsx({ [styles.Selected]: isSelected })
                    return (
                        <Grid item container xs={4} sm={2}
                            alignContent="center"
                            justify="center"
                            className={classes}
                            onClick={() => setSelected(targetAudience.id)}
                        >
                            {targetAudience.name}
                        </Grid>
                    )
                })}
            </Grid>

            <Grid container justify="center" className={styles.ActivityList} wrap="wrap">
                {filteredActivities.map(
                    activity => <EduActivityPreview key={activity.id} {...activity} />
                )}
            </Grid>
        </div>
    )
}
