export default function getCarouselProps(gtXs: boolean) {

    const centerSlidePercentage = gtXs ? 33.333 : 100

    return {
        showThumbs: false,
        showArrows: false,
        centerMode: true,
        centerSlidePercentage,
        swipeable: true,
        autoPlay: false,
        infiniteLoop: false,
        dynamicHeight: false,
        showStatus: false,
        showIndicators: false
    }
}
