import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

// investigar isto:
// https://github.com/mui-org/material-ui/blob/master/packages/material-ui-icons/custom/Facebook.js
// https://github.com/mui-org/material-ui/blob/193de7b61573c318853465049e74f5ac9b714660/packages/material-ui-icons/src/utils/createSvgIcon.js#L4

const TicketIcon = (props: any) => (
    <SvgIcon {...props}>
        <path d="M14.667 24.494h-3.761c-0.561 0-1.022-0.442-1.046-1.002-0.031-0.725-0.623-1.293-1.347-1.293s-1.317 0.568-1.348 1.294c-0.024 0.56-0.485 1.002-1.046 1.002h-3.761c-0.578 0-1.047-0.469-1.047-1.047v-21.908c0-0.578 0.469-1.047 1.047-1.047h3.761c0.561 0 1.022 0.442 1.046 1.002 0.031 0.725 0.624 1.293 1.348 1.293s1.316-0.568 1.347-1.293c0.024-0.56 0.485-1.002 1.046-1.002h3.761c0.578 0 1.047 0.469 1.047 1.047v21.908c0 0.578-0.469 1.047-1.047 1.047zM11.76 22.4h1.86v-19.814h-1.86c-0.473 1.344-1.749 2.295-3.248 2.295s-2.775-0.951-3.248-2.295h-1.86v19.814h1.86c0.473-1.345 1.75-2.295 3.248-2.295s2.775 0.951 3.248 2.295zM11.169 16.89v-8.796c0-0.463-0.375-0.838-0.838-0.838s-0.838 0.375-0.838 0.838v8.796c0 0.463 0.375 0.838 0.838 0.838s0.838-0.375 0.838-0.838zM7.53 16.89v-8.796c0-0.463-0.375-0.838-0.838-0.838s-0.838 0.375-0.838 0.838v8.796c0 0.463 0.375 0.838 0.838 0.838s0.838-0.375 0.838-0.838z" />
    </SvgIcon>
);

export default TicketIcon;
