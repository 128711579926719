import { Grid } from '@material-ui/core'
import React from 'react'
import styles from './HomeFeatured.module.scss'

export default function HomeFeatured() {
    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid container component="section" justify="space-between">
                <div className={styles.Banner} />
                <div className={styles.Banner} />
            </Grid>
        </Grid>
    )
}
