import { Grid } from '@material-ui/core';
import React from 'react';
import Paragraph from '../Paragraph/Paragraph';
import Typography from '../Typography/Typography';
import styles from './BookingInformationView.module.scss';
import { Link } from '../Link/Link';

export default function BookingInformationView() {
    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid item xs={12} sm={4} className={styles.Image} />
            <Grid item xs={12} sm={8} className={styles.Text}>
                <main>
                    <Typography variant="h1colisseum">
                        Informações
                    </Typography>

                    <section>
                        <Paragraph>
                            Os pedidos de informações podem ser efetuados na bilheteira do Coliseu Porto Ageas,
                            presencialmente ou através do número de telefone: <a href="tel:+351223395090">223395090</a>&nbsp;
                            (chamada para a rede fixa nacional).
                        </Paragraph>

                        <Paragraph style={{ fontWeight: 700 }}>
                            Horário da bilheteira:
                        </Paragraph>

                        <Paragraph>
                            De segunda a sábado das 13h00 às 20h30.
                        </Paragraph>

                        <Paragraph>
                            Em dia de espetáculo até ao início do mesmo.
                        </Paragraph>

                        <Paragraph>
                            Horário de Agosto: de segunda a sexta, das 15h00 às 19h00. Em dia de espetáculo, das 15h00 até ao início do mesmo.
                        </Paragraph>

                        <Paragraph style={{ fontWeight: 700 }}>
                            Perguntas Frequentes
                        </Paragraph>

                        <Paragraph>
                            <i>Posso adquirir bilhetes online?</i>
                        </Paragraph>

                        <Paragraph>
                            Sim, o Coliseu trabalha com várias bilheteiras online, como a Ticketline ou a Blueticket,
                            cabendo ao promotor de cada espetáculo selecionar a bilheteira online da sua preferência.
                            Para adquirir bilhetes online, basta ir à secção <Link route="/agenda">Agenda</Link> do nosso site, entrar na página do
                            espetáculo pretendido e clicar em "Comprar".
                        </Paragraph>

                        <Paragraph style={{ paddingTop: 10 }}>
                            <i>Como posso saber se ainda há bilhetes disponíveis para um espetáculo?</i>
                        </Paragraph>

                        <Paragraph>
                            Poderá dirigir-se à nossa bilheteira ou contactar telefonicamente, mas é possível saber
                            online a disponibilidade para cada espetáculo e setor da sala. Basta ir à secção <Link route="/agenda">Agenda</Link> do
                            nosso site, entrar na página do espetáculo pretendido, clicar em "Comprar" e verificar
                            as disponibilidades na bilheteira online.
                        </Paragraph>

                        <Paragraph style={{ paddingTop: 10 }}>
                            <i>Preciso de imprimir os bilhetes comprados online?</i>
                        </Paragraph>

                        <Paragraph>
                            Não é necessário. Basta apresentar o bilhete eletrónico no seu telemóvel.
                        </Paragraph>

                        <Paragraph style={{ paddingTop: 10 }}>
                            <i>Não consigo ir a um espetáculo, posso trocar o meu bilhete?</i>
                        </Paragraph>

                        <Paragraph>
                            Não é possível a troca ou devolução de bilhetes. Agradecemos por isso a verificação dos bilhetes no ato da compra.
                        </Paragraph>

                        <Paragraph style={{ paddingTop: 10 }}>
                            <i>Quais os descontos disponíveis?</i>
                        </Paragraph>

                        <Paragraph>
                            Na secção <Link route="/agenda">Agenda</Link>, basta entrar na página do espetáculo para o qual pretende adquirir bilhete para conhecer
                            os descontos disponíveis. Os descontos são da responsabilidade do promotor de cada espetáculo.
                        </Paragraph>

                        <Paragraph>
                            Os portadores de bilhetes com desconto estão obrigados à apresentação dos documentos comprovativos
                            do direito ao desconto à entrada da sala. A sua não apresentação poderá inibir o acesso ao espetáculo.
                        </Paragraph>

                        <Paragraph style={{ paddingTop: 10 }}>
                            <i>Como posso ficar a par da agenda de espetáculos e atividades do Coliseu Porto Ageas?</i>
                        </Paragraph>

                        <Paragraph>
                            Pode subscrever gratuitamente a nossa <Link route="/newsletter/subscrever">Newsletter no nosso site</Link>.
                        </Paragraph>

                        <Paragraph>
                            Para saber informações sobre as normas da sala, <Link route="/normas-sala">clique aqui</Link>.
                        </Paragraph>

                    </section>
                </main>
            </Grid>
        </Grid >
    )
}
