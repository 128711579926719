import React from 'react';
import styles from './CustomButton.module.scss';
import classNames from 'classnames'

const CustomButton = ({ url, label, blank, thin }: { url: string, label: string, blank?: boolean, thin?: boolean }) => {
    const classes = [styles.Custom]
    const target = blank ? '_blank' : '_self'

    if (thin) {
        classes.push(styles.Thin)
    }

    return (
        <a href={url} className={classNames(classes)} target={target}>
            {label}
        </a>
    )
}

export default CustomButton;
