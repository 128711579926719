import { Dialog } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';
import { useHistory } from 'react-router';
import Typography from '../Typography/Typography';
import styles from './NewsletterConfirmationPopup.module.scss';

interface NewsletterConfirmationPopupProps {
    isOpen: boolean
    setIsOpen: (state: boolean) => void
}

export default function NewsletterConfirmationPopup(props: NewsletterConfirmationPopupProps) {
    const { isOpen, setIsOpen } = props
    const history = useHistory()

    const handleClick = () => {
        history.push("/")
    }

    return (
        <Dialog onClose={() => setIsOpen(false)} open={isOpen}>
            <div className={styles.Wrapper}>
                <div className={styles.Image} />
                <div className={styles.Content}>
                    <Typography variant="subtitle1">
                        Confirme a sua subscrição no seu email
		    </Typography>
                    <Typography variant="body1">
                        Se não encontrar, verifique na pasta de Spam
		    </Typography>
                    <Button onClick={handleClick} variant="outlined" fullWidth>
                        Voltar Homepage
		    </Button>
                </div>
            </div>
        </Dialog >

    )
}
