import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useGtXs } from '../../hooks/useGtXs';
import { Link } from '../Link/Link';
import getSections from '../Sitemap/Helpers/getSections';
import Typography from '../Typography/Typography';
import styles from './DesktopMenu.module.scss';
import isRouteActive from './Helpers/isRouteActive';

export default function DesktopMenu() {

    const gtXs = useGtXs()
    const location = useLocation()

    if (!gtXs) return null

    const path = location.pathname
    const sections = getSections()

    return (
        <Grid container justify="center" alignItems="center" className={styles.Menu}>
            {sections.map(({ id, route, name, subsections }) => {
                const classes = isRouteActive(path, route, subsections) ? styles.Active : null
                return (
                    <Typography variant="menu">
                        <Link key={id} name={name} route={route} className={classes} />
                    </Typography>
                )
            })}
        </Grid>
    )
}
