import { Grid } from '@material-ui/core';
import React from 'react';
import Paragraph from '../Paragraph/Paragraph';
import Typography from '../Typography/Typography';
import styles from './RoomNormsView.module.scss';

export default function RoomNormsView() {
    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid item xs={12} sm={4} className={styles.Image} />
            <Grid item xs={12} sm={8} className={styles.Text}>
                <main>
                    <Typography variant="h1colisseum">
                        Normas da Sala
 		    </Typography>

                    <Typography variant="h6">
                        O Coliseu Porto Ageas agradece a sua presença neste espetáculo e a atenção às seguintes regras de conduta:
 		    </Typography>

                    <section>

                        <Paragraph>
                            É da responsabilidade do espetador a verificação dos bilhetes no ato da compra.
 			</Paragraph>

                        <Paragraph>
                            Não se efetuam trocas ou reembolsos de bilhetes.
 			</Paragraph>

                        <Paragraph>
                            A classificação etária dos espetáculos deve ser respeitada.
 			</Paragraph>

                        <Paragraph>
                            Não é permitida a entrada na sala após o início do espetáculo, exceto no intervalo ou mediante autorização expressa dos assistentes, não sendo, no entanto, garantidos os lugares marcados.
 			</Paragraph>

                        <Paragraph>
                            De acordo com o Plano de Evacuação e Emergência do Coliseu Porto Ageas, não é permitida a entrada de objetos rígidos de transporte de crianças (carrinhos de bebé, babycoques, etc.) na sala de espetáculo. Estes devem ser depositados no Vestiário. Objetos suscetíveis de perturbar o público ou a realização do espetáculo, como sacos e volumes, devem ser igualmente depositados no Vestiário.
 			</Paragraph>

                        <Paragraph>
                            Não é permitida a entrada de alimentos ou bebidas no Coliseu.
			</Paragraph>

                        <Paragraph>
                            O consumo de alimentos ou bebidas dentro da sala está dependente das especificidades do espetáculo.
			</Paragraph>

                        <Paragraph>
                            Não é permitido fumar no Coliseu.
			</Paragraph>

                        <Paragraph>
                            Não é permitida a entrada de objetos que possam ser considerados perigosos.
			</Paragraph>

                        <Paragraph>
                            Não é permitida a entrada de animais.
			</Paragraph>

                        <Paragraph>
                            Nos camarotes ou frisas, o bilhete representa uma senha individual sem lugar marcado.
			</Paragraph>

                        <Paragraph>
                            Mantenha o telemóvel e outros aparelhos sonoros desligados durante o espetáculo.
			</Paragraph>

                        <Paragraph>
                            Não é permitido qualquer registo áudio ou vídeo durante os espetáculos.
			</Paragraph>

                        <Paragraph>
                            Os espetáculos podem ser filmados ou fotografados. O acesso ao Coliseu pressupõe o consentimento à captação de imagens do público e a utilização, direta ou indireta, pelo Coliseu Porto Ageas para fins institucionais, de comunicação, marketing, promocionais e informativas.
			</Paragraph>

                        <Paragraph>
                            A programação pode ser alterada por motivos imprevistos.
			</Paragraph>

                        <Paragraph>
                            A entidade emissora do bilhete não assume quaisquer obrigações e responsabilidades que competem ao promotor do espetáculo.
			</Paragraph>

                        <Paragraph>
                            A alteração da data ou cancelamento do espetáculo são da exclusiva responsabilidade do promotor, obrigando-se este a cumprir o disposto na legislação aplicável.
			</Paragraph>
                    </section>
                </main>
            </Grid>
        </Grid>
    )
}
