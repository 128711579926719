import { Grid } from '@material-ui/core'
import React from 'react'
import { Link } from '../Link/Link'
import { socialNetworks } from '../Supporters/Supporters'
import styles from './SocialNetworks.module.scss'

export default function SocialNetworks() {
    return (
        <Grid container justify="center" className={styles.SocialNetworks}>
            {socialNetworks.map(network => (
                <Link key={network.id} route={network.route}>
                    <img src={network.src} alt={network.alt} />
                </Link>
            ))}
        </Grid>
    )
}
