import { Config } from '../services/Config';
import moment from 'moment/moment.js'

export const htmlToText = (text: any) => {
    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
};

/** returns an absolute url to a media resource given the filename */
export const mediaUrl = (rel: any) => (
    Config.MEDIA_URL + rel
)

export const capitalize = (string: any) => {
    return string.replace(/^\w/, (c: any) => c.toUpperCase());
}

export const eventStates = (state: any, transform: any = 'capitalize', language: any = 'pt') => {
    if (!state) return null
    const states = {
        ACTIVE: {
            pt: 'ativo',
            en: 'active'
        },
        POSTPONED: {
            pt: 'adiado',
            en: 'postponed'
        },
        SOLDOUT: {
            pt: 'esgotado',
            en: 'soldout'
        },
        CANCELED: {
            pt: 'cancelado',
            en: 'canceled'
        }
    }
    // @ts-ignore
    const s: any = states[state]
    const output = s[language]
    return transform === 'capitalize' ? capitalize(output) : output;
}

/** Returns a list of numbers between start and end, inclusive */
export function range(start: number, end: number): number[] {
    const lst: number[] = []
    for (let i = start; i <= end; i++) {
        lst.push(i)
    }
    return lst
}

/** returns an array with archived years, where start year is 2016 and end year is current year*/
export function getArchivedYears(): number[] {
    const current = getCurrentYear()
    let archivedYears = range(2016, current)
    return archivedYears.reverse()
}

/** returns the current year, using local time */
export const getCurrentYear = (): number => {
    return new Date().getFullYear()
}

/** converts from seconds to hours and minutes */
export const durationFormatter = (seconds: number): string => {
    const m = seconds / 60;
    const hours = Math.floor(m / 60);
    const minutes = String(Math.ceil(m % 60));
    const paddedMinutes = minutes.padStart(2, '0');
    return `${hours}h${paddedMinutes}`
}

/** Returns the absolute path to a file in the api */
export const staticFile = (relative: string): string => {
    return Config.MEDIA_URL + relative
}

/**
* starts download of file from the frontend
* @param url - the url of file to download
* @param filename - the name of the file
*
* @example downloadRequest(url, 'folhasala.pdf')
*/
export const downloadRequest = (url: string, filename: string) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = function() {
        const type = xhr.getResponseHeader("content-type");
        const arrayBufferView = new Uint8Array(this.response);
        // @ts-ignore
        const blob = new Blob([arrayBufferView], { type });
        const anchor = document.createElement('a');
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = filename;
        anchor.click();
    };
    xhr.send();
}

/**
* truncates a string at the required length
*
* @param str - the string to truncate
* @param chars - the length at which the string will be truncated (will not take in account the ellipsis)
* @param ellipsis - apply ellipsis to the truncated string (the returned string will be longer than chars value)
*/
export const truncateString = (str: string, chars: number, ellipsis?: boolean): string => {
    if (str.length <= chars) {
        return str
    }
    const sliced = str.slice(0, chars)
    return ellipsis ? sliced + '...' : sliced
}

/**
* returns the correct absolute path use as background image regardless
* if is a media resource or a local (imported) resource
*
*
* limitation: assumes field in resource is called photoUrl (improve this)
*/
export const universalMedia = (resource: string | {}) => {
    // @ts-ignore
    return resource?.photoUrl ? mediaUrl(resource.photoUrl) : resource
}


export const errorParser = (response: any) => {

    let errors: any = {}

    const key = Object.keys(response.data)[0];
    const e = response.data[key].errors;

    if (e) {
        e.map((error: any) => errors[error.field] = error.message[0]);
    }

    return errors;
}

export const isLocalStorageAvailable = () => {
    const test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
}

/**
* uses unix timestamp, seconds since 1/1/1970
*/
export const nowTimestamp = () => {
    return moment().unix();
}

/**
 * avança os elementos de uma array
 * uma posição para a frente
 * o último elemento volta a entrar
 * no início da array
 *  [1,2,3] -> [3,1,2]
 */
export const shiftForwards = (array: any) => {
    if (!array?.length) return []
    const lastItem = array.pop();
    return [lastItem, ...array];
}

/**
 * recua os elementos de uma array
 * uma posição para a trás
 * o primeiro elemento volta a entrar
 * pelo fim da array
 *  [1,2,3] -> [2,3,1]
 */
export const shiftBackwards = (array: any) => {
    if (!array?.length) return []
    const [firstItem, ...rest] = array;
    return [...rest, firstItem];
};


export function normalizeAgeRating(input: string | null): string | null {
    if (input === null || input.trim() === '') {
        return null;
    }

    const normalizedInput = input.toUpperCase().trim();

    if (normalizedInput.includes("TODOS")) {
        return 'Todos';
    }

    const match = normalizedInput.match(/(\d+)/);
    if (match) {
        const age = parseInt(match[1], 10);
        return `M/${age}`;
    }

    return 'Outros'
}
