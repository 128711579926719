import React from 'react';
import { Helmet } from "react-helmet";
import { Event } from '../../generated/graphql';
import { Config } from '../../services/Config';
import { htmlToText } from '../../services/Utils';
import { MaybeDeepPartial } from "../../types/types";

interface EventDetailsHelmetProps {
    event?: MaybeDeepPartial<Event>
}

export default function EventDetailsHelmet(props: EventDetailsHelmetProps) {
    const { event } = props
    if (!event) return null
    return (
        <Helmet>
            <meta property="og:site_name" content="Coliseu Porto Ageas" />
            <meta property="og:url" content={`${Config.APP}evento/${event.slug}`} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={event.name} />
            <meta property="og:description" content={htmlToText(event.description)} />
            <meta property="og:image" content={`${Config.MEDIA_URL}${event.cover}`} />
            <meta property="og:image:width" content={String(event.coverWidth)} />
            <meta property="og:image:height" content={String(event.coverHeight)} />
        </Helmet>
    )
}
