import { Grid } from '@material-ui/core';
import React from 'react';
import mainBanner from '../../assets/images/educational/schools/schools_banner.webp';
import { Config } from '../../services/Config';
import ActivityCarousel from '../ActivityCarousel/ActivityCarousel';
import EducationalActivitySearch from '../EducationalActivitySearch/EducationalActivitySearch';
import OutlineButton from '../OutlineButton/OutlineButton';
import styles from './SchoolsView.module.scss';

export default function SchoolsView() {

    const url = Config.SCHOOLS_PROGRAM_2024_2025
    const isSearchActive = true

    const handleClick = () => {
        window.open(url)
    }

    return (
        <div>
            <ActivityCarousel />
            <Grid container justify="center" className={styles.Wrapper}>
                <main>
                    <Grid container spacing={2} className={styles.Introduction}>
                        <Grid item xs={12} sm={7} className={styles.Banner}>
                            <div>
                                <img src={mainBanner} alt="" />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={5} className={styles.Content}>
                            <Grid>


                                <p>
                                    O Serviço Educativo Coliseu completa um ano! Foram 365 dias repletos de experiências e
                                    aprendizagens com famílias, alunos e professores, que nos ajudaram a crescer e a inventar
                                    novas propostas para diversas idades, do pré-escolar até ao ensino técnico, superior e sénior.
                                    É na partilha que moldamos novas formas de aprender e levar a todos, de forma criativa, a vida mágica e
                                    intensa de um dos teatros mais importantes do país.
                                </p>

                                <p>
                                    Com o novo Programa Escolas 2024/2025, pretendemos continuar a criar laços vincados e duradouros,
                                    partilhar o nosso sentido de missão e proporcionar lugar e tempo para a educação e fruição artísticas,
                                    com atividades e conteúdos transformadores, capazes de inspirar e expandir horizontes.
                                </p>

                                <p><b>SEJA BEM-VINDO AO COLISEU DAS ESCOLAS.</b></p>
                                <p>Consulte o programa completo aqui:</p>

                                <div>
                                    <OutlineButton
                                        name="Programa Escolas 2024/25"
                                        onClick={handleClick}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                    {isSearchActive ? <EducationalActivitySearch /> : null}
                </main>
            </Grid>
        </div>
    )
}
