import { parseISO } from 'date-fns'
import { Event } from "../../../../generated/graphql"
import { abbrevDate, formatTime, weekdayPT } from "../../../../utils/dates"

export interface Session {
    id: string
    date: string
    time: string
    url: string
    isActive: boolean
}

export default function formatSession(event: Event): Session {

    if (!event || !event.startDate) {
        throw new Error("formatSession: startDate is required");
    }

    const dateObj = parseISO(event.startDate)
    const dateStr = abbrevDate(event.startDate, 'd MMM', "UPPER")
    const weekday = weekdayPT(dateObj)
    const { hours, minutes } = formatTime(dateObj)
    const timeStr = `${weekday}, ${hours}H${minutes}`

    const now = new Date()
    const isFutureEvent = dateObj.getTime() >= now.getTime()

    return {
        id: event.id,
        date: dateStr,
        time: timeStr,
        url: `/evento/${event.slug}`,
        isActive: event.state === 'ACTIVE' && isFutureEvent
    }
}
