import React from 'react';
import getYouTubeID from 'get-youtube-id';
import YouTube from 'react-youtube';
import { Options } from 'react-youtube'
import { Maybe } from '../../generated/graphql';

interface VideoPlayerProps {
    url?: Maybe<string>
    opts?: Options
}

export default function VideoPlayer(props: VideoPlayerProps) {
    const { url, opts = {} } = props
    if (!url) return null
    const videoId = getYouTubeID(url);
    return videoId ? <YouTube videoId={videoId} opts={opts} /> : null
}
