import { Grid } from '@material-ui/core';
import React from 'react';
import CustomButton from '../CustomButton/CustomButton';
import Paragraph from '../Paragraph/Paragraph';
import Typography from '../Typography/Typography';
import styles from './AwardView.module.scss';

export default function AwardView() {
    const url = "https://drive.google.com/file/d/14s5W01Db-EX9ymAxp-3gEMfOh2rOA-US/view?usp=sharing";
    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid item xs={12} sm={4} className={styles.Image} />
            <Grid item xs={12} sm={8} className={styles.Text}>
                <main>

                    <Typography variant="h1colisseum">
                        Prémio Jovens Artistas Coliseu Porto Ageas
 		    </Typography>


                    <Typography variant="subtitle2">No dia em que assinalou 80 anos, 19 de dezembro de 2021, o Coliseu Porto Ageas apresentou o Prémio Jovens Artistas Coliseu Porto Ageas — Artes Circenses / Dança. Uma iniciativa conjunta com o Grupo Ageas Portugal que pretende promover talentos em duas áreas artísticas que são de enorme importância no nosso panorama artístico. Áreas que implicam talentos distintivos e de enorme dedicação, mas que nem sempre têm o devido reconhecimento.</Typography>

                    <section>

                        <Paragraph>Destinado a distinguir profissionais das Artes Circenses ou da Dança, desde intérpretes, coreógrafos, cenógrafos, produtores, programadores, entre outros — que tenham completado até 30 anos de idade, inclusive, na data da atribuição — o Prémio é anual: em 2022 destina-se às Artes Circenses e em 2023 à Dança, e assim sucessivamente.</Paragraph>

                        <Paragraph>Na área das Artes Circenses, o júri é constituído por: Isabela Cardinali, Israel Modesto, João Paulo Santos, Julieta Guimarães, Luís de Matos e Rui Paixão.</Paragraph>

                        <Paragraph>Na Dança, o júri é constituído por: Ana Borralho & João Galante, Max Oliveira, Miguel Ramalho, Olga Roriz e Susana Otero.</Paragraph>

                        <Paragraph>O vencedor é anunciado em 19 de dezembro de cada ano e o vencedor, seja no ano das Artes Circenses ou no ano da Dança, recebe 5.000€.</Paragraph>


                        <CustomButton url={url} label="Regulamento" blank thin />

                        <div className={styles.Winner}>
                            <Paragraph>Vencedor Prémio Jovens Artistas - Dança 2023: <a href="https://coliseu.pt/noticia/ana-isabel-castro-e-a-vencedora-do-premio-jovens-artistas-coliseu-porto-age/">Ana Isabel Castro</a></Paragraph>
                            <Paragraph>Vencedor Prémio Jovens Artistas - Artes Circenses 2022: <a href="https://www.coliseu.pt/noticia/daniel-seabra-e-o-vencedor-do-premio-jovens-artistas-coliseu-porto-ageas-">Daniel Seabra</a></Paragraph>
                        </div>

                        <Typography variant="h6">
                            Júri · Artes Circenses
 			</Typography>

                        <Typography variant="subtitle2" component="p">
                            João Paulo Santos
 			</Typography>

                        <Paragraph>Artista de novo circo especializado em Mastro Chinês. Estudou circo no Chapitô e nas escolas francesas ENACR e CNAC. Em 2004, criou a sua companhia chamada O Último Momento com o músico Guillaume Dutrieux e o seu primeiro espectáculo estreou em 2005 "(Peut-être)". Inventou uma nova forma de praticar o Mastro chinês e foi considerado como um mestre com a sua forma tão peculiar de se expressar, misturando dança, novas acrobacias e vídeo. Considerado pioneiro no campo do circo contemporâneo, é também videógrafo, professor e encenador. É artista associado da O Espaço do Tempo, liderado por Rui Horta em Montemor-O-Novo.</Paragraph>


                        <Typography variant="subtitle2" component="p">Luís de Matos</Typography>

                        <Paragraph>Mágico, autor e produtor, é um artista versátil. Dos palcos à televisão tem um extraordinário poder de comunicação. A nível internacional, é um dos mágicos mais premiados, tendo sido distinguido pela Academia de Artes Mágicas de Hollywood três vezes. É também o mais jovem mágico a ter recebido o Devant Award — The Magic Circle pelo seu papel no desenvolvimento da Arte Mágica. Em Portugal foi condecorado com a comenda da Ordem do Infante D. Henrique por serviços relevantes na expansão da cultura portuguesa.</Paragraph>

                        <Typography variant="subtitle2" component="p">Israel Modesto</Typography>

                        <Paragraph>Nascido debaixo das lonas do extinto Circo Bruxelas, dirigido pelo seu avô Abraão Modesto, estava destinado pelo sangue (e pelo coração) a fazer parte desta arte. O seu sentido de humor notabilizou-o enquanto palhaço. Em 2000 criou a companhia Circo Merito, nome herdado da família da mulher. Em 2016, e após uma série de transformações, renomeou a sua companhia de Super Circo, tendo empreendido num espetáculo grandioso em três pistas.</Paragraph>

                        <Typography variant="subtitle2" component="p">Rui Paixão</Typography>

                        <Paragraph>Com foco na criação artística para o espaço público, desenvolve um trabalho de investigação e exploração da linguagem do clown contemporâneo e do teatro físico. Em 2019 juntou-se ao Cirque du Soleil numa nova criação em Hangzhou, na China, tornando-se o primeiro português a integrar a companhia como criador original. Da sua relação, recente, com o Festival de Circo Contemporâneo LEME surge uma nova personagem, Albano, a sua última investida sobre o que é ser um palhaço.</Paragraph>

                        <Typography variant="subtitle2" component="p">Isabela Cardinali</Typography>

                        <Paragraph>Descendente da mais mítica família circense em Portugal, começou a trabalhar com 12 anos no Coliseu de Lisboa, tendo passado a juventude a viajar pela Europa. Aos 20 anos criou o próprio espetáculo, "Circo dos Horrores" onde foi co-responsável pelo guião e responsável pela maquilhagem, coreografia e gestão das redes sociais. Juntamente com Pedro Moreira escreveu o guião do espetáculo de natal do Circo Chen, "À Procura do Pai Natal". Com forte impacto junto da população mais jovem, e centenas de milhares de seguidores no Instagram, produz conteúdos nas áreas do desporto, moda, maquilhagem e artes circenses.</Paragraph>

                        <Typography variant="subtitle2" component="p">Julieta Guimarães</Typography>

                        <Paragraph>Licenciada em Estudos Teatrais pela Escola Superior de Música Artes e Espetáculo, em 2005, pós-graduou-se em Teatro pela Faculdade de Psicologia e Ciências da Educação da Universidade do Porto e em Gestão das Artes pela Universidade Católica. Mestre em Produção e Direção de Cena pela ESMAE, é cofundadora da Erva Daninha, companhia criada em 2006, onde assume a direção plástica, assistência de direção e comunicação. Como atriz e coordenadora artística, colaborou com a Operação Nariz Vermelho. Como programadora fez o Ciclo Vão de Escada para o FITEI, a mostra Corrente Alterna no TNSJ, Trengo — Festival de Circo do Porto e Mostra Estufa.</Paragraph>

                        <Typography variant="h6">
                            Júri · Dança
 			</Typography>

                        <Typography variant="subtitle2" component="p">Olga Roriz</Typography>

                        <Paragraph>Formada em dança pela Escola de Dança do Teatro Nacional de S. Carlos, com Ana Ivanova, fez também o curso da Escola de Dança do Conservatório Nacional de Lisboa. De 1976 a 1992 integrou o elenco do Ballet Gulbenkian sob a direção de Jorge Salavisa, onde foi primeira bailarina e coreógrafa principal. Trabalhou com coreógrafos como Alvin Nokolais, Jiri Kylián, Louis Falco, HansVan Manen, Vasco Wellemkamp, Karine Saporta, Lar Lubovitch, Peter Sparling, Elisa Monte e Christopher Bruce. Depois da direção artística da Companhia de Dança de Lisboa fundou, em 1995, a Companhia Olga Roriz. O seu reportório na área da dança, teatro e vídeo é constituído por mais de 100 obras apresentadas internacionalmente. Na área do vídeo realizou três filmes “Felicitações Madame”, “A Sesta” e “Interiores”. Uma extensa biografia sobre a sua vida e obra foi editada em 2006, pela Assírio&Alvim, da autoria de Mónica Guerreiro. Desde 1982, Olga Roriz foi distinguida com relevantes prémios nacionais e estrangeiros.</Paragraph>

                        <Typography variant="subtitle2" component="p">Susana Otero</Typography>

                        <Paragraph>Iniciou os estudos de ballet clássico aos três anos, frequentando instituições como a Academia de Bailado Clássico Pirmin Treku, no Porto. Frequentou o Conservatório de Música e Dança do Funchal; o curso de Gestão do Património Artístico e Cultural na Escola Superior de Educação do Porto; o curso de Especialização de Performance em Espaços Públicos e Site-Specific na Universidade Lusófona; e o Mestrado de Indústrias Criativas na Universidade Católica do Porto. Bailarina no Ballet Contemporâneo do Norte desde 2001, dá aulas no Balleteatro Escola Profissional.</Paragraph>

                        <Typography variant="subtitle2" component="p">Ana Borralho & João Galante</Typography>

                        <Paragraph>Ana Borralho (Lagos, 1972) & João Galante (Luanda, 1968) conheceram-se enquanto estudavam artes plásticas no AR.CO (Lisboa). Depois de uma passagem pelo teatro OLHO, trabalham nos campos da performance-art, dança, instalação, fotografia, som e vídeo com apresentações em todos o mundo. Corpo,/mente, dentro/fora, emoção/sentimento, eu/outros, privado/público, social/político, são alguns dos temas que abordam. Em 2010, o Teatro Municipal Maria Matos apresentou uma pequena antologia das suas performances sob o título “O Mundo Maravilhoso de Ana Borralho & João Galante”. São membros fundadores da banda de não-músicos Jymmie Durham, co-fundadores da associação cultural casaBranca e directores artísticos do festival de artes performativas Verão Azul.</Paragraph>

                        <Typography variant="subtitle2" component="p">Max Oliveira</Typography>

                        <Paragraph>Produtor e coreógrafo de espetáculos nacionais e internacionais, é bboy e Funky Styler Profissional. Líder de Momentum Crew, de breaking profissional, que conta com 10 títulos mundiais e sete europeus nas mais relevantes competições. Professor em várias escolas e cursos, é também curador dos eventos como “World Battle”, “Eurobattle”, “Bboy Gala” , "Betclic Breaking Battle", "Red Bull BCone" e "Red Bull Dance Your STyle". Responsável pelo centro de Arte MXM — MAXMOMENTUM, e pelo Departamento de Breaking na Federação Portuguesa de Dança Desportiva, coordena ainda projetos sociais como Desporto no Bairro, Todos, KBL (USA), Style Force Crew (Colombia) e WB (SIberia/ Kazakhstan).</Paragraph>

                        <Typography variant="subtitle2" component="p">Miguel Ramalho</Typography>

                        <Paragraph>Iniciou a sua formação na Escola de Dança do Conservatório Nacional seguindo para a Companhia Portuguesa de Bailado Contemporâneo sob direção de Vasco Wellenkamp. Na Companhia Nacional de Bailado dançou todo o repertório da companhia, clássico e contemporâneo. Premiado em 2012 pela imprensa nacional como Bailarino do Ano, tornou-se o primeiro bailarino da companhia a fazer uma residência em África. Em 2017 iniciou a sua carreira coreográfica criando várias obras para as maiores instituições de dança Portuguesas. Em 2019 torna-se o 1º coreógrafo a co-criar uma obra com Vasco Wellenkamp. Atualmente, Miguel Ramalho encontra-se a desenvolver vários projetos independentes, incluindo duas criações para a Companhia Nacional de Bailado partilhada com o coreógrafo Sidi Larbi Cherkaoui.
			</Paragraph>
                    </section>
                </main>
            </Grid>
        </Grid>
    )
}
