import { Grid } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useGtXs } from '../../hooks/useGtXs';
import { EducationalCategory, excludeCategoryFromList } from '../SchoolsView/Activities';
import styles from './SeeOtherCategories.module.scss';

interface SeeOtherCategoriesProps {
    category: EducationalCategory
}

const SeeOtherCategories = (props: SeeOtherCategoriesProps) => {
    const { category } = props

    const gtXs = useGtXs()
    const history = useHistory()
    const categories = excludeCategoryFromList(category)

    const spacing = gtXs ? 4 : 1

    const handleClick = (category: EducationalCategory) => {
        const { slug } = category
        history.push(`/educativo/programa-escolas/categoria/${slug}`)
    }

    return (
        <Grid container className={styles.Footer} spacing={spacing}>
            <Grid item xs={6} sm={4} onClick={() => handleClick(categories[0])}>
                <img src={categories[0].cover} alt="" />
            </Grid>
            <Grid item xs={6} sm={4} onClick={() => handleClick(categories[1])}>
                <img src={categories[1].cover} alt="" />
            </Grid>
            <Grid item xs={6} sm={4} onClick={() => handleClick(categories[2])}>
                <img src={categories[2].cover} alt="" />
            </Grid>
        </Grid>

    )
}

export default SeeOtherCategories
