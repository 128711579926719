import { Fade } from '@material-ui/core';
import React, { ReactNode, useEffect, useState } from 'react';

interface PageTransitionProps {
    children: ReactNode
}

export default function PageTransition(props: PageTransitionProps) {
    const { children } = props
    const [isTransitioning, setIsTransitioning] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsTransitioning(true)
        }, 100)

        return () => {
            setIsTransitioning(false)
            clearTimeout(timer)
        }
    }, [])

    return (
        <Fade in={isTransitioning}>
            <div>
                {children}
            </div>
        </Fade>
    )
}
