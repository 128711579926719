import React, { FC, Fragment, ReactNode } from "react";

interface ShowProps {
    on: boolean
    children: ReactNode
}

const Show: FC<ShowProps> = props => {
    const { on, children } = props
    if (!on) return null
    return (
        <Fragment>
            {children}
        </Fragment>
    )
}

export default Show
