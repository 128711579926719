import Grid from '@material-ui/core/Grid';
import React from 'react';
import EventGallery from '../../EventGallery/EventGallery';
import styles from './CircusPhotoGallery.module.scss';

const CircusPhotoGallery = ({ photos, year }: { photos: any, year: number }) => {
    return (
        <Grid container direction="row" justify="center" className={styles.PhotoGallery}>
            <h1>Alguns momentos Circo do Coliseu Porto em {year}.</h1>
            <Grid item xs={12} lg={10} style={{ paddingBottom: 50 }}>
                <EventGallery photos={photos} hideTitle />
            </Grid>
        </Grid>
    )
};

export default CircusPhotoGallery;
