import React from 'react';
import { InputLabelProps, InputProps } from "@material-ui/core"
import { Field } from "formik"
import { FC } from "react"
import FieldText from "../FieldText/FieldText"

interface InputTextFieldProps {
    name: string
    label?: string
    autoFocus?: boolean
    variant?: "standard" | "outlined" | "contained"
    multiline?: boolean
    fullWidth?: boolean
    rows?: number
    minRows?: number
    maxRows?: number
    InputProps?: Partial<InputProps>
    InputLabelProps?: Partial<InputLabelProps>
    type?: "text" | "password" | string
    disabled?: boolean,
    autoComplete?: string
}

const InputTextField: FC<InputTextFieldProps> = props => {
    const { name,
        label = null,
        autoFocus = false,
        multiline = false,
        variant = "standard",
        fullWidth = false,
        rows,
        minRows,
        maxRows,
        InputLabelProps,
        InputProps,
        type = "text",
        disabled,
        autoComplete = "off"

    } = props
    return (
        <Field
            component={FieldText}
            name={name}
            label={label}
            type={type}
            autoFocus={autoFocus}
            variant={variant}
            multiline={multiline}
            fullWidth={fullWidth}
            rows={rows}
            minRows={minRows}
            maxRows={maxRows}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
            disabled={disabled}
            autoComplete={autoComplete}
        />
    )
}

export default InputTextField
