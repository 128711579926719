import { Grid } from '@material-ui/core';
import React from 'react';
import building1 from '../../assets/images/institution/building/building_1.jpg';
import building2 from '../../assets/images/institution/building/building_2.jpg';
import building3 from '../../assets/images/institution/building/building_3.jpg';
import building4 from '../../assets/images/institution/building/building_4.jpg';
import building5 from '../../assets/images/institution/building/building_5.jpg';
import building6 from '../../assets/images/institution/building/building_6.jpg';
import building7 from '../../assets/images/institution/building/building_7.jpg';
import building8 from '../../assets/images/institution/building/building_8.jpg';
import Paragraph from '../Paragraph/Paragraph';
import Typography from '../Typography/Typography';
import styles from './BuildingView.module.scss';

export default function BuildingView() {
    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <main>

                <Typography variant="h1colisseum">
                    Edifício e Arquitetura
		</Typography>

                <section>

                    <div className={styles.TextImage}>
                        <img src={building1} alt="" className={styles.PageImage} />
                        <Typography variant="subtitle2">A 19 de dezembro de 1941 foi inaugurado o edifício do Coliseu Porto. No final da década de 30 o Modernismo impunha-se. Não só nas artes, como nas gentes e até na traça da cidade. O Coliseu nasce assim moderno. E moderno será para sempre.</Typography>
                    </div>

                    <div className={styles.TextImage}>
                        <img src={building2} alt="" className={styles.PageImage} />
                        <Typography variant="h6">
                            Arquitetura
			</Typography>
                        <Paragraph>Ícone do modernismo local e internacional, o edifício do Coliseu foi projetado a várias mãos. Cassiano Branco, José Porto, Yan Wils, Júlio de Brito, Charles Siclis e Mário Abreu são alguns dos arquitetos que colaboraram ativamente na sua construção, como que antecipando uma das principais características do Coliseu: a pluralidade de expressões.</Paragraph>
                    </div>


                    <div className={styles.TextImage}>
                        <img src={building3} alt="" className={styles.PageImage} />
                        <Typography variant="h6">
                            José Porto
			</Typography>
                        <Paragraph>Natural de Vilar de Mouros, José Porto iniciou a sua atividade artística como pintor, ilustrador, cenógrafo e designer. Este percurso marcou os seus projetos enquanto arquiteto, onde combinava a arquitetura com a decoração de interiores e o planeamento urbanístico com a cenografia. É da sua autoria o anteprojeto para a fachada do Coliseu Porto, preterido em favor de outras propostas. Projetou também o interior da sala principal do edifício, que se mantém fiel aos seus primeiros estudos.</Paragraph>
                    </div>

                    <div className={styles.TextImage}>
                        <img src={building4} alt="" className={styles.PageImage} />
                        <Typography variant="h6">Yan Wils</Typography>
                        <Paragraph>Arquiteto holandês, foi um dos fundadores do movimento artístico De Stijl e um admirador de Frank Lloyd Wright e do modernismo americano. Entre outros projetos, desenhou o Estádio Olímpico de Amsterdão e fez alguns esboços para o edifício do Coliseu Porto, que nunca chegaram a ser concretizados.</Paragraph>
                    </div>

                    <div className={styles.TextImage}>
                        <img src={building5} alt="" className={styles.PageImage} />
                        <Typography variant="h6">Júlio de Brito</Typography>
                        <Paragraph>Foi um arquiteto, engenheiro e professor português. Filho do pintor José de Brito, cursou Arquitetura na Escola de Belas Artes do Porto e em simultâneo inscreveu-se no curso de Engenharia na Faculdade de Engenharia do Porto. Entre as suas obras mais emblemáticas está o Teatro Rivoli, o edifício da Junta de Freguesia de Cedofeita e o Coliseu Porto, onde começa por ver recusados os seus estudos pela comissão de Estética da Câmara Municipal do Porto. Mais tarde, o arquiteto Cassiano Branco assume a obra e conta com a colaboração de Júlio de Brito no projeto.</Paragraph>
                    </div>

                    <div className={styles.TextImage}>
                        <img src={building6} alt="" className={styles.PageImage} />
                        <Typography variant="h6">Cassiano Branco</Typography>
                        <Paragraph>É um dos mais importantes arquitetos portugueses da primeira metade do século XX. Um dos mais espetaculares e cosmopolitas modernistas da sua geração, deixa na sua obra alguns projetos visionários nunca realizados e obras de referência da primeira modernidade portuguesa, como é o caso do Hotel Vitória e do Coliseu Porto. Foi arquiteto dirigente da obra do Coliseu, em colaboração com Júlio de Brito, e o seu traço marcou definitivamente a fachada do edifício. De todos os arquitetos envolvidos na obra, foi provavelmente o que mais marcou o aspeto exterior do Coliseu Porto.</Paragraph>
                    </div>

                    <div className={styles.TextImage}>
                        <img src={building7} alt="" className={styles.PageImage} />
                        <Typography variant="h6">Charles Siclis</Typography>
                        <Paragraph>Foi um arquiteto e designer francês, formado na Escola de Belas Artes de Paris. Durante a sua carreira internacional, passou por Portugal e pelo Porto, onde fez alguns trabalhos de modificação na Casa de Serralves e participou, a convite de Cassiano Branco, na arquitetura de interiores do Coliseu. Após ter proposto alguns desenhos, admite-se que só os candeeiros e as portas foram incluídos no projeto.</Paragraph>
                    </div>

                    <div className={styles.TextImage}>
                        <img src={building8} alt="" className={styles.PageImage} />
                        <Typography variant="h6">Mário Abreu</Typography>
                        <Paragraph>É o último arquiteto a participar na construção do edifício do Coliseu Porto e entra já na fase final da obra. Projetou o interior e fez alterações na sala principal, nas escadarias e na torre da fachada, que deveria ser envidraçada. Eliminou ainda o néon verde, vermelho e branco que acompanharia todo o edifício, segundo o conceito original de Cassiano Branco.</Paragraph>
                    </div>

                </section>
            </main>
        </Grid>

    )

}
