import { Fade, Grid, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useEventBySlugQuery } from '../../generated/graphql';
import { useGtXs } from '../../hooks/useGtXs';
import { Config } from '../../services/Config';
import { durationFormatter, eventStates, mediaUrl } from '../../services/Utils';
import BindHTML from '../BindHTML/BindHTML';
import BuyTickets from '../BuyTickets/BuyTickets';
import DownloadIcon from '../DownloadIcon/DownloadIcon';
import EventDetailsHelmet from '../EventDetailsHelmet/EventDetailsHelmet';
import EventGallery from '../EventGallery/EventGallery';
import FormatDate from '../FormatDate/FormatDate';
import FormatTime from '../FormatTime/FormatTime';
import Hide from '../Hide/Hide';
import Loading from '../Loading/Loading';
import Show from '../Show/Show';
import Typography from '../Typography/Typography';
import VideoPlayerAspect from '../VideoPlayerAspect/VideoPlayerAspect';
import styles from './EventDetailsView.module.scss';

const getComponent = (event: any) => {
    if (event?.state === 'ACTIVE') {
        return <BuyTickets event={event} button />
    }
    return (
        <span className={styles.DisplayUnavailable}>
            {eventStates(event?.state)}
        </span>
    )
}

export default function EventDetailsView(props: any) {

    const slug = props?.match?.params?.slug
    const { data, loading } = useEventBySlugQuery({ variables: { slug } });
    const gtXs = useGtXs()

    if (loading) {
        return <Loading />
    }

    const event = data?.eventBySlug
    const component = getComponent(event)
    const cover = Config.MEDIA_URL + event?.cover

    const handleDownload = (file: any) => {
        window.open(mediaUrl(file))
    }

    if (!loading && !data?.eventBySlug?.id) {
        const classes = clsx([styles.Wrapper, styles.Empty])
        return (
            <Grid container justify="center" className={classes}>
                <Typography variant="subtitle1">
                    Não foi possível encontrar o evento.
                </Typography>
            </Grid>
        )
    }


    return (
        <Fade in={!loading}>
            <Grid container justify="center" className={styles.Wrapper}>
                <EventDetailsHelmet event={event} />
                <Grid container component="main">

                    {/* primeira linha em mobile */}
                    <Show on={!gtXs}>
                        <div>
                            <h1 className={styles.EventName}>
                                {event?.name}
                            </h1>
                            <p className={styles.EventDate}>
                                <FormatDate date={event?.startDate} />
                            </p>
                            <p className={styles.EventTime}>
                                <FormatTime datetime={event?.startDate} />
                            </p>
                        </div>
                    </Show>

                    {/* 1 coluna mobile, 2 colunas desktop */}
                    <Grid item xs={12} sm={8} className={styles.Information}>

                        <div>
                            <Show on={gtXs}>
                                <div>
                                    <h1 className={styles.EventName}>
                                        {event?.name}
                                    </h1>
                                    <p className={styles.EventDate}>
                                        <FormatDate date={event?.startDate} />
                                    </p>
                                    <p className={styles.EventTime}>
                                        <FormatTime datetime={event?.startDate} />
                                    </p>
                                </div>
                            </Show>

                            <p className={styles.RoomName}>
                                {event?.room?.name}
                            </p>

                            <Show on={!!event?.minimumAge}>
                                <p className={styles.GenericInfo}>
                                    <span>Classificação Etária</span>
                                    <span>{event?.minimumAge}</span>
                                </p>
                            </Show>

                            <Show on={!!event?.estimatedDuration}>
                                <p className={styles.GenericInfo}>
                                    <span>
                                        Duração Aproximada
                                    </span>
                                    <span>
                                        {event?.estimatedDuration && durationFormatter(event?.estimatedDuration)}
                                    </span>
                                </p>
                            </Show>

                            <p className={styles.GenericInfo}>
                                <span>Abertura de Portas</span>
                                <span>{event?.eventOpening}</span>
                            </p>

                            <Show on={!!event?.promoter}>
                                <p className={styles.GenericInfo}>
                                    <span>Promotor</span>
                                    <Show on={!!event?.promoter}>
                                        <Show on={!event?.promoter?.url}>
                                            <span>{event?.promoter?.name}</span>
                                        </Show>
                                        <Show on={!!event?.promoter?.url}>
                                            <a href={event?.promoter?.url || ""} target="_blank" rel="noopener noreferrer">
                                                {event?.promoter?.name}
                                            </a>
                                        </Show>
                                    </Show>
                                </p>
                            </Show>
                        </div>

                        {/* bilhetes, descontos e comprar */}
                        <Grid container className={styles.Tickets}>
                            <Grid item xs={12} sm={6}>
                                <h4>Bilhetes</h4>
                                <p className={styles.BreakOnNew}>
                                    {event?.ticketsInfo}
                                </p>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Show on={!!event?.discountsInfo}>
                                    <h4>Descontos</h4>
                                    <p className={styles.BreakOnNew}>
                                        {event?.discountsInfo}
                                    </p>
                                </Show>
                                <Hide on={event?.isArchived || !event?.ticketsSeller}>
                                    <div className={styles.Call2Action}>
                                        {component}
                                    </div>
                                </Hide>
                            </Grid>
                        </Grid>

                        <div className={styles.VideoPlayerWrapper}>
                            <VideoPlayerAspect url={event?.videoUrl} />
                        </div>

                        <BindHTML className={styles.Description}>
                            {event?.description}
                        </BindHTML>
                    </Grid>

                    {/* 1 coluna mobile, 2 colunas desktop */}
                    <Grid xs={12} sm={4} className={styles.Poster}>
                        <img src={cover} alt="" style={{ maxWidth: '100%' }} />
                        <Show on={!!event?.roomSheet}>
                            <Tooltip title="descarregar folha de sala">
                                <Grid container className={styles.RoomSheet} onClick={() => handleDownload(event?.roomSheet)} alignItems="center">
                                    <DownloadIcon />
                                    <span>Folha de sala</span>
                                </Grid>
                            </Tooltip>
                        </Show>
                    </Grid>

                    {/* galeria de fotos 1 coluna mobile e desktop */}
                    <Grid container className={styles.EventGalleryWrapper}>
                        <EventGallery photos={event?.eventPhotos} />
                    </Grid>

                </Grid>
            </Grid>
        </Fade>
    )
}
