import { Grid } from '@material-ui/core'
import React from 'react'
import Highlights from '../Highlights/Highlights'
import styles from './HomeHighlights.module.scss'

export default function HomeHighlights() {
    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Highlights />
        </Grid>
    )
}
