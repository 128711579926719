import { Grid } from '@material-ui/core';
import React from 'react';
import CustomButton from '../CustomButton/CustomButton';
import Paragraph from '../Paragraph/Paragraph';
import Typography from '../Typography/Typography';
import styles from './ReportsView.module.scss';

export default function ReportsView() {


    const buttons = [
        {
            label: 'Relatório e Contas 2023',
            url: 'https://drive.google.com/file/d/12-zlM3UM9E4z46hF_tBdKc0y-HPrPmG-/view'
        },
        {
            label: 'Relatório e Contas 2022',
            url: 'https://drive.google.com/file/d/1wEPeFRW4JCD2-UNY7OB3bUha3_1boF-o/view'
        },
        {
            label: 'Relatório e Contas 2021',
            url: 'https://drive.google.com/file/d/15ESuyWp9Rtw8gK38HyhLf7qEFWGzn1hx/view'
        },
        {
            label: 'Relatório e Contas 2020',
            url: 'https://drive.google.com/file/d/1A66g2_cQMooYVpeOxkJpCINUM0epYCR5/view'
        },
        {
            label: 'Relatório e Contas 2019',
            url: 'https://drive.google.com/file/d/13GTZ-_FC0AmTUMvn8xuijp-BVacsDsGi/view'
        },
        {
            label: 'Relatório e Contas 2018',
            url: 'https://drive.google.com/file/d/1TzUAtRldQ23w25nK9ubteD8QsGzccQAF/view'
        },
        {
            label: 'Relatório e Contas 2017',
            url: 'https://drive.google.com/file/d/1vv1bHAL6s0VWJLLjV9DueEp1b4SDtgX8/view'
        },
        {
            label: 'Relatório e Contas 2016',
            url: 'https://drive.google.com/file/d/0B2jUSVhHniKaOUJNRFNFamdNNlE/view'
        },
        {
            label: 'Relatório e Contas 2015',
            url: 'https://drive.google.com/file/d/0B2jUSVhHniKaR3o4VHhwSXFROTg/view'
        },
        {
            label: 'Circo Coliseu Porto 2016',
            url: 'https://drive.google.com/file/d/0B2jUSVhHniKaVXNFYlU3YXFjX0U/view'
        }
    ];


    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid item xs={12} sm={4} className={styles.Image} />
            <Grid item xs={12} sm={8} className={styles.Text}>
                <main>

                    <Typography variant="h1colisseum">
                        Relatórios
		    </Typography>

                    <Paragraph>
                        Encontre aqui as últimas comunicações.
		    </Paragraph>


                    <section className={styles.PressSection}>
                        {buttons.map(button => (
                            <Paragraph key={button.label}>
                                <CustomButton
                                    url={button.url}
                                    label={button.label}
                                    blank
                                    thin
                                />
                            </Paragraph>
                        ))}
                    </section>
                </main>
            </Grid>
        </Grid>
    )
}
