import React from 'react';
import { Helmet } from "react-helmet";
import img from '../../assets/images/meta/coliseu_porto_ageas_site.webp';

const DefaultMetaTags = () => (
    <Helmet>
        <meta property="og:site_name" content="Coliseu Porto Ageas" />
        <meta property="og:url" content="https://www.coliseu.pt" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Coliseu Porto Ageas" />
        <meta property="og:description" content="O Coliseu Porto é um símbolo da cidade. Não apenas pelo seu edifício único, mas porque ao longo de 75 anos acolheu os maiores nomes nacionais. Espetáculos internacionais de grande renome. Centenas de filmes que emocionaram multidões. Na sua sala, sempre houve lugar para todos os públicos, para todos os géneros artísticos." />
        <meta property="og:image" content={`https://www.coliseu.pt${img}`} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="600" />
    </Helmet>
);

export default DefaultMetaTags;
