import { useState } from "react"
import getCarouselProps from "../utils/getCarouselProps"
import { useGtXs } from "./useGtXs"

export default function useCarousel(start: number, end: number) {

    const gtXs = useGtXs()
    const carouselProps = getCarouselProps(gtXs)
    const [currentSlide, setCurrentSlide] = useState<number>(start)

    const next = () => {
        if (currentSlide < end) {
            setCurrentSlide(currentSlide + 1)
        }
    }

    const previous = () => {
        if (currentSlide > start) {
            setCurrentSlide(currentSlide - 1)
        }
    }

    const isStart = currentSlide === start
    const isEnd = currentSlide === end

    return { next, previous, currentSlide, isStart, isEnd, carouselProps }
}
