import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import artists from '../../../assets/images/circus/2023/artists.png';
import gallery from '../../../assets/images/circus/2023/gallery.png';
import logos_desktop from '../../../assets/images/circus/2023/logos_desktop.png';
import logos_mobile from '../../../assets/images/circus/2023/logos_mobile.webp';
import photo1 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_01.webp';
import photo2 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_02.webp';
import photo3 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_03.webp';
import photo4 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_04.webp';
import photo5 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_05.webp';
import photo6 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_06.webp';
import photo7 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_07.webp';
import photo8 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_08.webp';
import photo9 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_09.webp';
import photo10 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_10.webp';
import photo11 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_11.webp';
import photo12 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_12.webp';
import photo13 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_13.webp';
import photo14 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_14.webp';
import photo15 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_15.webp';
import photo16 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_16.webp';
import photo17 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_17.webp';
import photo18 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_18.webp';
import photo19 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_19.webp';
import photo20 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_20.webp';
import photo21 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_21.webp';
import photo22 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_22.webp';
import photo23 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_23.webp';
import photo24 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_24.webp';
import photo25 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_25.webp';
import photo26 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_26.webp';
import photo27 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_27.webp';
import photo28 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_28.webp';
import photo29 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_29.webp';
import photo30 from '../../../assets/images/circus/2023/photos/coliseu_porto_ageas_circo_2023_30.webp';
import sessions from '../../../assets/images/circus/2023/sessions.png';
import sheet from '../../../assets/images/circus/2023/sheet.png';
import { useGtXs } from '../../../hooks/useGtXs';
import { Config } from '../../../services/Config';
import EventGallery from '../../EventGallery/EventGallery';
import PageTransition from '../../PageTransition/PageTransition';
import styles from './Circus2023View.module.scss';

const photos = [
    photo1,
    photo2,
    photo3,
    photo4,
    photo5,
    photo6,
    photo7,
    photo8,
    photo9,
    photo10,
    photo11,
    photo12,
    photo13,
    photo14,
    photo15,
    photo16,
    photo17,
    photo18,
    photo19,
    photo20,
    photo21,
    photo22,
    photo23,
    photo24,
    photo25,
    photo26,
    photo27,
    photo28,
    photo29,
    photo30,
]


export default function Circus2023View() {

    const history = useHistory()
    const gtXs = useGtXs()
    const logos = gtXs ? logos_desktop : logos_mobile

    const handle = () => {
        history.push("/")
    }

    return (
        <PageTransition>
            <>
                <Helmet>
                    <meta property="og:site_name" content="Coliseu Porto Ageas" />
                    <meta property="og:url" content={`${Config.APP}circo-2023`} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Coliseu Porto Ageas" />
                    <meta property="og:description" content="De 15 de dezembro de 2023 a 7 de janeiro de 2024, o Circo está de volta." />
                </Helmet>


                <div className={styles.Hero} />

                <div className={styles.Circus2023}>

                    <section className={styles.Intro}>

                        <p>De 15 de dezembro a 7 de janeiro, o Coliseu Porto Ageas apresenta um espetáculo memorável, irrepetível e surpreendente, que desvenda o que as artes circenses têm  de mais fantástico!</p>

                        <p>Para a edição de 2023, o Coliseu desafiou Gonçalo M. Tavares a escrever uma história original para um circo memorável. Naquela que é a sua estreia a escrever para Circo, Gonçalo M. Tavares, Prémio Literário José Saramago, vai guiar o público numa viagem onírica de homenagem à memória, através do amor de um neto pelo seu avô.</p>

                        <p>Desenvolvido e programado pelo Coliseu, com a parceria do Teatro Nacional de São João, neste espetáculo-sonho haverá artistas em terra a surpreender com malabarismos impossíveis e outros em voos ousados num trapézio que nos fará suster a respiração. Hula-hoops, antipodismo, arame, corda balanço, mastro chinês, báscula coreana, tecido acrobático, magia e os imprescindíveis palhaços completam o programa.</p>


                        <p>Com música original, desenho de luz e videoarte que vai surpreender, mantemos viva esta tradição ininterrupta no Coliseu desde 1941 que faz sonhar o público de todas as idades. Venha sonhar connosco!</p>

                        <Grid container className={styles.Credits}>
                            <Grid item xs={12} md={6}>
                                <div>
                                    <h5>História Original</h5>
                                    <p>Gonçalo M. Tavares</p>
                                </div>
                                <div>
                                    <h5>Encenação</h5>
                                    <p>Nuno Cardoso</p>
                                </div>
                                <div>
                                    <h5>Direção de Magia</h5>
                                    <p>Luís de Matos</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div>
                                    <h5>Música</h5>
                                    <p>Alexandre Soares</p>
                                </div>
                                <div>
                                    <h5>Vídeo Arte</h5>
                                    <p>Luís Porto</p>
                                </div>
                                <div>
                                    <h5>Desenho de Luz</h5>
                                    <p>Cárin Geada</p>
                                </div>
                            </Grid>
                        </Grid>
                    </section>

                    <section className={styles.Sessions}>

                        <Grid container justify="center" className={styles.SectionTitle}>
                            <img src={sessions} alt="sessões circo" />
                        </Grid>

                        <Grid container justify="center" className={styles.Call2Action}>
                            <Button onClick={handle}>
                                Ver Horários e Bilhetes
                            </Button>
                        </Grid>

                        <p>Bilhetes entre 9€ e 20€, à venda no Coliseu, Ticketline e locais habituais.</p>

                        <div className={styles.Discounts}>
                            <p>Descontos</p>
                            <p>75% crianças até aos 3 anos</p>
                            <p>50% crianças até aos 12 anos</p>
                            <p>20% Amigos do Coliseu e Cartão Pronto</p>
                        </div>
                    </section>

                    <section className={styles.Artists}>

                        <Grid container justify="center" className={styles.SectionTitle}>
                            <img src={artists} alt="artistas circo" />
                        </Grid>

                        <div>
                            <p>Ator - João Pamplona</p>
                            <p>palhaços - máximo luftman, rui luftman, antónio isacc</p>
                            <p>hula hoop - kety luftman</p>
                            <p>antipodismo - cintia luftman</p>
                            <p>arame - hannah grove</p>
                            <p>trapézio - ruby burgess</p>
                            <p>clowd swing - imogen macrae</p>
                            <p>mastro chinês - Swantje kawecki</p>
                            <p>tecidos - shalom gramiccioli</p>
                            <p>malabares e báscula coreana - volantes company</p>
                        </div>
                    </section>

                    <section className={styles.Gallery}>

                        <Grid container justify="center" className={styles.SectionTitle}>
                            <img src={gallery} alt="galeria circo" />
                        </Grid>

                        <Grid container direction="row" justify="center">
                            <Grid item xs={12} lg={10}>
                                <EventGallery photos={photos} hideTitle />
                            </Grid>
                        </Grid>
                    </section>

                    <section className={styles.Staff}>

                        <Grid container justify="center" className={styles.SectionTitle}>
                            <img src={sheet} alt="ficha técnica circo" />
                        </Grid>

                        <Grid container className={styles.TechTeam}>
                            <Grid item xs={12} md={6}>
                                <div>
                                    <p><b>Assistência de Desenho de Luz</b> Tiago Silva</p>
                                    <p><b>Desenho de Som</b> Francisco Leal</p>
                                    <p><b>Assistência de Desenho de Som</b> Luís Ventura</p>
                                    <p><b>Direção Fotografia</b> Francisco Lobo</p>
                                    <p><b>Direção de Arte</b> Random Freaks</p>
                                    <p><b>Artwork Cenográfico</b> Mesk</p>
                                    <p><b>Operação de Vídeo</b> Tiago Mota</p>
                                    <p><b>Operação de Som</b> Carlos Matos, João Pereira</p>
                                    <p><b>Operação de Followspot</b> Abílio Sousa, António Mota</p>
                                    <p><b>Direação de Cena</b> Pedro Manana</p>
                                    <p><b>Assitentes de Pista</b> Bruno Martins, Bruno Gomes, Iara Gomes, João Bragança, Zé Kalanga</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div>
                                    <p><b>Ator</b> João Pamplona</p>
                                    <p><b>Palhaços</b> Máximo Luftman, Rui Luftman, António Isacc</p>
                                    <p><b>Hula Hoop</b> Kety Luftman</p>
                                    <p><b>Antipodismo</b> Cintia Luftman</p>
                                    <p><b>Arame</b> Hannah Grove</p>
                                    <p><b>Trapézio</b> Ruby Burgess</p>
                                    <p><b>Clowd Swing</b> Imogen Macrae</p>
                                    <p><b>Mastro Chinês</b> Swantje Kawecki</p>
                                    <p><b>Tecidos</b> Shalom Gramiccioli</p>
                                    <p><b>Malabares e Báscula Coreana</b> Volantes Company</p>
                                    <p><b>Intérpretes</b> Ana Isabel Teles, André Barros, André Rodrigues, Beatriz Silva, Carolina Oliveira, Eunice Rocha, Moisés Amorim</p>
                                </div>
                            </Grid>
                        </Grid>

                        <div className={styles.Coordination}>
                            <p><b>COORDENAÇÃO GERAL:</b> Miguel Guedes</p>
                        </div>

                        <div className={styles.Team}>
                            <p>
                                <b style={{ textTransform: "none" }}>Equipa Coliseu:</b> Celestino Teixeira, Eduardo Cambra, Fátima Pinto, Fernando Mota, Filipa Godinho, Graça Barreto, Ivan Pinto, João Pereira, João Puig, José Carlos Pinto Coelho, José Cunha, Juliana Agostinho, Justina Vilela, Lara Jacinto, Leandro Couto, Luís Barros, Márcio Correia, Paulo Alves, Pedro Moreira, Sara Coelho, Tiago Azevedo, Vanda Teixeira
                            </p>
                        </div>

                    </section>

                    <section className={styles.Footer}>
                        <Grid container justify="center">
                            <img src={logos} alt="logotipos apoios" />
                        </Grid>
                    </section>

                </div>
            </>
        </PageTransition>
    )
}
