import { Grid } from '@material-ui/core';
import React from 'react';
import Paragraph from '../Paragraph/Paragraph';
import Typography from '../Typography/Typography';
import styles from './TechnicalAreaView.module.scss';

export default function TechnicalAreaView() {
    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid item xs={12} sm={4} className={styles.Image} />
            <Grid item xs={12} sm={8} className={styles.Text}>
                <main>
                    <Typography variant="h1colisseum">
                        Área Técnica
		    </Typography>

                    <Paragraph>
                        Prepare <a href="http://coliseu.info" target="_blank" rel="noopener noreferrer"> aqui</a> a sua produção
		    </Paragraph>
                </main>
            </Grid>
        </Grid>
    )
}
