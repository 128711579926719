import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { HighlightSortFields, SortDirection, useHighlightsQuery } from '../../generated/graphql';
import useSwipe from '../../hooks/useSwipe';
import { mediaUrl, shiftBackwards, shiftForwards } from '../../services/Utils';
import FormatDate from '../FormatDate/FormatDate';
import FormatTime from '../FormatTime/FormatTime';
import Hide from '../Hide/Hide';
import Loading from '../Loading/Loading';
import Show from '../Show/Show';
import Typography from '../Typography/Typography';
import styles from './Highlights.module.scss';

export enum HighlightTypes {
    EVENT = "EVENT",
    NEWS = "NEWS",
    PASTIME = "PASTIME",
    LINK = "LINK"
}

const HighlightLink = ({ highlight }: { highlight: any }) => (
    <a href={highlight.url} className={styles.EventLink} aria-label="">
        &nbsp;
    </a>
);

export const LinkHighlight = ({ highlight }: { highlight: any }) => {
    return (
        <div className={styles.EventDetails}>
            <HighlightLink highlight={highlight} />
            <Hide on={highlight.isHidden}>
                <div className={styles.Name}>
                    {highlight.name}
                </div>
            </Hide>
        </div>
    )
}

const HighlightDetails = ({ highlight }: { highlight: any }) => {
    const isNewsType = highlight.type === HighlightTypes.NEWS
    const style = { fontWeight: 600 }
    return (
        <Grid container direction="column" justify="flex-end" className={styles.HighlightDetails}>
            <div className={styles.Overlay}></div>
            <Grid container className={styles.NewsDetails} direction="column" justify="flex-end">
                <HighlightLink highlight={highlight} />
                <Typography variant="menu" className={styles.Name}>
                    {!highlight.isHidden && highlight.name}
                </Typography>
                <Grid container justify="center" className={styles.DateTime}>
                    <Show on={isNewsType}>
                        <Typography variant="body1" style={style}>
                            Notícia
                        </Typography>
                    </Show>
                    <Show on={!isNewsType}>
                        <Typography variant="body1" style={style}>
                            <FormatDate date={highlight.event && highlight.event.startDate} monthCaps />
                            <span> // </span>
                            <FormatTime datetime={highlight.event && highlight.event.startDate} />
                        </Typography>
                    </Show>
                </Grid>
            </Grid>
        </Grid>
    );
};

const Highlight = ({ highlight, size }: { highlight: any, size: any }) => {
    const image = mediaUrl(highlight.image);
    return (
        <Grid item className={styles.Highlight} xs={size} style={{ backgroundImage: `url(${image})` }}>
            <HighlightLink highlight={highlight} />
            <HighlightDetails highlight={highlight} />
        </Grid>
    );
};

const Highlights = () => {

    let slots = 1;
    slots = useMediaQuery('(min-width:600px)') ? 2 : slots;
    slots = useMediaQuery('(min-width:960px)') ? 3 : slots;

    const { data, loading } = useHighlightsQuery({
        variables: {
            sorting: [{ field: HighlightSortFields.Order, direction: SortDirection.Asc }],
            filter: { isActive: { is: true } },
            paging: { limit: 99 }
        }
    })

    if (loading) {
        return <Loading />;
    }

    const highlights = data?.highlights?.nodes;

    if (!highlights) {
        return <p>Erro ao carregar destaques.</p>
    }

    // caso não existam destaques para
    // todos os slots
    if (highlights.length < slots) {
        slots = highlights.length
    }

    // @ts-ignore
    const sizes: any = {
        1: [12],
        2: [8, 4],
        3: [4, 4, 4],
    }[slots];

    return (
        <Grid container justify="center">
            <HighlightsInteractive highlights={highlights} sizes={sizes} slots={slots} />
        </Grid>
    );
};

const HighlightsInteractive = (props: any) => {

    const { highlights, slots } = props
    const [rotatingHighlights, setRotatingHighlights] = useState<any[]>(highlights)

    const handleRight = () => {
        const newOrder = shiftBackwards(rotatingHighlights)
        setRotatingHighlights(newOrder)
    }

    const handleLeft = () => {
        const newOrder = shiftForwards(rotatingHighlights)
        setRotatingHighlights(newOrder)
    }

    const [handleTouchStart, handleTouchEnd] = useSwipe(handleRight, handleLeft)

    useEffect(() => {
        if (rotatingHighlights?.length > slots) {
            const interval = setInterval(() => {
                handleRight()
            }, 10000)
            return () => clearInterval(interval)
        }
        // eslint-disable-next-line
    }, [rotatingHighlights, setRotatingHighlights, slots]);


    const displayHighlights = rotatingHighlights.slice(0, slots)
    const hideArrows = slots === 1 || rotatingHighlights?.length <= slots

    return (
        <Grid container className={styles.Highlights} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
            <Hide on={hideArrows}>
                <IconButton onClick={handleLeft} className={clsx(styles.Arrow, styles.ArrowLeft)}><ChevronLeft /></IconButton>
            </Hide>
            {
                displayHighlights?.map((highlight: any, index: number) => <Highlight key={Math?.random()} highlight={highlight} size={props.sizes[index]} />)
            }
            <Hide on={hideArrows}>
                <IconButton onClick={handleRight} className={clsx(styles.Arrow, styles.ArrowRight)}><ChevronRight /></IconButton>
            </Hide>
        </Grid>
    )
}

export default Highlights
