import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import icon1 from '../../../assets/images/circus/2024/circo2024_aros.webp';
import icon2 from '../../../assets/images/circus/2024/circo2024_fitas_aereas.webp';
import icon3 from '../../../assets/images/circus/2024/circo2024_funambulismo.webp';
import icon4 from '../../../assets/images/circus/2024/circo2024_hand_ballacing.webp';
import icon5 from '../../../assets/images/circus/2024/circo2024_palhacos.webp';
import icon6 from '../../../assets/images/circus/2024/circo2024_pole_aereo.webp';
import icon7 from '../../../assets/images/circus/2024/circo2024_rollerskating.webp';
import icon8 from '../../../assets/images/circus/2024/circo2024_trapezio_duo.webp';
import logos from '../../../assets/images/circus/2024/logos.png';
import photo1 from '../../../assets/images/circus/2024/photos/lj_circo2024_01_003.webp';
import photo2 from '../../../assets/images/circus/2024/photos/lj_circo2024_01_006.webp';
import photo3 from '../../../assets/images/circus/2024/photos/lj_circo2024_01_017.webp';
import photo4 from '../../../assets/images/circus/2024/photos/lj_circo2024_01_020.webp';
import photo5 from '../../../assets/images/circus/2024/photos/lj_circo2024_01_028.webp';
import photo6 from '../../../assets/images/circus/2024/photos/lj_circo2024_01_036.webp';
import photo7 from '../../../assets/images/circus/2024/photos/lj_circo2024_01_039.webp';
import photo8 from '../../../assets/images/circus/2024/photos/lj_circo2024_01_054.webp';
import photo9 from '../../../assets/images/circus/2024/photos/lj_circo2024_01_056.webp';
import photo10 from '../../../assets/images/circus/2024/photos/lj_circo2024_01_076.webp';
import photo11 from '../../../assets/images/circus/2024/photos/lj_circo2024_01_077.webp';
import photo12 from '../../../assets/images/circus/2024/photos/lj_circo2024_01_078.webp';
import photo13 from '../../../assets/images/circus/2024/photos/lj_circo2024_01_095.webp';
import photo14 from '../../../assets/images/circus/2024/photos/lj_circo2024_01_100.webp';
import photo15 from '../../../assets/images/circus/2024/photos/lj_circo2024_01_122.webp';
import photo16 from '../../../assets/images/circus/2024/photos/lj_circo2024_01_125.webp';
import photo17 from '../../../assets/images/circus/2024/photos/lj_circo2024_01_126.webp';
import photo18 from '../../../assets/images/circus/2024/photos/lj_circo2024_01_128.webp';
import photo19 from '../../../assets/images/circus/2024/photos/lj_circo2024_01_135.webp';
import photo20 from '../../../assets/images/circus/2024/photos/lj_circo2024_01_147.webp';
import { Config } from '../../../services/Config';
import EventGallery from '../../EventGallery/EventGallery';
import PageTransition from '../../PageTransition/PageTransition';
import Typography from '../../Typography/Typography';
import styles from './Circus2024View.module.scss';
import useGetCircus2024Sessions from './Helpers/useGetCircus2024Sessions';
import Separator from './Separator';
import Session from './Session';
import { useGtXs } from '../../../hooks/useGtXs';

const photos = [
    photo1,
    photo2,
    photo3,
    photo4,
    photo5,
    photo6,
    photo7,
    photo8,
    photo9,
    photo10,
    photo11,
    photo12,
    photo13,
    photo14,
    photo15,
    photo16,
    photo17,
    photo18,
    photo19,
    photo20
]

const artists = [
    { id: 1, discipline: "Palhaços", artists: ["Datinelli Brothers"], icon: icon5 },
    { id: 2, discipline: "Aros", artists: ["Priscila Alves"], icon: icon1 },
    { id: 3, discipline: "Trapézio Duo", artists: ["Kimberley Bargenquast", "Flynn Miller"], icon: icon8 },
    { id: 4, discipline: "Roller-Skating", artists: ["Beata Surmiak", "Jamie Swan"], icon: icon7 },
    { id: 5, discipline: "Hand Balancing", artists: ["Duo Jordanella"], icon: icon4 },
    { id: 6, discipline: "Pole Aéreo", artists: ["Mateo Sito"], icon: icon6 },
    { id: 7, discipline: "Funambulismo", artists: ["Los Guerreros"], icon: icon3 },
    { id: 8, discipline: "Fitas Aéreas", artists: ["Augustin Rodriguez", "Beltran"], icon: icon2 },
]

const Artist = (props: any) => {
    const { artist } = props

    const gtXs = useGtXs()
    const disciplineVariant = gtXs ? "RocGroteskRegular25" : "RocGroteskRegular18"
    const nameVariant = gtXs ? "RocGroteskBold25" : "RocGroteskBold16"

    return (
        <Grid item xs={12} md={6} container className={styles.Artist}>
            <div>
                <img src={artist.icon} />
            </div>
            <div className={styles.ArtistWrapper}>
                <Typography variant={disciplineVariant} component="p">
                    {artist.discipline}
                </Typography>
                <div className={styles.ArtistName}>
                    {artist.artists.map((name: string) =>
                        <Typography variant={nameVariant} component="p">
                            {name}
                        </Typography>
                    )}
                </div>
            </div>
        </Grid>
    )
}


export default function Circus2024View() {

    const history = useHistory()
    const { decemberSessions, januarySessions } = useGetCircus2024Sessions()
    const handle = () => {
        history.push("/")
    }

    return (
        <PageTransition>
            <>
                <Helmet>
                    <meta property="og:site_name" content="Coliseu Porto Ageas" />
                    <meta property="og:url" content={`${Config.APP}circo-2024`} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Coliseu Porto Ageas" />
                    <meta property="og:description" content="De 13 de dezembro a 5 de janeiro, o Coliseu Porto Ageas apresenta um espetáculo memorável." />
                </Helmet>


                <div className={styles.Hero} />

                <div className={styles.Circus2024}>

                    <section className={styles.Intro}>


                        <p>
                            De 13 de dezembro a 5 de janeiro, o Coliseu Porto Ageas apresenta um espetáculo memorável, irrepetível e surpreendente, que desvenda o que as artes circenses têm
                            de mais fantástico. Uma tradição ininterrupta no Coliseu desde 1941 que faz sonhar - este ano, com bolas! - o público de todas as idades. Venha sonhar connosco!
                        </p>



                        <Grid container className={styles.Credits}>
                            <Grid item xs={12}>
                                <div>
                                    <h5>História Original</h5>
                                    <p>Afonso Cruz</p>
                                </div>
                                <div>
                                    <h5>Encenação</h5>
                                    <p>Ana Luena</p>
                                </div>
                                <div>
                                    <h5>Música</h5>
                                    <p>Carlos Tê e Pedro Vidal</p>
                                </div>
                                <div className={styles.Actors}>
                                    <h5>Atores</h5>
                                    <p>Pedro Almendra</p>
                                    <p>Susana Madeira</p>
                                </div>

                            </Grid>
                        </Grid>
                    </section>



                    <section className={styles.Sessions}>

                        <Separator title="SESSÕES" />

                        <Grid container justify="center" className={styles.SessionMonth}>
                            <Typography variant="RocGroteskRegular20">
                                DEZEMBRO
                            </Typography>
                        </Grid>

                        <Grid container spacing={2} className={styles.SessionGrid}>
                            {decemberSessions.map(session =>
                                <Session session={session} key={session.id} />
                            )}
                        </Grid>

                        <Grid container justify="center" className={styles.SessionMonth}>
                            <Typography variant="RocGroteskRegular20">
                                JANEIRO
                            </Typography>
                        </Grid>

                        <Grid container spacing={2} className={styles.SessionGrid}>
                            {januarySessions.map(session =>
                                <Session session={session} key={session.id} />
                            )}
                        </Grid>


                        <p>Bilhetes entre 10€ e 20€, à venda no Coliseu, Ticketline e locais habituais.</p>

                        <div className={styles.Discounts}>
                            <p>Descontos</p>
                            <p>75% crianças até aos 3 anos</p>
                            <p>50% crianças até aos 12 anos</p>
                            <p>20% Amigos do Coliseu e Cartão Porto</p>
                        </div>


                        <Grid container justify="center" className={styles.Call2Action}>
                            <Button onClick={handle} variant="outlined">
                                Agenda Coliseu
                            </Button>
                        </Grid>

                    </section>

                    <section className={styles.Artists}>

                        <Separator title="ARTISTAS" />

                        <Grid container className={styles.ArtistsContainer}>
                            {artists.map(artist =>
                                <Artist artist={artist} key={artist.id} />
                            )}
                        </Grid>

                    </section>

                    <section className={styles.Gallery}>

                        <Separator title="GALERIA" />

                        <Grid container direction="row" justify="center">
                            <Grid item xs={12} lg={10}>
                                <EventGallery photos={photos} hideTitle />
                            </Grid>
                        </Grid>
                    </section>

                    <section className={styles.Staff}>

                        <Separator title="FICHA TÉCNICA" />

                        <Grid container className={styles.TechTeam}>
                            <Grid item xs={12} md={6}>
                                <div>
                                    <p><b>História</b> Afonso Cruz</p>
                                    <p><b>Encenação</b> Ana Luena</p>
                                    <p><b>Música</b> Carlos Tê e Pedro Vidal</p>
                                    <p><b>Desenho de Luz</b> Cárin Geada</p>
                                    <p><b>Operação de Som</b> Carlos Matos, Miguel Moreira, Miguel Silva</p>
                                    <p><b>Operação de Luz</b> José Cunha, Pedro Moreira</p>
                                    <p><b>Operação de followspot</b> António Alves, António Mota, Tiago Mota</p>
                                    <p><b>Assistência de Pista</b> Stay On the Scene</p>
                                    <p><b>Apoio à Produção</b> Luísa Teixeira</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div>
                                    <p><b>Atores</b> Pedro Almendra e Susana Madeira</p>
                                    <p><b>Palhaços</b> Datinelli Brothers</p>
                                    <p><b>Aros</b> Priscila Alves</p>
                                    <p><b>Trapézio Duo</b> Kimberly Bargenquast e Flynn Miller</p>
                                    <p><b>Roller-Skating</b> Beata Surmiak e Jamie Swan</p>
                                    <p><b>Hand Balancing</b> Duo Jordanella</p>
                                    <p><b>Pole Aéreo</b> Mateo Sito</p>
                                    <p><b>Funambulismo</b> Los Guerreros</p>
                                    <p><b>Fitas Aéreas</b> Agustin Rodriguez Beltran</p>
                                </div>
                            </Grid>
                        </Grid>

                        <div className={styles.Performers}>
                            <p>
                                <b>INTÉRPRETES (2.º ANO DE DANÇA DO BALLETTEATRO):</b> Afonso Pinhel, Ana Luiza Alves, Ana Rita Sá, Beatriz Ribeiro,
                                Carolina Lopes, Gabriela Teixeira, Gonçalo Pereira, Iara Belo, Mafalda Silva, Mara Cruz, Maria Nazaré Marinho, Maria Pinto,
                                Mariana Costa, Matilde Pereira, Renata Soares, Sara Oliveira, Telma Oliveira, Ana Filipa Rocha, Benedita Oliveira, Catarina Guimarães,
                                Cátia Rodrigues, Francisca Pinto, Hugo Nascimento, Isabel Matos, Laura Moreira, Mafalda Macedo, Maria Jorge Fonseca, Mariana Silva,
                                Marta Barbosa, Matilde Sousa, Ricardo Pereira, Sabrina Vásquez, Sofia Rocha, Leonor Truta
                            </p>
                        </div>

                        <div className={styles.Coordination}>
                            <p><b>COORDENAÇÃO GERAL:</b> Miguel Guedes</p>
                        </div>

                        <div className={styles.Team}>
                            <p>
                                <b>Equipa Coliseu:</b> André Gonçalves, Artem Yakuni, Celestino Teixeira, Eduardo Amorim Cambra, Eduardo Cambra, Fátima Pinto,
                                Fernando Mota, Filipa Godinho, Graça Barreto, Ivan Pinto, João Pereira, João Puig, José Carlos Pinto Coelho, José Cunha, Juliana Agostinho,
                                Justina Vilela, Lara Jacinto, Leandro Couto, Luís Barros, Paulo Alves, Pedro Moreira, Sara Coelho, Tiago Azevedo
                            </p>
                        </div>

                    </section>

                    <section className={styles.Footer}>
                        <Grid container justify="center">
                            <img src={logos} alt="logotipos apoios" />
                        </Grid>
                    </section>

                </div>
            </>
        </PageTransition>
    )
}

