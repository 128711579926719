import React from 'react'
import { useGtXs } from '../../hooks/useGtXs'
import { educationalHighlights } from '../EducationalHighlights/EducationalHighlights'
import HomeEducationalServiceDesktop from '../HomeEducationalServiceDesktop/HomeEducationalServiceDesktop'
import HomeEducationalServiceMobile from '../HomeEducationalServiceMobile/HomeEducationalServiceMobile'

export interface HomeEducationalAction {
    name: string
    route: string
    linkName: string
}


export default function HomeEducationalService() {

    const gtXs = useGtXs()

    const content = educationalHighlights

    const action = {
        name: "Serviço Educativo Coliseu",
        route: "/educativo",
        linkName: "Serviço Educativo"
    }

    if (gtXs) {
        return <HomeEducationalServiceDesktop content={content} action={action} />
    }
    return <HomeEducationalServiceMobile content={content} action={action} />
}
