import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import getSectionSubsections from '../Sitemap/Helpers/getSectionSubsections';
import Submenu from '../Submenu/Submenu';

interface NewsViewProps extends RouteComponentProps<{}> { }

export default function NewsView(props: NewsViewProps) {

    const { match } = props

    const subsections = getSectionSubsections(5, true)
    const subsection = subsections.find(subsection => subsection.route === match.path)
    const component = subsection ? subsection.component : null

    return (
        <>
            <Submenu items={subsections} path={match.path} />
            {component}
        </>
    )
}
