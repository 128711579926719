import React from 'react'
import { HomeNewsAction, HomeNewsContent } from '../HomeNews/HomeNews'
import ThreeColumnSection from '../ThreeColumnSection/ThreeColumnSection'

interface HomeNewsDesktopProps {
    content: HomeNewsContent[]
    action: HomeNewsAction
}

export default function HomeNewsDesktop(props: HomeNewsDesktopProps) {
    const { content, action } = props
    return (
        <ThreeColumnSection content={content || []} action={action} contentStyle="long" />
    )
}
