import React, { CSSProperties } from 'react';
import Typography, { ExtendedTypographyVariant } from '../Typography/Typography';

interface ParagraphProps {
    children: any
    style?: CSSProperties
    variant?: ExtendedTypographyVariant
    className?: string
}

export default function Paragraph(props: ParagraphProps) {
    const { children, style = {}, variant = "body0", className = "" } = props
    return (
        <Typography variant={variant} paragraph={true} style={style} className={className}>
            {children}
        </Typography>
    )
}
