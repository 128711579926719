import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { truncateString } from '../../services/Utils';
import EventOptions from '../EventOptions/EventOptions';
import FormatDate from '../FormatDate/FormatDate';
import FormatTime from '../FormatTime/FormatTime';
import PlusIcon from '../PlusIcon/PlusIcon';
import Typography from '../Typography/Typography';
import styles from './EventPreview.module.scss';

interface EventPreviewProps {
    event: any
    showOptions: boolean
}

export default function EventPreview(props: EventPreviewProps) {
    const { event, showOptions } = props
    const { name, slug, startDate, state } = event

    const active = state === 'ACTIVE'
    const classes = classNames([styles.EventPreview], { [styles.Soldout]: !active })
    const eventUrl = 'evento/' + slug;

    const handleClick = () => {
        window.open(eventUrl, '_self')
    }

    return (
        <Grid container direction="column" justify="space-between" className={classes}>
            <Link to={eventUrl} className={styles.EventLink} />
            <Typography variant="menu" className={styles.Name}>
                {truncateString(name, 38, true)}
            </Typography>
            <Typography variant="body1" className={styles.Date}>
                <FormatDate date={startDate} monthCaps /><br />
                <FormatTime datetime={startDate} />
            </Typography>
            <Grid container justify="space-around" alignItems="center" className={styles.Actions}>
                <IconButton style={{ padding: 0 }} onClick={handleClick}>
                    <PlusIcon />
                </IconButton>
                <EventOptions show={showOptions} event={event} />
            </Grid>
        </Grid>
    )
}
