import { Field, FormikValues } from 'formik';
import { Checkbox } from 'formik-material-ui';
import React from 'react';
import { Config } from '../../services/Config';
import styles from './SelectNewsletters.module.scss';

export const newslettersList = [
    { list: +Config.MAILJET_GENERAL_LIST_ID, name: "Geral" },
    { list: +Config.MAILJET_EDUCATIONAL_LIST_ID, name: "Serviço Educativo" }
]

export const selectedNewsletters = (values: FormikValues) => {
    return Object.entries(values.newsletters)
        .filter(([id, isSelected]) => isSelected) // Filtra apenas as newsletters selecionadas
        .map(([id]) => parseInt(id)); // Mapeia para obter apenas os IDs
}


export default function SelectNewsletters() {
    return (
        <div className={styles.SelectNewsletters}>
            <p >
                Selecionar Newsletters
	    </p>

            <ul>
                {newslettersList.map((newsletter: any) => (
                    <li key={newsletter.list}>
                        <Field component={Checkbox} type="checkbox" name={`newsletters.${newsletter.list}`} />
                        {newsletter.name}
                    </li>
                ))}
            </ul>
        </div>
    )
}
