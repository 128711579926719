import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import React from 'react';
import { useGtXs } from '../../hooks/useGtXs';
import { CircusMenuItem } from '../CircusView/CircusView';
import Typography from '../Typography/Typography';
import styles from './CircusMenu.module.scss';

interface CircusMenuProps {
    items: CircusMenuItem[]
    selected: CircusMenuItem
    select: (item: CircusMenuItem) => void
}

export default function CircusMenu(props: CircusMenuProps) {
    const { items, selected, select } = props

    const gtXs = useGtXs()
    const justify = gtXs ? "center" : "flex-start"

    const handleClick = (item: CircusMenuItem) => {
        if (item.component) {
            select(item)
        } else if (item.link) {
            window.open(item.link)
        } else {
            console.log('Error item link or component must be defined',);
        }
    }

    return (
        <Grid container justify={justify} alignItems="center" wrap="nowrap" className={styles.CircusMenu}>
            {items.map(item => {
                const className = classNames({ [styles.YearButton]: true, [styles.Selected]: selected.id === item.id })
                return (
                    <Typography variant="menu" onClick={() => handleClick(item)} key={item.id}>
                        <span className={className}>
                            {item.name}
                        </span>
                    </Typography>
                )
            })}
        </Grid >
    )
}
