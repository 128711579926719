import React from 'react';
import { useHistory } from 'react-router';
import { EduActivityPreviewType } from '../SchoolsView/Activities';
import styles from './EduActivityPreview.module.scss';

export function EduActivityPreview(props: EduActivityPreviewType) {
    const { slug, name, cover, category, targetAudience } = props

    const history = useHistory()

    const handleClick = () => {
        history.push(`/educativo/programa-escolas/atividade/${slug}`)
    }

    return (
        <div className={styles.EduActivityPreview} onClick={handleClick}>
            <img src={cover} alt={name} />
            <div className={styles.Category}>
                {category}
            </div>
            <div>{targetAudience}</div>
        </div>
    )
}
