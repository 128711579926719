import collectionCategories from "../data/collection-categories"

export default function useCollectionCategory(slug: string) {
    const records = collectionCategories.filter(collectionCategory => collectionCategory.slug === slug)

    if (records.length !== 1) {
        throw new Error("Wrong query")
    }

    return records[0]
}
