import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import getSectionSubsections from '../Sitemap/Helpers/getSectionSubsections';
import Submenu from '../Submenu/Submenu';

interface ProgramsViewProps extends RouteComponentProps<{}> { }

export default function ProgramsView(props: ProgramsViewProps) {

    const { match } = props

    const subsections = getSectionSubsections(4)
    const subsection = subsections.find(subsection => subsection.route === match.path)
    const component = subsection ? subsection.component : null

    return (
        <>
            <Submenu items={subsections} path={match.path} />
            {component}
        </>
    )
}
