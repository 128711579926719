import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import logo from '../../assets/images/newsletter/logo_black.webp';
import { useActivateOneSubscriptionMutation } from '../../generated/graphql';
import Show from '../Show/Show';
import SocialNetworks from '../SocialNetworks/SocialNetworks';
import Typography from '../Typography/Typography';
import styles from './ActivateView.module.scss';

export default function ActivateView(props: any) {
    const list = +props.match.params.list;
    const token = props.match.params.token;

    const [isActivating, setIsActivating] = useState(false)
    const [activate, { data, loading }] = useActivateOneSubscriptionMutation({ onError: () => console.error("Erro na confirmação.") })

    if (!isActivating) {
        setIsActivating(true)
        activate({ variables: { input: { subscription: { token, list } } } })
    }

    return (
        <div>
            <Grid container justify="center" className={styles.Wrapper}>
                <main>
                    <Grid container justify="center" direction="column" className={styles.Activate}>

                        <div className={styles.Logo}>
                            <img src={logo} alt="logo coliseu porto ageas" />
                        </div>

                        <Show on={loading}>
                            <Typography variant="subtitle1">
                                Estamos a proceder à ativação da sua subscrição.....
			    </Typography>
                        </Show>

                        <Show on={Boolean(!loading && data?.activateOneSubscription?.email)}>

                            <Typography variant="h2">
                                Obrigado por subscrever
			    </Typography>

                            <Typography variant="h3">
                                As nossas newsletters
                            </Typography>

                            <Typography variant="subtitle1">
                                Siga-nos também nas Redes Sociais
			    </Typography>

                            <div className={styles.SocialNetworks}>
                                <SocialNetworks />
                            </div>
                        </Show>

                        <Show on={Boolean(!loading && !data?.activateOneSubscription?.email)}>
                            <Typography variant="subtitle1" className={styles.Error}>
                                Erro ao confirmar subscrição na newsletter. Tente novamente, por favor.
			    </Typography>
                        </Show>

                    </Grid>
                </main>
            </Grid>
        </div>
    )
}
