import { Maybe } from "graphql/jsutils/Maybe"
import React, { ReactNode } from "react"
import { Link as ReactRouterLink } from "react-router-dom"

interface LinkProps {
    name?: string
    route: string
    className?: Maybe<string>
    children?: ReactNode;
}

export function Link(props: LinkProps) {
    const { name = "", route, children, className = "" } = props

    const isExternal = route.startsWith("http")

    const content = children || name

    if (isExternal) {
        return (
            <a href={route} target="_blank" rel="noopener noreferrer" className={className!}>
                {content}
            </a>
        )
    }

    return (
        <ReactRouterLink to={route} className={className!}>
            {content}
        </ReactRouterLink>
    )
}
