import getYouTubeID from 'get-youtube-id';
import React, { useState } from 'react';
import YouTube from 'react-youtube';
import { Maybe } from '../../generated/graphql';
import styles from './VideoPlayerAspect.module.scss'

interface VideoPlayerAspectProps {
    url?: Maybe<string>
}

export default function VideoPlayerAspect(props: VideoPlayerAspectProps) {
    const { url } = props
    const [aspectRatio, setAspectRatio] = useState('56.25%') // por defeito 16/9

    if (!url) return null

    const videoId = getYouTubeID(url)

    if (!videoId) return null

    const onReady = (event: any) => {
        const player = event.target;
        const iframe = player.getIframe();
        const width = iframe.width;
        const height = iframe.height;
        const calculatedAspectRatio = (height / width) * 100;
        setAspectRatio(`${calculatedAspectRatio}%`);
    }

    return (
        <div className={styles.VideoWrapper} style={{ paddingTop: aspectRatio }}>
            <YouTube videoId={videoId} onReady={onReady} />
        </div>
    )
}
