import React from "react";
import EventPreview from "../EventPreview/EventPreview";
import EventSeparator from "../EventSeparator/EventSeparator";

interface EventCellProps {
    event: any
    showOptions: any
}

export default function EventCell(props: EventCellProps) {
    const { event, showOptions } = props

    if (event.year) {
        return <EventSeparator year={event.year} month={event.monthNumber} />
    }

    return <EventPreview event={event} showOptions={showOptions} />
}
