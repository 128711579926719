import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { clsx } from 'clsx';
import React, { CSSProperties, MouseEvent, ReactChild } from 'react';
import styles from './Modal.module.scss';

interface ModalProps {
    isOpen: boolean
    children: ReactChild | null
    title?: string
    onClose: () => void
    showClose?: boolean
    maxWidth?: 'md' | 'lg' | 'sm'
    fullWidth?: boolean
    fullScreen?: boolean
    onTop?: boolean
}

export default function Modal(props: ModalProps) {
    const {
        isOpen = false,
        children,
        title,
        onClose,
        showClose = true,
        maxWidth = "lg",
        fullWidth = true,
        fullScreen = false,
        onTop = false
    } = props

    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
    }

    const classes = clsx({ [styles.Modal]: true, [styles.OnTop]: onTop })

    return (
        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            onClose={onClose}
            className={classes}
            open={isOpen}
            fullScreen={fullScreen}
        >
            <DialogTitle>{title}</DialogTitle>
            {
                showClose &&
                <IconButton
                    className={styles.CloseAction}
                    aria-label="close"
                    onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            }
            <DialogContent onClick={handleClick}>
                {children}
            </DialogContent>
        </Dialog>
    )
}

