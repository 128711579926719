import { Event, EventsQuery } from "../../../../generated/graphql";
import { DeepPartial } from "../../../../types/types";
import formatSession, { Session } from "./formatSession";

export default function transformEvents(data?: EventsQuery) {
    const events: DeepPartial<Event>[] = data?.events?.nodes ?? []

    const decemberEvents = events.filter((event: any) => event.startDate.startsWith('2024-12'))
    const januaryEvents = events.filter((event: any) => event.startDate.startsWith('2025-01'))

    decemberEvents.sort((a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
    januaryEvents.sort((a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())

    const decemberSessions: Session[] = decemberEvents.map(event => {
        return formatSession(event as Event)
    })

    const januarySessions: Session[] = januaryEvents.map(event => {
        return formatSession(event as Event)
    })

    return { decemberSessions, januarySessions }
}
