import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useState } from 'react';
import { useDeleteManySubscriptionsMutation } from '../../generated/graphql';
import { formInit } from '../../utils/forms';
import SelectNewsletters, { newslettersList, selectedNewsletters } from '../SelectNewsletters/SelectNewsletters';
import unsubscribeSchema from './UnsubscribeSchema';
import styles from './UnsubscribeView.module.scss';

export default function UnsubscribeView() {

    const [unsubscribe] = useDeleteManySubscriptionsMutation()
    const [errors, setErrors] = useState<string[]>([])
    const [messages, setMessages] = useState<string[]>([])
    const { schema, initial } = formInit<{ email: string }>(unsubscribeSchema)


    const handleSubmit = async (values: any, helpers: any) => {
        setErrors([])
        setMessages([])

        const { resetForm } = helpers
        const { email } = values
        const lists = selectedNewsletters(values)

        const response = await unsubscribe({ variables: { input: { subscriptions: { email, lists } } } })

        // se todas subscrições correram bem
        if (response?.data?.deleteManySubscriptions.every(subscription => subscription.success)) {
            setMessages(["Remoção da subscrição efetuada com sucesso."])
            resetForm();
        } else {
            const messagesList: string[] = []
            const errorsList: string[] = []
            response?.data?.deleteManySubscriptions.forEach((subscription: any) => {
                const newsletter = newslettersList.find(n => n.list === subscription.list)
                if (subscription.success) {
                    messagesList.push(`Remoção da subscrição na newsletter ${newsletter?.name} efetuada com sucesso.`)
                } else {
                    errorsList.push(`Erro ao remover subscrição da newsletter ${newsletter?.name}.`)
                }
            })

            setMessages(messagesList)
            setErrors(errorsList)
        }
    }

    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <main>

                <h1>Remover Subscrição Newsletter</h1>

                <Formik initialValues={initial} validationSchema={schema} onSubmit={handleSubmit}>
                    {({ isValid, dirty, submitForm, isSubmitting }) => (
                        <Form>
                            <Grid container justify="center" alignItems="center">

                                <Grid container direction="column">

                                    <Field
                                        component={TextField}
                                        name="email"
                                        type="text"
                                        label="email"
                                        data-cy="email"
                                    />


                                    <SelectNewsletters />

                                    <p></p>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={Boolean(isSubmitting || !isValid || !dirty)}
                                        onClick={submitForm}
                                        data-cy="submit"
                                    >
                                        Submeter
				    </Button>

                                    <div data-cy="errors" className={styles.ValidationError}>
                                        {errors.map(error => <p>{error}</p>)}
                                    </div>

                                    <div data-cy="errors" className={styles.Success}>
                                        {messages.map(message => <p>{message}</p>)}
                                    </div>

                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </main>
        </Grid>
    )
}
