import { Grid } from "@material-ui/core";
import React from "react";
import Loading from "../Loading/Loading";

export default function LoadingView() {
    return (
        <Grid container justify="center" alignItems="flex-start" style={{
            height: '100vh', padding: '100px'
        }}>
            <Loading />
        </Grid >

    )
}
