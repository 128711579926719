import { EduActivityPreviewType } from "../../SchoolsView/Activities";

export default function filterByTargetAudience(activities: EduActivityPreviewType[], audience: number) {

    if (audience === 1) return activities

    return activities.filter(activity => {
        return activity.targetAudienceIds.includes(audience)
    });
}

export function filterByCategory(activities: EduActivityPreviewType[], category: number) {
    return activities.filter(activity => activity.categoryId === category)
}
