import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        // manter sincronizado com o typography
        fontFamily: "roc-grotesk, sans-serif",
        h1: {
            fontSize: 56,
            lineHeight: 1.22,
            fontWeight: 700,
            marginBottom: 36,
            '@media (max-width:600px)': {
                fontSize: 40,
            },
        },
        h3: {
            fontSize: 44,
            lineHeight: 1.1,
            fontWeight: 700,
            marginBottom: 28,
            '@media (max-width:600px)': {
                fontSize: 31,
            },
        },
        h6: {
            fontSize: 26,
            lineHeight: 1.22,
            fontWeight: 700
        },
        subtitle1: {
            fontSize: 24,
            fontWeight: 700
        },
        subtitle2: {
            fontSize: 22,
            fontWeight: 700
        },
        body1: {
            fontSize: 18,
            fontWeight: 400,
        },
        body2: {
            fontSize: 16,
            fontWeight: 400,
        },
        caption: {
            fontSize: 14,
            fontWeight: 300
        }
    },
})

export default theme
