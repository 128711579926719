import { Fade } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { usePostBySlugQuery } from '../../generated/graphql';
import { mediaUrl } from '../../services/Utils';
import BindHTML from '../BindHTML/BindHTML';
import CustomButton from '../CustomButton/CustomButton';
import EventGallery from '../EventGallery/EventGallery';
import FormatDate from '../FormatDate/FormatDate';
import LoadingView from '../LoadingView/LoadingView';
import PostDetailsHelmet from '../PostDetailsHelmet/PostDetailsHelmet';
import Typography from '../Typography/Typography';
import VideoPlayerAspect from '../VideoPlayerAspect/VideoPlayerAspect';
import getNewsSlug from './Helpers/getNewsSlug';
import styles from './PostDetailsView.module.scss';

export default function PostDetailsView() {

    const location = useLocation()
    const slug = getNewsSlug(location.pathname)
    const { data, loading } = usePostBySlugQuery({ variables: { slug } })

    if (loading) {
        return <LoadingView />
    }

    const post = data?.postBySlug

    if (!post) {
        return <p>Não foi possível encontrar notícia.</p>;
    }

    const cover = mediaUrl(post.cover);

    return (
        <Fade in={!loading}>
            <Grid container justify="center" className={styles.Wrapper}>
                <PostDetailsHelmet post={post} />
                <main>
                    <img src={cover} alt="" className={styles.PostImage} />

                    <Typography variant="h3">
                        {post.name}
                    </Typography>

                    <Typography variant="subtitle2" style={{ marginBottom: 25 }}>
                        <FormatDate date={post.publishedAt} format="DD MMMM [de] YYYY" />
                    </Typography>

                    <BindHTML className={styles.Description}>
                        {post.description}
                    </BindHTML>

                    <div className={styles.VideoPlayerWrapper}>
                        <VideoPlayerAspect url={post.videoUrl} />
                    </div>

                    <EventGallery photos={post.postPhotos} title="Galeria" />

                    <hr style={{ marginTop: 25, marginBottom: 25 }} />
                    <Grid container justify="center" className={styles.ArchiveSection}>
                        <CustomButton url="../../arquivo-noticias" label="Arquivo" />
                    </Grid>
                </main>
            </Grid>
        </Fade>
    )
}
