import { Grid } from '@material-ui/core';
import React from 'react';
import OutlineButton from '../OutlineButton/OutlineButton';
import styles from './ToursView.module.scss';

export default function ToursView() {
    return (
        <div>
            <div className={styles.SectionBanner} />

            <div className={styles.Tours}>
                <h1>Brevemente</h1>
                <div className={styles.CallToAction}>
                    <p>Siga a newsletter do Coliseu para informações em primeira mão</p>
                </div>
                <Grid container justify="center" className={styles.Action}>
                    <OutlineButton
                        name="Subscrever Newsletter"
                        route="/newsletter/subscrever"
                        fullWidth={false}
                    />
                </Grid>
            </div>
        </div>
    )
}
