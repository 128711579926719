import { Grid } from '@material-ui/core';
import React from 'react';
import Paragraph from '../Paragraph/Paragraph';
import Typography from '../Typography/Typography';
import styles from './ImageRightsView.module.scss';

export default function CookiesView() {
    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid item xs={12} sm={4} className={styles.Image} />
            <Grid item xs={12} sm={8} className={styles.Text}>
                <main>
                    <Typography variant="h1colisseum">
                        Direito à Imagem
		    </Typography>

                    <section>
                        <Paragraph>Os eventos realizados no Coliseu Porto Ageas poderão ser objeto de captação e gravação de imagens e som, quer para efeitos institucionais (arquivísticos, integração em documentos - vg relatório e contas -, entre outros), quer para efeitos promocionais e comerciais (spots, anúncios, entre outros), quer para efeitos comunicacionais e informativos (peças jornalísticas em órgãos de comunicação social).</Paragraph>
                        <Paragraph>Ao adquirir o bilhete, bem como ao aceder ao Coliseu Porto Ageas, para assistir ao evento/espectáculo a que o ingresso respeita, autoriza expressamente o Coliseu Porto Ageas, não só à captação e gravação de imagens e som, como à sua utilização, direta ou indireta, para os efeitos sobreditos.</Paragraph>
                        <Paragraph>Caso necessite de qualquer esclarecimento adicional ou pretenda suscitar alguma questão, contacte-nos.</Paragraph>

                    </section>
                </main>
            </Grid>
        </Grid>
    )
}
