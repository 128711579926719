import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Helmet } from "react-helmet";
import photo1 from '../../../assets/images/circus/2020/photos/coliseu_porto_ageas_circo_2020_01.jpg';
import photo2 from '../../../assets/images/circus/2020/photos/coliseu_porto_ageas_circo_2020_02.jpg';
import photo3 from '../../../assets/images/circus/2020/photos/coliseu_porto_ageas_circo_2020_03.jpg';
import photo4 from '../../../assets/images/circus/2020/photos/coliseu_porto_ageas_circo_2020_04.jpg';
import photo5 from '../../../assets/images/circus/2020/photos/coliseu_porto_ageas_circo_2020_05.jpg';
import photo6 from '../../../assets/images/circus/2020/photos/coliseu_porto_ageas_circo_2020_06.jpg';
import photo7 from '../../../assets/images/circus/2020/photos/coliseu_porto_ageas_circo_2020_07.jpg';
import photo8 from '../../../assets/images/circus/2020/photos/coliseu_porto_ageas_circo_2020_08.jpg';
import photo9 from '../../../assets/images/circus/2020/photos/coliseu_porto_ageas_circo_2020_09.jpg';
import { Config } from '../../../services/Config';
import PageTransition from '../../PageTransition/PageTransition';
import CircusPhotoGallery from '../CircusPhotoGallery/CircusPhotoGallery';
import CircusRow from '../CircusRow/CircusRow';
import artists from './Artists.json';
import styles from './Circus2020View.module.scss';

export default function Circus2020View() {

    const photos = [
        photo1,
        photo2,
        photo3,
        photo4,
        photo5,
        photo6,
        photo7,
        photo8,
        photo9
    ];


    const buyLink = "https://www.coliseu.pt/evento/20210103-circo-de-natal-coliseu-porto-ageas/";
    return (
        <PageTransition>
            <>
                <Helmet>
                    <meta property="og:site_name" content="Coliseu Porto Ageas" />
                    <meta property="og:url" content={`${Config.APP}circo-2020`} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Coliseu Porto Ageas" />
                    <meta property="og:description" content="Reiventando uma tradição histórica, duas dezenas de artistas apresentam números em estreia absoluta de acrobacia aérea, ilusionismo, trapézio, clown, mastro chinês, corda bamba, forças opostas, parkour e malabarismo, numa pista com todas as condições de segurança para artistas e público." />
                    <meta property="og:image" content={`${Config.APP}assets/images/circus/2020/circo2020_social.jpg}`} />
                    <meta property="og:image:width" content="836" />
                    <meta property="og:image:height" content="800" />
                </Helmet>

                <div className={styles.Circus2020}>
                    <div className={styles.Hero} />

                    <Grid container direction="column" className={styles.Intro} justify="center" alignItems="center">
                        <h1>O Circo volta a casa</h1>
                        <h2>
                            A um 2020 recheado de exceções, o Coliseu Porto Ageas responde com um Circo de Natal excecional! De 11 de dezembro até dia 3 de janeiro, um dos espetáculos mais queridos das famílias nesta época natalícia regressa com os melhores artistas em português e uma orquestra ao vivo, para mostrar, diante dos nossos olhos, o que as artes circenses têm de mais fantástico.
                        </h2>

                        <Button className={styles.BuyLink} href={buyLink}>
                            Compre já
                        </Button>


                        <h4>Reiventando uma tradição histórica, duas dezenas de artistas apresentam números em estreia absoluta de acrobacia aérea, ilusionismo, trapézio, clown, mastro chinês, corda bamba, forças opostas, parkour e malabarismo, numa pista com todas as condições de segurança para artistas e público.</h4>

                        <h4>Uma das grandes novidades é a criação da Orquestra Circo Coliseu Porto Ageas, conduzida pelo maestro Cesário Costa, que interpretará ao vivo em todas as sessões uma banda sonora original, especialmente criada pelo compositor Filipe Raposo.</h4>

                        <h4>Esta viagem de emoções e descobertas será feita com todas as condições de segurança e lotação reduzida que o Coliseu tem cumprido em todos os espetáculos que tem apresentado. Venha conhecer um espetáculo com 79 anos de história, que se mostra cada vez mais novo!</h4>
                    </Grid>


                    {artists.map(artist => <CircusRow key={Math.random()} {...artist} />)}

                    <CircusPhotoGallery photos={photos} year={2020} />

                </div>
            </>
        </PageTransition>
    )
}
