import { Grid } from '@material-ui/core';
import React from 'react';
import map from '../../assets/images/ticket_office/directions/map.png';
import Paragraph from '../Paragraph/Paragraph';
import Typography from '../Typography/Typography';
import styles from './DirectionsView.module.scss';

export default function DirectionsView() {
    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid item xs={12} sm={4} className={styles.Image} />
            <Grid item xs={12} sm={8} className={styles.Text}>
                <main>

                    <Typography variant="h1colisseum">
                        Centro da cidade
                    </Typography>

                    <Paragraph>
                        É na raiz da cidade, bem perto dos principais pontos turísticos, que o Coliseu se encontra.
                        Quem passeia pelo centro do Porto, entre os Clérigos e os Aliados, não pode deixar de reparar
                        na torre da fachada do edifício. Esta espécie de farol de néon que ilumina habitantes, visitantes e espectadores.
                    </Paragraph>

                    <section>

                        <img src={map} alt="" className={styles.MapImage} />

                        <Paragraph style={{ marginTop: 25 }}>
                            <span style={{ fontWeight: 700 }}>Morada</span><br />
                            Rua Passos Manuel 137 4000-385 Porto
                        </Paragraph>

                        <Paragraph variant="body1">
                            <br />
                            <b>Como chegar</b><br />
                        </Paragraph>

                        <Paragraph variant="body1">
                            De carro:<br />
                            Coordenadas GPS: Latitude: 41.146818 | Longitude: -8.605492<br />
                        </Paragraph>

                        <Paragraph variant="body1">
                            De comboio:<br />
                            Estação de São Bento<br />
                        </Paragraph>

                        <Paragraph variant="body1">
                            De metro:<br />
                            Estação do Bolhão ou estação dos Aliados<br />
                        </Paragraph>

                        <Paragraph variant="body1">
                            De autocarro:<br />
                            Paragem Santa Catarina, na Rua Formosa: 305, 401, 700, 800 e 801<br />
                            Paragem Praça da Batalha: 207, 300, 400, 904 e 905<br />
                        </Paragraph>

                        <Paragraph variant="body1">
                            <br />
                            <b>Onde estacionar perto do Coliseu Porto Ageas?</b><br />
                        </Paragraph>

                        <Paragraph variant="body1">
                            Parque dos Poveiros - situado na Praça dos Poveiros
                        </Paragraph>

                        <Paragraph variant="body1">
                            Parque D. João I - situado na Praça D. João I
                        </Paragraph>

                        <Paragraph variant="body1">
                            Parque Central - situado na Rua da Alegria
                        </Paragraph>

                        <Paragraph variant="body1">
                            Garagem Passos Manuel - situado na Rua Passos Manuel, em frente ao Coliseu
                        </Paragraph>

                        <Paragraph variant="body1">
                            Centro comercial Via Catarina - Acessos pela Rua Formosa e Rua Fernandes Tomás
                            (gratuito de segunda a sábado das 20h à 01h e aos domingos e feriados todo o dia).
                        </Paragraph>

                        <Paragraph variant="body1">
                            As informações sobre os parques de estacionamento podem sofrer alterações. Verifique sempre previamente
                            os horários e disponibilidade nas respetivas páginas de <i>parking</i>.
                        </Paragraph>
                    </section>
                </main>
            </Grid>
        </Grid>
    )
}
