import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../Consent/Consent.module.scss';

const Consent = () => {

    const consented = window.localStorage.getItem('consented');
    const [show, setShow] = useState(!consented);

    const handleClick = () => {
        window.localStorage.setItem('consented', 'true');
        setShow(false);
    };

    if (!show) {
        return null;
    }

    return (
        <Grid container direction="column" alignItems="center" className={styles.Consent}>
            <p>Este site utiliza cookies de forma a melhorar a experiência do utilizador. Ao clicar em "Aceitar" estará a consentir a sua utilização. Consulte a nossa <Link to="/politica-de-privacidade">Política de Privacidade</Link> e <Link to="/cookies">Política de Utilização de Cookies</Link> para mais informação.</p>
            <Button onClick={handleClick} color="secondary" variant="contained" className={styles.Accept}>
                Aceitar
	    </Button>
        </Grid>
    );
};

export default Consent;
