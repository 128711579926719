import { Field, useFormikContext } from 'formik';
import React, { FC } from 'react';
import DateTimePicker from '../DateTimePicker/DateTimePicker';

interface InputDateTimeFieldProps {
    name: string,
    disabled?: boolean
    minDate?: Date
    maxDate?: Date
}

const InputDateTimeField: FC<InputDateTimeFieldProps> = props => {
    const { name, disabled, minDate, maxDate } = props

    const context = useFormikContext()

    // @ts-ignore
    const hasError = context?.errors[name]

    return (
        <Field
            component={DateTimePicker}
            name={name}
            disabled={disabled}
            minDate={minDate}
            maxDate={maxDate}
            error={hasError}
        />
    )
}

export default InputDateTimeField
