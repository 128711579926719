import { useRef } from 'react';

type SwipeHandler = (event: any) => void;

function useSwipe(onSwipeLeft: any, onSwipeRight: any) {
    const touchStartX = useRef<number | null>(null);
    const touchEndX = useRef<number | null>(null);

    const handleTouchStart: SwipeHandler = (event) => {
        touchStartX.current = event.touches[0].clientX;
    };

    const handleTouchEnd: SwipeHandler = (event) => {
        touchEndX.current = event.changedTouches[0].clientX;

        if (touchStartX.current !== null && touchEndX.current !== null) {
            const deltaX = touchEndX.current - touchStartX.current;
            if (deltaX > 50) {
                onSwipeRight()

            } else if (deltaX < -50) {
                onSwipeLeft()
            }
        }

        touchStartX.current = null;
        touchEndX.current = null;
    };

    return [handleTouchStart, handleTouchEnd];
}

export default useSwipe
