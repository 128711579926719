import { Grid } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { EduActivityPreviewType, getActivityById } from '../SchoolsView/Activities';
import styles from './RelatedEducationalActivities.module.scss';


interface RelatedEducationalActivitiesProps {
    activity: EduActivityPreviewType
}

export default function RelatedEducationalActivities(props: RelatedEducationalActivitiesProps) {
    const { activity } = props
    const history = useHistory()

    const handleGoTo = (slug?: string) => {
        history.push(`/educativo/programa-escolas/atividade/${slug}`)
    }

    return (
        <Grid container justify="flex-start" className={styles.Footer} wrap="wrap">
            {activity.relatedActivities.map(id => {
                const relatedActivity = getActivityById(id)
                return (
                    <img src={relatedActivity?.cover} onClick={() => handleGoTo(relatedActivity?.slug)} alt={relatedActivity?.name} />
                )
            })}
        </Grid>
    )
}
