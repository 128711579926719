import { format, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';

export function abbrevDate(dateString: string, formatString = "dd MMM", transform?: "LOWER" | "UPPER"): string {
    const date = format(parseISO(dateString), formatString, { locale: pt })
    if (transform === "LOWER") {
        return date.toLowerCase()
    } else if (transform === "UPPER") {
        return date.toUpperCase()
    }
    return date
}

/**
 * Formats a given Date object into hours and minutes, both as zero-padded strings.
 * This function uses the `pt` locale to ensure proper formatting in Portuguese.
 */
export function formatTime(date: Date): { hours: string, minutes: string } {
    const hours = format(date, 'H', { locale: pt })
    const minutes = format(date, 'mm', { locale: pt })
    return { hours, minutes }
}

/**
 * Returns the weekday name in Portuguese for the given date, removing the "-feira"
 * suffix if present and converting the result to uppercase.
 *
 * For example, a Friday normally formats to "sexta-feira" and this function will return "SEXTA".
 */
export function weekdayPT(date: Date): string {
    const weekdayFull = format(date, 'EEEE', { locale: pt })
    return weekdayFull.replace('-feira', '').toUpperCase()
}
