import DateFnsUtils from '@date-io/date-fns';
import { TextField } from '@material-ui/core';
import { CalendarToday as CalendarIcon } from '@material-ui/icons';
import { DateTimePicker as MuiDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useFormikContext } from 'formik';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { REQUIRED_FIELD } from '../../services/Config';

interface DateTimePickerProps {
    field: any,
    form: any,
    props: any,
    label?: string,
    fullWidth?: boolean,
    disabled?: boolean
    variant?: "standard" | "filled" | "outlined"
    minDate?: Date
    maxDate?: Date
}

const CustomTextField = (props: any) => {
    const { error, helperText } = props
    return (
        <TextField
            {...props}
            error={error}
            helperText={error && helperText}
            InputProps={{
                ...props.InputProps,
                endAdornment: (
                    <>
                        {props.InputProps.endAdornment}
                        <CalendarIcon color="action" />
                    </>
                ),
            }}
        />
    );
};


export default function DateTimePicker(props: DateTimePickerProps) {
    const { variant = "outlined", disabled, minDate, maxDate, field } = props
    const { name, value = null, onChange } = field

    const context = useFormikContext()

    // @ts-ignore
    const error = context?.errors[name]
    const helperText = REQUIRED_FIELD

    const handleChange = (date: Maybe<Date>) => {
        if (!date) return null
        try {
            const event = {
                target: { name, value: date }
            }
            onChange(event)
        } catch (e) { }
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiDateTimePicker
                value={value}
                onChange={handleChange}
                format="dd/MM/yyyy HH:mm"
                fullWidth
                variant="inline"
                inputVariant={variant}
                disabled={disabled}
                minDate={minDate}
                maxDate={maxDate}
                ampm={false}
                minutesStep={5}
                TextFieldComponent={CustomTextField}
                error={error}
                helperText={helperText}

            />
        </MuiPickersUtilsProvider>
    );
}
