import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { Link } from 'react-router-dom';
import getSections from '../Sitemap/Helpers/getSections';
import { Section } from '../Sitemap/SitemapData';
import Typography from '../Typography/Typography';
import styles from './MobileMenu.module.scss';

interface MobileMenuProps {
    close: () => void
}

const MobileMenuLink = ({ section, onChange }: { section: Section, onChange: any }) => {

    let component = null

    if (section.route.startsWith('http')) {
        component = (
            <a href={section.route}>{section.name}</a>
        )
    } else {
        component = (
            <Link key={section.id} to={section.route} onClick={onChange}>
                {section.name}
            </Link>
        )
    }

    return (
        <Typography variant="menu" className={styles.MobileMenuLink}>
            {component}
        </Typography>
    )
}

export default function MobileMenu(props: MobileMenuProps) {

    const { close } = props

    const sections = getSections()

    const handleChange = () => {
        close()
    }

    return (
        <Grid container direction="column" justify="flex-start" alignItems="flex-start" className={styles.MobileMenu}>
            <Grid container justify="flex-end" className={styles.CloseAction}>
                <IconButton className={styles.menuButton} onClick={close}>
                    <CloseIcon />
                </IconButton>
            </Grid>
            {sections.map(section => <MobileMenuLink key={section.id} section={section} onChange={handleChange} />)}
        </Grid>
    )
}
