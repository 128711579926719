import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { EventSortFields, SortDirection, useEventsQuery } from '../../generated/graphql';
import { getArchivedYears, getCurrentYear } from '../../services/Utils';
import ArchiveYears from '../ArchiveYears/ArchiveYears';
import EventsGrid from '../EventsGrid/EventsGrid';
import LoadingView from '../LoadingView/LoadingView';
import styles from './ArchiveView.module.scss';

export default function ArchiveView() {

    const years = getArchivedYears()
    const [year, setYear] = useState(getCurrentYear());

    const lower = `${year}-01-01`
    const upper = `${year}-12-31`
    const states = year === 2020 ? ["ACTIVE", "SOLDOUT", "POSTPONED", "CANCELED"] : ["ACTIVE", "SOLDOUT"]

    const { data, loading } = useEventsQuery({
        variables: {
            paging: { limit: 9999 },
            filter: {
                isArchived: { is: true },
                isVisible: { is: true },
                startDate: { between: { lower, upper } },
                state: { in: states }
            },
            sorting: [{ field: EventSortFields.StartDate, direction: SortDirection.Asc }]
        }
    })

    if (loading) {
        return <LoadingView />
    }

    const events = data?.events?.nodes

    if (!events) {
        return <p>Não foram encontrados eventos</p>;
    }

    const options2020 = {
        ACTIVE: false,
        POSTPONED: true,
        CANCELED: true,
        SOLDOUT: false
    }

    const options = year === 2020 ? options2020 : false;


    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <ArchiveYears years={years} selected={year} select={setYear} />
            <div className={styles.ArchiveView}>
                <EventsGrid events={events} showOptions={options} />
            </div>
        </Grid>
    )
}
