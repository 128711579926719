import desktop_1 from '../../../assets/images/agenda/separators/desktop_separator_01.webp';
import desktop_2 from '../../../assets/images/agenda/separators/desktop_separator_02.webp';
import desktop_3 from '../../../assets/images/agenda/separators/desktop_separator_03.webp';
import desktop_4 from '../../../assets/images/agenda/separators/desktop_separator_04.webp';
import desktop_5 from '../../../assets/images/agenda/separators/desktop_separator_05.webp';
import desktop_6 from '../../../assets/images/agenda/separators/desktop_separator_06.webp';
import mobile_1 from '../../../assets/images/agenda/separators/mobile_separator_01.webp';
import mobile_2 from '../../../assets/images/agenda/separators/mobile_separator_02.webp';
import mobile_3 from '../../../assets/images/agenda/separators/mobile_separator_03.webp';
import mobile_4 from '../../../assets/images/agenda/separators/mobile_separator_04.webp';
import mobile_5 from '../../../assets/images/agenda/separators/mobile_separator_05.webp';
import mobile_6 from '../../../assets/images/agenda/separators/mobile_separator_06.webp';

/** returns the path of the image associated with the month number */
export default function separatorImage(month: number, gtXs: boolean): string {

    const variant = gtXs ? "desktop" : "mobile"

    const separators: any = {
        1: {
            desktop: desktop_1,
            mobile: mobile_1
        },
        2: {
            desktop: desktop_2,
            mobile: mobile_2
        },
        3: {
            desktop: desktop_3,
            mobile: mobile_3
        },
        4: {
            desktop: desktop_4,
            mobile: mobile_4
        },
        5: {
            desktop: desktop_5,
            mobile: mobile_5
        },
        6: {
            desktop: desktop_6,
            mobile: mobile_6
        },
        7: {
            desktop: desktop_1,
            mobile: mobile_1
        },
        8: {
            desktop: desktop_2,
            mobile: mobile_2
        },
        9: {
            desktop: desktop_3,
            mobile: mobile_3
        },
        10: {
            desktop: desktop_4,
            mobile: mobile_4
        },
        11: {
            desktop: desktop_5,
            mobile: mobile_5
        },
        12: {
            desktop: desktop_6,
            mobile: mobile_6
        }
    }
    return separators?.[month]?.[variant]
}
