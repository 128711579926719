import { Grid } from '@material-ui/core';
import React from 'react';
import { useGtXs } from '../../../hooks/useGtXs';
import { range } from '../../../services/Utils';
import Typography from '../../Typography/Typography';
import styles from './Circus2024View.module.scss';

interface SeparatorProps {
    title: string
}

export default function Separator(props: SeparatorProps) {
    const { title } = props

    const gtXs = useGtXs()
    const stars = gtXs ? 4 : 2
    const variant = gtXs ? "ColisseumRegular48" : "ColisseumRegular30"

    return (
        <div className={styles.Separator}>
            <div className={styles.Line}></div>
            <div className={styles.Line}></div>
            <Grid container justify="space-evenly" alignItems="center">
                {range(1, stars).map(() =>
                    <span className={styles.Star}>
                        ★
                    </span>
                )}
                <Typography variant={variant}>
                    {title}
                </Typography>
                {range(1, stars).map(() =>
                    <span className={styles.Star}>
                        ★
                    </span>
                )}
            </Grid>
            <div className={styles.Line}></div>
            <div className={styles.Line}></div>
        </div>
    )
}
