import Grid from '@material-ui/core/Grid';
import React from 'react';
import EventCell from '../EventCell/EventCell';
import styles from './EventsGrid.module.scss';

interface EventsGridProps {
    events: any
    showOptions: any
}

export default function EventsGrid(props: EventsGridProps) {

    const { events, showOptions } = props

    let e: any[] = []

    for (var i = 0; i < events.length; i++) {

        let previous = events[i - 1] ? new Date(events[i - 1].startDate) : null
        let next = new Date(events[i].startDate)

        if (previous && previous.getFullYear() === next.getFullYear() && previous.getMonth() === next.getMonth()) {
            e.push(events[i])
        } else {
            const monthNumber = next.getMonth()
            e.push({ monthNumber, year: next.getFullYear() })
            e.push(events[i])
        }
    }

    return (
        <Grid container direction="row" className={styles.EventsGrid}>
            {e.map((event: any) => {
                return (
                    <Grid item xs={12} sm={3} md={2} key={Math.random()} className={styles.Cell}>
                        <EventCell event={event} showOptions={showOptions} />
                    </Grid>
                );
            })}
        </Grid>
    )
}
