import { Grid } from '@material-ui/core';
import React from 'react';
import contacts_banner from '../../assets/images/o_coliseu/collection/homepage/contacts_banner.webp';
import homepage_desktop_hero from '../../assets/images/o_coliseu/collection/homepage/hero_desktop.webp';
import homepage_mobile_hero from '../../assets/images/o_coliseu/collection/homepage/hero_mobile.webp';
import { useGtXs } from '../../hooks/useGtXs';
import ActionButton from '../ActionButton/ActionButton';
import CollectionCategories from '../CollectionCategories/CollectionCategories';
import Hero from '../Hero/Hero';
import Typography from '../Typography/Typography';
import styles from './CollectionView.module.scss';

export default function CollectionView() {

    const gtXs = useGtXs()

    const homepageHero = {
        xs: homepage_mobile_hero,
        gtXs: homepage_desktop_hero
    }

    const name = "Descarregar PDF"
    const url = "https://downloads.coliseu.pt/coliseu_collection.pdf"
    const action = "download"

    const description = "Com o selo de qualidade de uma Instituição com mais de 80 anos de experiência e História, queremos levar o Coliseu para fora de portas e ir ao encontro de novos públicos. A oferta é pensada para diferentes gostos e idades, desde performances de teatro, dança e eventos de cultura urbana, até momentos musicais, seja de música erudita, bandas sonoras de filmes, música para crianç̧as, sucessos da pop, ou até uma sessão com a histórica Orquestra Salão Jardim. Se é verdade que o Coliseu é conhecido pelos eventos de grande escala, aqui procuramos transportar a emoção e o impacto de um evento artístico até espaços novos e mais pequenos. Tudo o que se passa dentro das nossas portas viaja para fora do Coliseu e vai, agora, ao encontro."


    const variant = gtXs ? "RocGroteskMedium25" : "RocGroteskMedium20"

    return (
        <div className={styles.Wrapper}>
            <Hero images={homepageHero} />
            <Grid container justify="center">
                <main>

                    <CollectionCategories itemsPerRow={gtXs ? 3 : 1} />

                    <Grid container className={styles.Description}>
                        <Typography variant={variant}>
                            {description}
                        </Typography>
                    </Grid>

                    <Grid container justify="space-between" alignItems="flex-end" className={styles.Contacts}>
                        <Grid item xs={12} md={2} className={styles.InformationWrapper}>
                            <div className={styles.Information}>
                                <Typography variant="body1">
                                    <b>Contactos</b>
                                </Typography>
                                <Typography variant="body1">
                                    (+351) 223 394 940
                                </Typography>
                                <Typography variant="body1">
                                    <a href="mailto:eventos@coliseu.pt">eventos@coliseu.pt</a>
                                </Typography>
                            </div>
                            <ActionButton name={name} action={action} url={url} fullWidth={gtXs ? false : true} />
                        </Grid>
                        <Grid container justify="flex-end" item xs={12} md={10} className={styles.Banner}>
                            <img src={contacts_banner} alt="" />
                        </Grid>
                    </Grid>
                </main>
            </Grid>
        </div>
    )
}
