import Grid from '@material-ui/core/Grid';
import React from 'react';
import photo1 from '../../../assets/images/circus/2017/photos/coliseu_porto_ageas_circo_2017_01.jpg';
import photo2 from '../../../assets/images/circus/2017/photos/coliseu_porto_ageas_circo_2017_02.jpg';
import photo3 from '../../../assets/images/circus/2017/photos/coliseu_porto_ageas_circo_2017_03.jpg';
import photo4 from '../../../assets/images/circus/2017/photos/coliseu_porto_ageas_circo_2017_04.jpg';
import photo5 from '../../../assets/images/circus/2017/photos/coliseu_porto_ageas_circo_2017_05.jpg';
import photo6 from '../../../assets/images/circus/2017/photos/coliseu_porto_ageas_circo_2017_06.jpg';
import photo7 from '../../../assets/images/circus/2017/photos/coliseu_porto_ageas_circo_2017_07.jpg';
import photo8 from '../../../assets/images/circus/2017/photos/coliseu_porto_ageas_circo_2017_08.jpg';
import photo9 from '../../../assets/images/circus/2017/photos/coliseu_porto_ageas_circo_2017_09.jpg';
import photo10 from '../../../assets/images/circus/2017/photos/coliseu_porto_ageas_circo_2017_10.jpg';
import photo11 from '../../../assets/images/circus/2017/photos/coliseu_porto_ageas_circo_2017_11.jpg';
import photo12 from '../../../assets/images/circus/2017/photos/coliseu_porto_ageas_circo_2017_12.jpg';
import photo13 from '../../../assets/images/circus/2017/photos/coliseu_porto_ageas_circo_2017_13.jpg';
import photo14 from '../../../assets/images/circus/2017/photos/coliseu_porto_ageas_circo_2017_14.jpg';
import photo15 from '../../../assets/images/circus/2017/photos/coliseu_porto_ageas_circo_2017_15.jpg';
import photo16 from '../../../assets/images/circus/2017/photos/coliseu_porto_ageas_circo_2017_16.jpg';
import photo17 from '../../../assets/images/circus/2017/photos/coliseu_porto_ageas_circo_2017_17.jpg';
import CircusPhotoGallery from '../CircusPhotoGallery/CircusPhotoGallery';
import CircusRow from '../CircusRow/CircusRow';
import artists from './Artists.json';
import styles from './Circus2017View.module.scss';
import PageTransition from '../../PageTransition/PageTransition';

export default function Circus2017View() {

    const photos = [
        photo1,
        photo2,
        photo3,
        photo4,
        photo5,
        photo6,
        photo7,
        photo8,
        photo9,
        photo10,
        photo11,
        photo12,
        photo13,
        photo14,
        photo15,
        photo16,
        photo17
    ];

    return (
        <PageTransition>
            <div className={styles.Circus2017}>
                <div className={styles.Hero}>
                </div>

                <Grid container direction="column" justify="center" alignItems="center" className={styles.Intro}>

                    <h1>Vamos ao Circo!</h1>
                    <h3>O Coliseu Porto apresenta o seu Circo de Natal, uma verdadeira festa da felicidade, este ano numa edição especial de aniversário!</h3>
                    <p>75 anos depois, o Circo Coliseu Porto é um circo sem idade! A tradição mantém-se, mas a cada ano é apresentado na pista desta mítica sala de espetáculos da cidade um circo rejuvenescido e cada vez mais completo, com novos artistas que exibem talentos excecionais. Um espetáculo capaz de reinterpretar os clássicos da tradição circense aliando-os às disciplinas mais contemporâneas, resultando em números de extraordinária beleza. E sempre sem animais em pista!</p>

                </Grid>

                {artists.map(artist => <CircusRow key={Math.random()} {...artist} />)}

                <CircusPhotoGallery photos={photos} year={2017} />
            </div>
        </PageTransition>
    )
}
