import React from 'react';
import BuyTickets from '../BuyTickets/BuyTickets';
import EventUnavailable from '../EventUnavailable/EventUnavailable';

type EventOptionsProps = {
    event: any
    show: any
}

export default function EventOptions(props: EventOptionsProps) {

    const { event, show } = props

    if (typeof show === "boolean") {
        if (!show) return null
    } else {
        const showState = show[event.state]
        if (!showState) return null
    }

    if (event.state === 'ACTIVE') {
        return <BuyTickets event={event} />
    }

    return <EventUnavailable event={event} />
}
