import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import React from 'react';
import TicketIcon from '../TicketIcon/TicketIcon';
import styles from './BuyTickets.module.scss';

const TicketIconDisplay = (props: any) => (
    <TicketIcon className={classNames(styles.TicketIcon, { [styles.BigFormat]: props.big })} />
);

const BlueTicket = (props: any) => {
    if (props.button) {
        return (
            <a href={props.event.ticketsUrl} target="_blank" rel="noopener noreferrer" className={styles.BuyButton}>
                <TicketIcon className={styles.IconAdjust} />
                Comprar
            </a>
        );
    }

    return (
        <IconButton href={props.event.ticketsUrl} target="_blank" rel="noopener noreferrer" style={{ padding: 0 }}>
            <TicketIconDisplay {...props} />
        </IconButton>
    );
};

const TicketLineLink = (props: any) => {
    const url = 'https://ticketline.sapo.pt/evento/' + props.event.ticketsUrl
    if (props.button) {
        return (
            <a href={url} target="_blank" rel="noopener noreferrer" className={styles.BuyButton}>
                <TicketIcon className={styles.IconAdjust} />
                Comprar
            </a>
        );
    }

    return (
        <IconButton href={url} target="_blank" rel="noopener noreferrer" style={{ padding: 0 }}>
            <TicketIconDisplay {...props} />
        </IconButton>
    );
};

const BuyTickets = (props: any) => {
    if (props.hide || !props.event.ticketsSeller) return null;
    return (props.event.ticketsSeller === 'BLUETICKET') ? <BlueTicket {...props} /> : <TicketLineLink {...props} />;
};


export default BuyTickets;
