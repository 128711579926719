import * as Yup from "yup";
import { newslettersList } from "../SelectNewsletters/SelectNewsletters";

export default function unsubscribeSchema() {

    const newsletterIds = newslettersList.map(newsletter => +newsletter.list)

    const newslettersSchema = newsletterIds.reduce((schema: any, id: any) => {
        schema[id] = Yup.boolean().default(false)
        return schema
    }, {})

    return Yup.object().shape({
        email: Yup.string().email("Por favor introduza email válido.").required("O campo email é obrigatório."),
        newsletters: Yup.object().shape(newslettersSchema)
            .test(
                'at-least-one',
                'Você deve selecionar pelo menos uma newsletter.',
                values => Object.values(values).some(Boolean)
            ),


    })
}
