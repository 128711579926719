import React from 'react';
import DOMPurify from 'dompurify';

/** sanitizes html string and strips unwanted tags and attributes */
const BindHTML = ({ children, className = "" }: { children: any, className?: any }) => {
    const ALLOWED_TAGS = ['b', 'p', 'a', 'br', 'i', 'u', 'li', 'ul']
    const FORBID_ATTR = ['style']
    const ALLOWED_ATTR = ['target', 'href']
    const purifiedText = DOMPurify.sanitize(children, { ALLOWED_TAGS, FORBID_ATTR, ALLOWED_ATTR });
    return <div className={className} dangerouslySetInnerHTML={{ __html: purifiedText }} />
}


export default BindHTML;
