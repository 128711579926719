import { Grid } from '@material-ui/core'
import React from 'react'
import Hide from '../Hide/Hide'
import HomeAgenda from '../HomeAgenda/HomeAgenda'
import HomeEducationalService from '../HomeEducationalService/HomeEducationalService'
import HomeFeatured from '../HomeFeatured/HomeFeatured'
import HomeHighlights from '../HomeHighlights/HomeHighlights'
import HomeNews from '../HomeNews/HomeNews'
import HomeNewsletter from '../HomeNewsletter/HomeNewsletter'
import styles from './HomeView.module.scss'

export default function HomeView() {

    const hideFeatured = true

    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid container component="main">
                <HomeHighlights />
                <HomeAgenda />
                <Hide on={hideFeatured}>
                    <HomeFeatured />
                </Hide>
                <HomeEducationalService />
                <HomeNews />
                <HomeNewsletter />
            </Grid>
        </Grid>
    )
}
