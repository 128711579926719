import { Grid } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useGtXs } from '../../hooks/useGtXs';
import useSpace from '../../hooks/useSpace';
import ActionButton from '../ActionButton/ActionButton';
import Hero from '../Hero/Hero';
import MoreSpaces from '../MoreSpaces/MoreSpaces';
import Paragraph from '../Paragraph/Paragraph';
import PhotoGallery from '../PhotoGallery/PhotoGallery';
import Show from '../Show/Show';
import Typography from '../Typography/Typography';
import styles from './SpaceView.module.scss';

const SpaceName = (props: any) => {
    const { name, image } = props

    return (
        <div className={styles.SpaceName}>
            <img src={image} alt={name} />
        </div>
    )
}

const Content = (props: any) => {
    const { description, observations } = props

    const gtXs = useGtXs()

    const variant = gtXs ? "RocGroteskMedium25" : "RocGroteskMedium20"
    const observationsVariant = gtXs ? "RocGroteskRegular25" : "RocGroteskRegular20"

    return (
        <div className={styles.Description}>
            <Paragraph variant={variant} style={{ marginBottom: 20, lineHeight: 1.2 }}>
                {description}
            </Paragraph>
            <Show on={!!observations}>
                <Paragraph variant={observationsVariant} style={{ marginBottom: 20, lineHeight: 1.2 }}>
                    {observations}
                </Paragraph>
            </Show>
        </div>
    )
}


const Suggestions = (props: any) => {
    const { suggestions } = props
    const variant = "RocGroteskBold30"
    return (
        <div className={styles.Suggestions}>
            {suggestions?.map((suggestion: string, index: number) => (
                <Typography key={index} variant={variant} component="h3">
                    {suggestion}
                </Typography>
            ))}
        </div>
    )
}

const Actions = (props: any) => {
    const { actions } = props
    return (
        <div className={styles.Actions}>
            {actions?.map((action: any) => (
                <p className={styles.ButtonWrapper} style={{ paddingLeft: 0 }}>
                    <ActionButton {...action} fullWidth />
                </p>
            ))
            }
        </div >
    )
}

interface SpaceViewProps extends RouteComponentProps<{}> { }

export default function SpaceView(props: SpaceViewProps) {

    const { match } = props
    const space = useSpace(match.path)
    const gtXs = useGtXs()

    return (
        <div className={styles.Wrapper}>
            <Hero images={space.hero} />
            <Grid container justify="center">
                <main>
                    {gtXs ?
                        <Grid container spacing={10}>
                            <Grid item xs={5}>
                                <SpaceName image={space.nameImage} name={space.name} />
                                <Suggestions suggestions={space.suggestions} />
                                <Actions actions={space.actions} />
                            </Grid>

                            <Grid item xs={7}>
                                <div className={styles.TopInfographic}>
                                    <img src={space.topInfographic} alt="" />
                                </div>
                                <PhotoGallery photos={space.photos} />
                                <div className={styles.BottomInfographic}>
                                    <img src={space.bottomInfographic} alt="" />
                                </div>
                                <Content description={space.description} observations={space.observations} />
                            </Grid>
                        </Grid>

                        :

                        <Grid container justify="space-between" className={styles.Presentation}>
                            <Grid item xs={12}>
                                <SpaceName image={space.nameImage} name={space.name} />
                                <PhotoGallery photos={space.photos} />
                                <Suggestions suggestions={space.suggestions} />
                                <Content description={space.description} observations={space.observations} />
                                <Actions actions={space.actions} />
                            </Grid>
                        </Grid>
                    }


                    <Show on={gtXs}>
                        <Grid container justify="center" className={styles.BackButton}>
                            <ActionButton name="Voltar Espaços Coliseu" url="/espacos" />
                        </Grid>
                    </Show>

                    <div className={styles.MoreSpacesWrapper}>
                        <MoreSpaces id={space.id} itemsPerRow={gtXs ? 4 : 2} />
                    </div>

                    <Show on={!gtXs} >
                        <div style={{ marginTop: 30, marginBottom: 30 }}>
                            <ActionButton name="Voltar Espaços Coliseu" url="/espacos" fullWidth />
                        </div>
                    </Show>

                </main>
            </Grid>
        </div>
    )
}
