import React, { Fragment, FC, ReactNode } from "react";

interface HideProps {
    on: boolean
    children: ReactNode
}

const Hide: FC<HideProps> = props => {
    const { on, children } = props
    if (on) return null
    return (
        <Fragment>
            {children}
        </Fragment>
    )
}

export default Hide
