import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

const FormatTime = ({ datetime }: { datetime: any }) => {
    return (
        <Moment format="HH[h]mm" tz="Europe/Lisbon">
            {datetime}
        </Moment>
    );
};

export default FormatTime;
