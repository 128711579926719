import { Field } from 'formik';
import { Checkbox } from 'formik-material-ui';
import React from 'react';

export default function CheckboxField(props: any) {
    const { name } = props

    return (
        <Field name={name} component={Checkbox} type="checkbox" />
    )
}
