import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useGtXs } from '../../hooks/useGtXs';
import FormatDate from '../FormatDate/FormatDate';
import Typography from '../Typography/Typography';
import styles from './EventSeparator.module.scss';
import separatorImage from './Helpers/separatorImage';

interface EventSeparatorProps {
    year: number
    month: number
}

export default function EventSeparator(props: EventSeparatorProps) {
    const { year, month } = props
    const gtXs = useGtXs()

    const image = separatorImage(month + 1, gtXs)
    return (
        <Grid container direction="column" className={styles.EventSeparator} style={{ backgroundImage: `url(${image})` }}>
            <Typography variant="menu">
                <FormatDate date={new Date(year, month, 1)} format="MMMM" monthCaps />
            </Typography>
            <Typography variant="menu">
                {year}
            </Typography>
        </Grid>
    )
}
