import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const DownloadIcon = (props: any) => {
    const d = "M12 0.48c6.361 0 11.52 5.158 11.52 11.52 0 6.364-5.159 11.521-11.52 11.521-6.364 0-11.521-5.158-11.521-11.521 0-6.362 5.158-11.52 11.521-11.52zM14.4 7.2h-4.8v4.8h-3l5.4 5.4 5.4-5.4h-3v-4.8z"
    return (
        <SvgIcon {...props}>
            <path d={d} />
        </SvgIcon>
    );
};

export default DownloadIcon;
