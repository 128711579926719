import spaces from "../data/spaces"

export default function useSpace(slug: string) {
    const records = spaces.filter(space => space.slug === slug)

    if (records.length !== 1) {
        throw new Error("Wrong query")
    }

    return records[0]
}
