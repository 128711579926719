import React from 'react';
import { useGtXs } from '../../hooks/useGtXs';
import styles from './Hero.module.scss';

interface HeroProps {
    images: any
}

export default function Hero(props: HeroProps) {
    const { images } = props
    const gtXs = useGtXs()
    const imageUrl = gtXs ? images.gtXs : images?.xs
    const style = { backgroundImage: `url(${imageUrl})` }
    return (
        <div className={styles.Hero} style={style} />
    )
}
