import { Grid } from '@material-ui/core';
import React from 'react';
import mobile_logo from '../../assets/images/educational/informations/educational_service_logo_mobile.webp';
import GoBackToSchool from '../GoBackToSchool/GoBackToSchool';
import RelatedEducationalActivities from '../RelatedEducationalActivities/RelatedEducationalActivities';
import { activities } from '../SchoolsView/Activities';
import styles from './EducationalActivityThanksView.module.scss';


export default function EducationalActivityThanksView(props: any) {

    const slug = props.match.params.slug
    const activity = activities.find(activity => activity.slug === slug)

    if (!activity) {
        return <div>Atividade Não Encontrada</div>
    }

    return (
        <div>
            <GoBackToSchool />
            <Grid container justify="center" className={styles.Wrapper}>
                <main>

                    <Grid container justify="center" className={styles.Wrapper}>

                        <div className={styles.Logo}>
                            <img src={mobile_logo} alt="logo do serviço educativo coliseu" />
                        </div>

                        <div className={styles.Thanks}>
                            <p>Obrigado por submeter</p>
                        </div>

                        <div className={styles.Name}>
                            <p>{activity.name}</p>
                        </div>

                        <div className={styles.Message}>
                            <p>Iremos entrar em contacto consigo o mais cedo possível, em caso de dúvidas ou para mais informações contacte a nosa equipa:</p>
                        </div>

                        <div className={styles.Team}>
                            <p><b>FILIPA GODINHO</b></p>
                            <p>Direção e Coordenação Pedagógica</p>
                            <br />
                            <p><b>TIAGO AZEVEDO</b></p>
                            <p>Gestão e Produção</p>
                            <br />
                            <p>
                                <b>
                                    <a href="mailto:educativo@coliseu.pt?subject=Pedido%20Informação%20Serviço%20Educativo">
                                        educativo@coliseu.pt
					</a>
                                </b>
                            </p>
                            <p>
                                <b>
                                    <a href="tel:223395092">
                                        223 395 092
					</a>
                                </b>
                            </p>
                        </div>

                        <div className={styles.CallToAction}>
                            <p>DESEJA FAZER OUTRA SUBMISSÃO?</p>
                        </div>
                    </Grid>
                    <RelatedEducationalActivities activity={activity} />

                </main>
            </Grid>
        </div>
    )
}
