import { Grid } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import clsx from 'clsx'
import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import useAgendaEvents from '../../hooks/useAgendaEvents'
import useCarousel from '../../hooks/useCarousel'
import { useGtXs } from '../../hooks/useGtXs'
import HomeEventPreview from '../HomeEventPreview/HomeEventPreview'
import Loading from '../Loading/Loading'
import OutlineButtonHome from '../OutlineButtonHome/OutlineButtonHome'
import styles from './HomeAgenda.module.scss'

export default function HomeAgenda() {

    const { data, loading } = useAgendaEvents({ limit: 9, variant: "homeAgenda" })
    const gtXs = useGtXs()
    const startSlide = gtXs ? 1 : 0
    const endSlide = gtXs ? 7 : 8
    const { next, previous, currentSlide, isStart, isEnd, carouselProps } = useCarousel(startSlide, endSlide)

    if (loading) {
        return <Loading />
    }

    const events = data?.events?.nodes

    return (
        <Grid container>
            <Grid container justify="center" className={styles.Wrapper}>
                <Grid container component="section">
                    <Grid container direction="column" justify="center" alignContent="center" className={styles.Arrow} onClick={previous}>
                        <ArrowBackIosIcon className={clsx({ [styles.Disabled]: isStart })} />
                    </Grid>
                    <Carousel
                        {...carouselProps}
                        className={styles.Carousel}
                        selectedItem={currentSlide}
                        onChange={() => { }}
                    >
                        {events?.map(event => <HomeEventPreview key={event.id} event={event} />)}
                    </Carousel>
                    <Grid container direction="column" justify="center" alignContent="center" className={styles.Arrow} onClick={next}>
                        <ArrowForwardIosIcon className={clsx({ [styles.Disabled]: isEnd })} />
                    </Grid>

                    <Grid container justify="flex-end" className={styles.SeeAgendaWrapper}>
                        <Grid container className={styles.SeeAgenda}>
                            <OutlineButtonHome route="/agenda" name="ver agenda" />
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    )
}
