import { Grid } from '@material-ui/core'
import React from 'react'
import desktop from '../../assets/images/home/newsletter/newsletter_desktop.webp'
import mobile from '../../assets/images/home/newsletter/newsletter_mobile.webp'
import { useGtXs } from '../../hooks/useGtXs'
import { Link } from '../Link/Link'
import styles from './HomeNewsletter.module.scss'

export default function HomeNewsletter() {

    const gtXs = useGtXs()
    const source = gtXs ? desktop : mobile

    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid container justify="center" component="section">
                <Link route="/newsletter/subscrever">
                    <img src={source} alt="subscreva as nossas newsletters" />
                </Link>
            </Grid>
        </Grid>
    )
}
