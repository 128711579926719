import { Grid } from '@material-ui/core';
import React from 'react';
import Typography from '../Typography/Typography';
import styles from './StatutesView.module.scss';

export default function StatutesView() {
    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid item xs={12} sm={4} className={styles.Image} />
            <Grid item xs={12} sm={8} className={styles.Text}>
                <main>
                    <Typography variant="h1colisseum">
                        Estatutos da Associação Amigos do Coliseu do Porto
		    </Typography>

                    <section>
                        <p>
                            <b>Capítulo I — Nome, fim, duração e associados</b>
                        </p>
                        <p>
                            <b>Artigo 1º</b><br />
			    A Associação “Amigos do Coliseu do Porto” é uma Associação privada sem fins lucrativos, com sede no edifício do Coliseu do Porto, na Rua de Passos Manuel, cento e trinta e sete, na união de freguesias de Cedofeita, Sto. Ildefonso, Sé, Miragaia, S. Nicolau e Vitória, desta cidade.
			</p>

                        <p>
                            <b>Artigo 2º</b><br />
			    1. A Associação tem como finalidade:<br />
			    a) Assegurar o funcionamento e exploração do Coliseu do Porto como equipamento de grande relevância para a vida cultural, social e corporativa da cidade e Área Metropolitana do Porto, nomeadamente através da sua aquisição e gestão, directa ou indirecta;<br />
			    b) Assegurar o funcionamento e exploração de outros equipamentos similares, para realização de actividades de âmbito cultural, desportivo e outros tipos de eventos corporativos, podendo para esse efeito subscrever participações sociais em sociedades, independentemente da sua forma jurídica, cujo objecto social seja a gestão desses equipamentos;<br />
			    c) Exploração de serviços conexos com as actividades acima referidas, nomeada mas não taxativamente, bilhética, montagem e produção de espectáculos, podendo inclusive para esse efeito subscrever participações sociais em sociedades, independentemente da sua forma jurídica, cujo objecto social seja a exploração deste tipo de serviços.<br />
			    2. A Associação está aberta à participação dos cidadãos e instituições, públicas e privadas, que comunguem dos seus objectivos.<br />
                        </p>

                        <p>
                            <b>Artigo 3º</b><br />
			    A Associação durará por tempo indeterminado.
			</p>

                        <p>
                            <b>Artigo 4º</b><br />
			    1. São associados fundadores as pessoas individuais e colectivas que tenham concorrido, até 15 de Dezembro de 1995, para o património social, destinado à aquisição do complexo do Coliseu do Porto, com as contribuições mínimas fixadas em anexo.<br />
			    2. São também associados fundadores o Estado, que será representado nos órgãos da Associação pelo membro do Governo responsável pela área da cultura, ou por quem este designar, o Município do Porto e a Área Metropolitana do Porto.<br />
			    3. São associados Individuais e Colectivos as pessoas singulares e as instituições, respetivamente, cuja entrada para a Associação haja sido ou venha a ser requerida depois do dia 15 de Dezembro de 1995, nos termos propostos em anexo.<br />
			    4. São associados Prestígio, Individuais ou Colectivos, todos os associados, fundadores ou não, que contribuam para o património social de acordo com a tabela aprovada na Assembleia Geral respectiva.<br />
			    5. São associados Honorários aqueles que, por relevantes serviços prestados à causa do Coliseu, sejam como tal aprovados por uma maioria de dois terços em Assembleia Geral, por proposta de um mínimo de vinte associados.<br />
			    6. Os associados devem cumprir as demais obrigações constantes destes estatutos.<br />
                        </p>

                        <p>
                            <b>Artigo 5º</b><br />
			    1. Constituem direitos dos associados:<br />
			    a) Participar nas assembleias gerais da Associação;<br />
			    b) Designar e ser designado para os órgãos sociais da Associação;<br />
			    c) Receber o cartão de “Amigo do Coliseu” nos termos do regulamento e usufruir dos benefícios a ele inerentes.<br />
			    2. Constituem deveres dos associados:<br />
			    a) Pagar a quota mensal fixada pela Assembleia Geral;<br />
			    b) Colaborar nas iniciativas que concorram para o prestígio e desenvolvimento da Associação;<br />
			    c) Exercer os cargos para que sejam eleitos ou aceitar ser nomeados para os órgãos competentes;<br />
			    d) Cumprir as determinações emanadas dos órgãos da Associação que lhes digam respeito.<br />
                        </p>

                        <p>
                            <b>Artigo 6º</b><br />
			    Perderão a qualidade de associados:<br />
			    a) Os que a ela renunciarem, por comunicação escrita dirigida à Direcção;:<br />
			    b) Os que forem excluídos, mediante deliberação da Assembleia Geral, por violação ou desrespeito dos fins da Associação e/ou dos deveres a que estão obrigados legal ou estatutariamente, depois de terem sido ouvidos sobre os factos que lhes forem imputados.:<br />
			    c) Os que estiverem em mora há mais de seis meses relativamente ao pagamento das quotas devidas à Associação e que, após notificados por carta registada com aviso de recepção para pagarem o montante devido, acrescido de uma indemnização moratória correspondente a cinco por cento da soma em dívida, o não façam no prazo de sessenta dias a contar da recepção dessa comunicação.:<br />
                        </p>

                        <p>
                            <b>Capítulo II — Património</b>
                        </p>

                        <p>
                            <b>Artigo 7º</b><br />
			    O património da Associação é constituído pelo edifício, imobilizado e restantes contribuições que constituam capital.
			</p>

                        <p>
                            <b>Artigo 8º</b><br />
			    Constituem receitas da Associação:<br />
			    a) As contrapartidas que receber pela cedência a terceiros dos diversos espaços que compõem o Coliseu do Porto, bem como os proveitos que aufira pela exploração directa de tais espaços que a própria Associação venha a fazer;:<br />
			    b) Os proveitos emergentes das actividades previstas nas alíneas b) e c) do artigo 2.º dos estatutos;:<br />
			    c) Os subsídios, donativos ou contribuições de qualquer natureza que venha a receber de entidades, tanto públicas, como privadas;:<br />
			    d) Os rendimentos das aplicações financeiras do seu património;:<br />
			    e) O valor das quotas dos associados, nos termos fixados pela Assembleia Geral.:<br />
                        </p>

                        <p>
                            <b>Capítulo III — Órgãos da Associação</b>
                        </p>

                        <p>
                            <b>Artigo 9º</b><br />
			    São órgãos da Associação a Assembleia Geral, a Direcção e o Conselho Fiscal
			</p>

                        <p>Secção I — Assembleia Geral</p>

                        <p>
                            <b>Artigo 10º</b><br />
			    1. A Assembleia Geral da Associação é composta por todos os associados.<br />
			    2. Cada associado dispõe de um número de votos proporcional à contribuição entregue (jóia), sendo cada voto correspondente à verba de vinte e quatro euros e noventa e quatro cêntimos.<br />
                        </p>

                        <p>
                            <b>Artigo 11º</b><br />
			    Compete à Assembleia Geral deliberar sobre:<br />
			    a) A aprovação do relatório de gestão, balanço e contas da Direcção;<br />
			    b) A designação dos membros que compõem a Mesa da Assembleia Geral, bem como de um vogal efectivo e do vogal suplente do Conselho Fiscal;<br />
			    c) A destituição dos membros eleitos dos órgãos da Associação;<br />
			    d) A autorização para demandar os membros da Direcção por factos praticados no exercício do cargo;<br />
			    e) A alteração dos estatutos;<br />
			    f) A atribuição do estatuto de associado honorário;<br />
			    g) A fixação do valor da quota respeitante a cada uma das categorias de associados;<br />
			    h) A fixação do estatuto remuneratório dos membros dos órgãos sociais;<br />
			    i) A extinção da Associação.<br />
                        </p>

                        <p>
                            <b>Artigo 12º</b><br />
			    A Mesa da Assembleia Geral é composta por um Presidente, um Vice-Presidente e um Secretário, eleitos trienalmente pela Assembleia Geral.<br />
                        </p>

                        <p>
                            <b>Artigo 13º</b><br />
			    1. A Assembleia Geral dever reunir sempre que convocada pelo presidente da mesa a requerimento da Direcção ou de um conjunto de associados em número não inferior à quarta parte da sua totalidade.<br />
			    2. A Assembleia Geral reunirá obrigatoriamente, até ao dia 30 de Abril de cada ano, a fim de deliberar sobre o relatório de gestão, apresentado pela Direcção e as contas do exercício, respeitantes ao ano precedente.<br />
			    3. A Assembleia Geral será convocada mediante aviso publicado em sítio da internet de acesso público, regulado por portaria do Ministério da Justiça, com pelo menos 8 dias de antecedência, do qual deverá constar a data, hora e local da reunião, bem como a respectiva ordem do dia e ainda a indicação da hora em que a Assembleia Geral, na falta de quórum, funcionará em segunda convocação.<br />
			    4. O aviso convocatório será ainda publicado, com a mesma antecedência, na página inicial do sítio electrónico do Coliseu do Porto, bem como num dos 3 jornais com maior tiragem na área metropolitana do Porto.<br />
			    5. O aviso convocatório será remetido por correio electrónico aos associados que autorizem a Associação a utilizar este meio como forma privilegiada de comunicação entre si.<br />
                        </p>

                        <p>
                            <b>Artigo 14º</b><br />
			    1. A Assembleia Geral não poderá funcionar, em primeira convocação, se não estiverem presentes pelo menos metade dos associados.<br />
			    2. A Assembleia Geral deliberará por maioria absoluta de votos dos associados presentes.<br />
			    3. As deliberações sobre a alteração dos estatutos da Associação requerem setenta e cinco por cento de votos favoráveis dos associados presentes, incluindo o voto favorável dos associados referidos no número 2 do artigo 4º.<br />
			    4. As deliberações sobre a extinção da Associação requerem setenta e cinco por cento de votos favoráveis do universo de todos os associados, incluindo o voto favorável dos associados referidos no número 2 do artigo 4º.<br />
			    5. De todas as reuniões da Assembleia Geral será lavrada acta, registada em livro próprio.<br />
                        </p>

                        <p>Secção II — Direcção</p>

                        <p>
                            <b>Artigo 15º</b><br />
			    1. A Associação é gerida e representada por uma Direcção composta por cinco membros, um dos quais será o Presidente, designados trienalmente nos termos dos artigos seguintes.<br />
			    2. Aos associados individuais caberá designar um Director por eleição entre eles, em assembleia especial, composta exclusivamente pelos associados desta categoria, a convocar directamente pelo Presidente da Assembleia Geral..<br />
			    3. Aos associados pessoas colectivas caberá designar um Director por eleição entre eles, em assembleia especial, composta exclusivamente pelos associados desta categoria, a convocar directamente pelo Presidente da Assembleia Geral..<br />
			    4. O Estado, o Município do Porto e a Área Metropolitana do Porto serão sempre membros da Direcção, indicando os seus representantes..<br />
			    5. A Direcção, uma vez constituída, deverá proceder, na primeira reunião, à eleição do seu Presidente..<br />
                        </p>

                        <p>
                            <b>Artigo 16º</b><br />
			    1. A Direcção reunirá pelo menos uma vez por mês..<br />
			    2. As reuniões são convocadas pelo Presidente por correio electrónico enviado aos demais Directores, com a antecedência mínima de três dias, para o endereço que cada um dos Directores indique para o efeito..<br />
			    3. A Direcção só poderá deliberar estando presente a maioria dos Directores e as suas deliberações são tomadas por maioria de votos dos Directores presentes, tendo o Presidente, além do seu voto, direito a voto de desempate..<br />
			    4. Em derrogação do disposto no número anterior, todas as deliberações que tenham por objecto a cedência a outra entidade do direito de utilização do Coliseu, por períodos que excedam o período de um mês, deverão ser tomadas por maioria dos directores em exercício de funções..<br />
			    5. Os Directores presentes nas reuniões da direcção não poderão abster-se de votar..<br />
                        </p>

                        <p>
                            <b>Artigo 17º</b><br />
			    1. A Direcção poderá delegar no Presidente ou num Director, que receberá o título de Director-Delegado, a prática dos actos de gestão corrente que definir..<br />
			    2. A delegação de poderes prevista no número anterior poderá ser revogada a todo o tempo..<br />
                        </p>

                        <p>
                            <b>Artigo 18º</b><br />
			    1. A Associação vincula-se pela intervenção:.<br />
			    a) De quaisquer dois Directores, sendo um deles o Presidente da Direcção;.<br />
			    b) Do Presidente da Direcção ou de um Director-Delegado, se se tratar de matéria compreendida no âmbito da delegação de poderes que nele haja sido feita;.<br />
			    c) De um qualquer Director e de um procurador, conjuntamente, agindo este último dentro dos limites do respectivo mandato;.<br />
			    d) De um mandatário constituído para a prática de acto certo e determinado..<br />
			    2. Da delegação de poderes estão excluídas a contratação de pessoal, a contracção de empréstimos e a oneração do património..<br />
                        </p>

                        <p>Secção III — Conselho Fiscal</p>

                        <p>
                            <b>Artigo 19º</b><br />
			    A actividade da Associação será fiscalizada por um Conselho Fiscal, composto por três membros efectivos e um suplente, designados trienalmente pela forma seguinte:<br />
			    a) O Presidente será designado pelo Estado;<br />
			    b) Um dos vogais efectivos será designado alternadamente pela Área Metropolitana do Porto e pelo Município do Porto;<br />
			    c) O outro vogal efectivo, que será obrigatoriamente um revisor oficial de contas, e o vogal suplente, serão eleitos pela Assembleia Geral, por proposta da Direcção.<br />
                        </p>

                        <p>
                            <b>Artigo 20º</b><br />
			    1. O Conselho Fiscal reunirá uma vez por trimestre e sempre que for convocado pelo seu Presidente.<br />
			    2. As deliberações do Conselho Fiscal serão tomadas por maioria de votos dos presentes, tendo o Presidente, além do seu voto, direito a voto de desempate.<br />
			    3. Os membros do Conselho Fiscal deverão proceder, em conjunto ou separadamente e em qualquer ocasião, aos actos de inspecção e verificação que tiverem por convenientes para o bom desempenho das suas funções.<br />
			    4. Até 31 de Março de cada ano, o Conselho Fiscal emitirá parecer sobre o relatório de gestão, balanço e contas da Direcção, o qual deverá ser presente à Assembleia Geral conjuntamente com esses documentos de prestação de contas.<br />
                        </p>

                        <p>
                            <b>Capítulo IV - Disposições finais</b>
                        </p>

                        <p>
                            <b>Artigo 21º</b><br />
			    Sem prejuízo do disposto no número um do artigo 166º do Código Civil o património da Associação terá o destino que lhe for fixado pela Assembleia Geral, com setenta e cinco por cento de votos favoráveis do universo de todos os associados, incluindo o voto favorável dos associados referidos no nº 2 do artigo 4º.
			</p>

                        <p>
                            <b>Artigo 22º</b><br />
			    Sempre que por eleição, designação ou disposição estatutária couber a uma pessoa colectiva o exercício de qualquer cargo nos órgãos da Associação, deverá ela designar, para o exercer, pessoa física que a todo o tempo poderá substituir e por cujos actos será solidariamente responsável.
			</p>

                        <p>
                            <b>Artigo 23º</b><br />
			    Os casos omissos serão resolvidos pela Direcção, de acordo com a legislação em vigor.
			</p>

                    </section>
                </main>
            </Grid>
        </Grid>
    )
}
