import React from 'react';
import OutlineButton from '../OutlineButton/OutlineButton';
import styles from './GoBackToSchool.module.scss';

export default function GoBackToSchool() {
    return (
        <div className={styles.GoBackToSchool}>
            <div className={styles.Content}>
                <OutlineButton
                    name="Programa Escolas"
                    route="/educativo/programa-escolas"
                    fullWidth={false}
                    showLeftArrow={true}
                />
            </div>
        </div>
    )
}
