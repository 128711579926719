import { clsx } from 'clsx'
import React from 'react'
import { Link } from '../Link/Link'
import Show from '../Show/Show'
import Typography, { ExtendedTypographyVariant } from '../Typography/Typography'
import styles from './OutlineButton.module.scss'

interface OutlineButtonProps {
    route?: string
    name: string
    showLeftArrow?: boolean
    showRightArrow?: boolean
    fullWidth?: boolean
    uppercased?: boolean
    variant?: ExtendedTypographyVariant
    onClick?: () => void
}

export default function OutlineButton(props: OutlineButtonProps) {
    const {
        route,
        name,
        showLeftArrow = false,
        showRightArrow = false,
        fullWidth = true,
        uppercased = true,
        variant = "RocGroteskBold18",
        onClick
    } = props


    const text = uppercased ? name.toLocaleUpperCase() : name

    if (route && onClick) {
        console.error("OutlineButton: Do not use 'route' and 'onClick' at the same time.")
        return null
    }

    const classes = clsx({
        [styles.Button]: true,
        [styles.FullWidth]: fullWidth
    })

    if (onClick) {
        return (
            <button className={classes} onClick={onClick}>
                <Typography variant={variant} component="p">
                    <Show on={showLeftArrow}>&lt;</Show> {text} <Show on={showRightArrow}>&gt;</Show>
                </Typography>
            </button>
        )
    } else if (route) {
        return (
            <Link route={route} className={classes}>
                <Typography variant={variant} component="p">
                    <Show on={showLeftArrow}>&lt;</Show> {text} <Show on={showRightArrow}>&gt;</Show>
                </Typography>
            </Link>
        )
    }

    return null
}
