import React from 'react'
import { EducationalHighlight } from '../EducationalHighlights/EducationalHighlights'
import { HomeEducationalAction } from '../HomeEducationalService/HomeEducationalService'
import ThreeColumnSection from '../ThreeColumnSection/ThreeColumnSection'

interface HomeEducationalServiceDesktopProps {
    content: EducationalHighlight[]
    action: HomeEducationalAction
}

export default function HomeEducationalServiceDesktop(props: HomeEducationalServiceDesktopProps) {
    const { content, action } = props
    return <ThreeColumnSection content={content} action={action} />
}
