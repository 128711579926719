import { Grid } from '@material-ui/core';
import React from 'react';
import EducationalHighlights from '../EducationalHighlights/EducationalHighlights';
import OutlineButton from '../OutlineButton/OutlineButton';
import Paragraph from '../Paragraph/Paragraph';
import styles from './EducationalSectionView.module.scss';

export default function EducationalSectionView() {
    return (
        <div>
            <div className={styles.SectionBanner} />

            <Grid container justify="center" className={styles.Wrapper}>
                <main>
                    <div className={styles.Introduction}>
                        <Paragraph variant="RocGroteskMedium20">
                            Pela primeira vez em 81 anos de história o Coliseu lança, em 2023, o seu Serviço Educativo, para reforçar a ligação de crianças, jovens e de todos os públicos às artes. Situado no coração da cidade do Porto desde 1941, o Coliseu orgulha-se de ser uma das salas de espetáculo mais democráticas do país, chamando a si uma multiplicidade de parceiros, instituições, escolas e associações educativas. Pretende agora criar, com todos, laços vincados e duradouros, partilhar o sentido da sua missão e proporcionar lugar e tempo para a educação e fruição artísticas.
                        </Paragraph>
                    </div>

                    <Grid container>
                        <EducationalHighlights />
                    </Grid>

                    <Grid container justify="center" className={styles.Newsletter}>
                        <OutlineButton
                            name="Subcrever Newsletter"
                            route="/newsletter/subscrever"
                            fullWidth={false}
                        />
                    </Grid>
                </main>
            </Grid>
        </div>
    )
}
