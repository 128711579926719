import { Grid, GridSize } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useGtXs } from '../../hooks/useGtXs';
import useSpaces from '../../hooks/useSpaces';
import Typography from '../Typography/Typography';
import styles from './MoreSpaces.module.scss';

interface MoreSpacesProps {
    id: number | number[]
    itemsPerRow?: 1 | 2 | 3 | 4
}

const getXsValue = (itemsPerRow: number): GridSize => {

    const xsMap: { [key: number]: number } = {
        1: 12,
        2: 6,
        3: 4,
        4: 3
    }

    const xs = xsMap[itemsPerRow] || 12
    return xs as GridSize
}

export default function MoreSpaces(props: MoreSpacesProps) {
    const { id, itemsPerRow = 4 } = props

    const { spaces } = useSpaces()
    const history = useHistory()
    const gtXs = useGtXs()

    const xs = getXsValue(itemsPerRow)
    const ids = Array.isArray(id) ? id : [id]
    const moreSpaces = spaces.filter(space => !ids.includes(space.id))

    const handleGoTo = (slug?: string) => {
        if (slug) {
            history.push(`${slug}`)
        }
    }

    const variant = gtXs ? "RocGroteskBold25" : "RocGroteskBold20"

    return (
        <Grid container justify="flex-start" className={styles.Wrapper} wrap="wrap" spacing={2}>
            {moreSpaces?.map(space => {
                return (
                    <Grid item xs={xs} key={space.id}>
                        <img src={space?.thumbnail} onClick={() => handleGoTo(space.slug)} alt={space.name} />
                        <Typography variant={variant}>
                            {space.name.toLocaleUpperCase()}
                        </Typography>
                    </Grid>
                )
            })}
        </Grid>
    )
}
