import React from 'react';
import formacao_professores_mobile from '../../assets/images/educational/schools/formacao_professores_mobile.webp';
import formacao_professores from '../../assets/images/educational/schools/formacao_professores_top_banner.webp';
import oficinas_mobile from '../../assets/images/educational/schools/oficinas_mobile.webp';
import oficinas from '../../assets/images/educational/schools/oficinas_top_banner.webp';
import visitas_orientadas_mobile from '../../assets/images/educational/schools/visitas_orientadas_mobile.webp';
import visitas_orientadas from '../../assets/images/educational/schools/visitas_orientadas_top_banner.webp';
import visita_jogo_mobile from '../../assets/images/educational/schools/visita_jogo_mobile.webp';
import visita_jogo from '../../assets/images/educational/schools/visita_jogo_top_banner.webp';
import { useGtXs } from '../../hooks/useGtXs';
import styles from './EducationalHero.module.scss';

interface EducationalHeroProps {
    category: string
}

const heroMap = {
    "Visita Jogo": { desktop: visita_jogo, mobile: visita_jogo_mobile },
    "Visitas Orientadas": { desktop: visitas_orientadas, mobile: visitas_orientadas_mobile },
    "Oficinas": { desktop: oficinas, mobile: oficinas_mobile },
    "Formação Professores": { desktop: formacao_professores, mobile: formacao_professores_mobile }
}

const getImage = (category: string, isGtXs: boolean) => {
    const breakpoint = isGtXs ? "desktop" : "mobile"
    // @ts-ignore
    return heroMap[category][breakpoint]
}
export default function EducationalHero(props: EducationalHeroProps) {
    const { category } = props
    const gtXs = useGtXs()
    const imageUrl = getImage(category, gtXs)
    const style = { backgroundImage: `url(${imageUrl})` }
    return (
        <div className={styles.EducationalHero} style={style} />
    )
}
