import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import React from 'react';
import { useGtXs } from '../../hooks/useGtXs';
import Typography from '../Typography/Typography';
import styles from './ArchiveYears.module.scss';

interface ArchiveYearsProps {
    years: number[]
    selected: number
    select: (year: any) => void
}

export default function ArchiveYears(props: ArchiveYearsProps) {
    const { years, selected, select } = props

    const gtXs = useGtXs()
    const justify = gtXs ? "center" : "flex-start"

    return (
        <Grid container justify={justify} alignItems="center" wrap="nowrap" className={styles.ArchiveYears}>
            {years.map(year => {
                const className = classNames({ [styles.YearButton]: true, [styles.Selected]: selected === year })
                return (
                    <Typography variant="menu" onClick={() => select(year)}>
                        <span className={className}>{year}</span>
                    </Typography>
                )
            })}
        </Grid>
    )
}
