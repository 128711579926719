import { Grid } from '@material-ui/core'
import React from 'react'
import { useGtXs } from '../../hooks/useGtXs'
import { mediaUrl, normalizeAgeRating } from '../../services/Utils'
import { abbrevDate } from '../../utils/dates'
import FormatDate from '../FormatDate/FormatDate'
import FormatTime from '../FormatTime/FormatTime'
import { Link } from '../Link/Link'
import Typography from '../Typography/Typography'
import styles from './HomeEventPreview.module.scss'

interface HomeEventPreviewProps {
    event: any
}

export default function HomeEventPreview(props: HomeEventPreviewProps) {
    const { event } = props

    const gtXs = useGtXs()
    const cover = mediaUrl(event?.homeAgendaImage || event?.poster || event?.cover)
    const date = abbrevDate(event?.startDate)
    const minimumAge = normalizeAgeRating(event?.minimumAge)
    const route = `/evento/${event.slug}`
    const dayFormat = gtXs ? "dddd" : "ddd"

    return (
        <Grid container direction="column" justify="space-between" className={styles.HomeEventPreview}>
            <Grid container justify="space-between" className={styles.First} alignItems="center">
                <Grid item xs={4} container justify="flex-start">
                    <Typography variant="subtitle2" gutterBottom={false}>
                        {date}
                    </Typography>
                </Grid>
                <Grid container item xs={8} justify="flex-end">
                    <Typography variant="body2" className={styles.Cased} gutterBottom={false}>
                        {event?.category?.name}
                    </Typography>
                    <Typography variant="body2" className={styles.Cased} gutterBottom={false}>
                        {minimumAge}
                    </Typography>
                </Grid>
            </Grid>
            <Grid className={styles.Image}>
                <Link route={route}>
                    <img src={cover} alt={event?.name} />
                </Link>
            </Grid>
            <Grid container>
                <Typography variant="menu" className={styles.Name}>
                    <Link name={event?.name} route={route} />
                </Typography>
            </Grid>
            <Grid container justify="space-between" className={styles.Last}>
                <Typography variant="body2" className={styles.DayOfWeek}>
                    <FormatDate date={event?.startDate} format={dayFormat} />
                </Typography>
                <span>//</span>
                <Typography variant="body2" className={styles.Time}>
                    <FormatTime datetime={event?.startDate} />
                </Typography>
                <span>//</span>
                <Typography variant="caption" className={styles.RoomName}>
                    {event?.room?.name}
                </Typography>
            </Grid>
        </Grid>
    )
}
