import * as Yup from "yup";
import { newslettersList } from "../SelectNewsletters/SelectNewsletters";

export default function subscribeSchema() {

    const newsletterIds = newslettersList.map(newsletter => +newsletter.list)

    const newslettersSchema = newsletterIds.reduce((schema: any, id: any) => {
        schema[id] = Yup.boolean().default(false)
        return schema
    }, {})

    return Yup.object().shape({
        name: Yup.string().required("O campo nome é obrigatório."),
        email: Yup.string().email("Por favor introduza email válido.").required("O campo email é obrigatório."),
        confirmation: Yup.boolean()
            .test(
                'is-true',
                'É necessário assinar a newsletter.',
                value => value === true
            )
            .default(false),
        newsletters: Yup.object().shape(newslettersSchema)
            .test(
                'at-least-one',
                'Você deve selecionar pelo menos uma newsletter.',
                values => Object.values(values).some(Boolean)
            ),
    })
}
