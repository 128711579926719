import { Grid } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import highlight1 from '../../assets/images/educational/main/highlight1.webp';
import highlight2 from '../../assets/images/educational/main/highlight2.webp';
import Paragraph from '../Paragraph/Paragraph';
import Typography from '../Typography/Typography';
import styles from './EducationalHighlights.module.scss';

export type EducationalHighlight = {
    id: number
    date: string
    name: string
    image: string
    route: string
}

export const educationalHighlights = [
    {
        id: 1,
        date: "Nov 2024",
        name: "ELO",
        image: highlight1,
        route: "https://www.coliseu.pt/noticia/elo-coliseu-clerigos-e-acp-querem-o-porto-multicultural-a-falar-a-lingua"
    },
    {
        id: 2,
        date: "Dez 2024",
        name: "Oficinas Natal",
        image: highlight2,
        route: "/noticia/oficinas-de-natal-do-servico-educativo-coliseu-desafiam-a-imaginacao"
    },
]


export default function EducationalHighlights() {

    const history = useHistory()


    const handleClick = (highlight: EducationalHighlight) => {
        const url = highlight.route
        if (url.startsWith("http")) {
            window.location.href = url
        } else {
            history.push(url)
        }
    }

    return (
        <Grid container className={styles.EducationalHighlights}>
            <Grid container justify="center">
                <Typography variant="RocGroteskBold25" className={styles.Name}>
                    Destaques
                </Typography>
            </Grid>
            <Grid item container justify="center" className={styles.Highlights}>
                {educationalHighlights.map(highlight => (
                    <div className={styles.SectionHighlight} onClick={() => handleClick(highlight)}>
                        <img src={highlight.image} alt={highlight.name} />
                        <Paragraph variant="RocGroteskBold20" className={styles.Link}>
                            {highlight.name}
                        </Paragraph>
                    </div>
                ))}
            </Grid>
        </Grid>
    )
}
