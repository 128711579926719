import eloDanceBanner from '../../assets/images/educational/elo/elo_dance_banner.jpg';
import eloTheaterBanner from '../../assets/images/educational/elo/elo_theater_banner.jpg';

export const eloActivities = [
    {
        cover: eloDanceBanner,
        name: "Aulas de Dança Inclusiva",
        teachers: "Eliana Campos",
        schedule: "2ª feiras, das 10h30 às 12h30 (2h00)<br/>Outubro 2023 - Junho 2024",
        location: "Coliseu Porto Ageas - Salão Jardim",
        audience: "4 vagas pessoas com deficiência (20€/mês)<br/>8 vagas adultos e séniores (40€/mês)",
        guests: "Participantes do Espaço T",
        openRegistrations: true
    },
    {
        cover: eloTheaterBanner,
        name: "Aulas de Teatro",
        teachers: "Pedro Lamares, Carolina Rocha",
        schedule: "2ª feiras, das 18h00 às 20h30 (2h30)<br/>Outubro 2023-Junho 2024",
        location: "Coliseu Porto Ageas",
        audience: "15 vagas para maiores de 15 anos (45€/mês)",
        guests: "Participantes da Casa do Vale e Instituto Profissional do Terço",
        openRegistrations: false
    }
]
