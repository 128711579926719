import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

// investigar isto:
// https://github.com/mui-org/material-ui/blob/master/packages/material-ui-icons/custom/Facebook.js
// https://github.com/mui-org/material-ui/blob/193de7b61573c318853465049e74f5ac9b714660/packages/material-ui-icons/src/utils/createSvgIcon.js#L4

const PlusIcon = (props: any) => {
    const d = "M17.428 11.468h-7.183v-7.183c0-0.567-0.459-1.026-1.026-1.026s-1.026 0.459-1.026 1.026v7.183h-7.183c-0.567 0-1.026 0.459-1.026 1.026s0.459 1.026 1.026 1.026h7.183v7.183c0 0.567 0.459 1.026 1.026 1.026s1.026-0.459 1.026-1.026v-7.183h7.183c0.567 0 1.026-0.459 1.026-1.026s-0.459-1.026-1.026-1.026z";
    return (
        <SvgIcon {...props}>
            <path d={d} />
        </SvgIcon>
    );
};


export default PlusIcon;
