import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Subscription } from 'apollo-client/util/Observable';
import { Field, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { Checkbox, TextField } from 'formik-material-ui';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useCreateManySubscriptionsMutation } from '../../generated/graphql';
import { formInit } from '../../utils/forms';
import NewsletterConfirmationPopup from '../NewsletterConfirmationPopup/NewsletterConfirmationPopup';
import SelectNewsletters, { newslettersList, selectedNewsletters } from '../SelectNewsletters/SelectNewsletters';
import subscribeSchema from './SubscribeSchema';
import styles from './SubscribeView.module.scss';

export default function SubscribeView() {

    const [subscribe] = useCreateManySubscriptionsMutation()

    const [errors, setErrors] = useState<string[]>([])
    const [messages, setMessages] = useState<string[]>([])
    const { schema, initial } = formInit<Subscription>(subscribeSchema)
    const [isOpen, setIsOpen] = useState<boolean>(false)


    const handleSubmit = async (values: FormikValues, helpers: FormikHelpers<Subscription>) => {
        setErrors([])
        setMessages([])

        const { resetForm } = helpers
        const { name, email } = values
        const lists = selectedNewsletters(values)

        const response = await subscribe({ variables: { input: { subscriptions: { name, email, lists } } } })

        // se todas subscrições correrem bem
        if (response?.data?.createManySubscriptions.every(subscription => subscription.success)) {
            setIsOpen(true)
            resetForm();
        } else {
            const messagesList: string[] = []
            const errorsList: string[] = []
            response?.data?.createManySubscriptions.forEach((subscription: any) => {
                const newsletter = newslettersList.find(n => n.list === subscription.list)
                if (subscription.success) {
                    messagesList.push(`Subscrição na newsletter ${newsletter?.name} efetuada com sucesso. Foi enviado um email de confirmação.`)
                } else {
                    errorsList.push(`Erro ao subscrever a newsletter ${newsletter?.name}.`)
                }
            })

            setMessages(messagesList)
            setErrors(errorsList)
        }
    }

    return (
        <Grid container justify="center" className={styles.Wrapper}>

            <main>

                <h1>Subscreva a Newsletter</h1>

                <p>Fique a conhecer os novos espetáculos, notícias e ofertas especiais.</p>

                <Formik initialValues={initial} validationSchema={schema} onSubmit={handleSubmit}>
                    {({ isValid, dirty, isSubmitting }) => (
                        <Form>
                            <Grid container justify="center" alignItems="center">

                                <Grid container direction="column">

                                    <Field
                                        component={TextField}
                                        name="name"
                                        type="text"
                                        label="nome"
                                        required
                                        autoFocus
                                        data-cy="name"
                                    />

                                    <Field
                                        component={TextField}
                                        name="email"
                                        type="text"
                                        label="email"
                                        data-cy="email"
                                        required
                                    />

                                    <SelectNewsletters />

                                    <p style={{ marginBlockEnd: 0 }}>
                                        <Field component={Checkbox} type="checkbox" name="confirmation" />
                                        <small>Autorizo o tratamento dos meus dados pessoais para envio de mensagens de divulgação de eventos, notícias e atividades</small></p>
                                    <p className={styles.ReadPolicy}>
                                        <Link to="../politica-de-privacidade" target="_blank">
                                            <u>Ler Politica Privacidade</u>
                                        </Link>
                                    </p>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={Boolean(isSubmitting || !isValid || !dirty)}
                                        data-cy="submit"
                                    >
                                        Submeter
                                    </Button>

                                    <div data-cy="errors" className={styles.ValidationError}>
                                        {errors.map(error => <p>{error}</p>)}
                                    </div>

                                    <div data-cy="errors" className={styles.Success}>
                                        {messages.map(message => <p>{message}</p>)}
                                    </div>

                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik >
                <NewsletterConfirmationPopup isOpen={isOpen} setIsOpen={setIsOpen} />
            </main>
        </Grid>
    )
}
