import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Link } from 'react-router-dom';
import { mediaUrl } from '../../services/Utils';
import PlusIcon from '../PlusIcon/PlusIcon';
import styles from './PostPreview.module.scss';

const PostPreview = ({ post }: { post: any }) => {
    const image = mediaUrl(post.cover);
    const url = `/noticia/${post.slug}`;
    return (
        <Grid container className={styles.PostPreview}>
            {/* <Link to={url} alt="Ler Mais" /> */}
            <Link to={url} />
            <Grid className={styles.PostPreviewImage} style={{ backgroundImage: `url(${image})` }} />
            <Grid container className={styles.PostPreviewDetails} alignItems="flex-start">
                <Grid className={styles.PostName} item xs={8}>{post.name}</Grid>
                <Grid item xs={2} className={styles.Action}>
                    <PlusIcon className={styles.PlusIcon} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PostPreview;
