import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Zoom from '@material-ui/core/Zoom';
import React from 'react';
import { universalMedia } from '../../services/Utils';
import styles from '../LightBox/LightBox.module.scss';

const LightBox = ({ isActive, selected, handleClose }: { isActive: any, selected: any, handleClose: any }) => {
    const maxWidth = 'lg';
    const url = universalMedia(selected)

    return (
        <Dialog
            fullWidth={false}
            maxWidth={maxWidth}
            onClose={handleClose}
            className={styles.LightBox}
            open={isActive}
            onBackdropClick={handleClose}
            TransitionComponent={Zoom}
            transitionDuration={{ enter: 600, exit: 300 }}
        >
            <DialogContent className={styles.LightBoxContent}>
                <Grid container direction="row" justify="center">
                    {selected && <img src={url} alt="" />}
                </Grid>
            </DialogContent>

        </Dialog>
    );
};

export default LightBox;
