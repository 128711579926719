import React from 'react';
import OutlineButton from "../OutlineButton/OutlineButton";
import { ExtendedTypographyVariant } from "../Typography/Typography";

interface OutlineButtonHomeProps {
    route?: string
    name: string
    showLeftArrow?: boolean
    showRightArrow?: boolean
    fullWidth?: boolean
    variant?: ExtendedTypographyVariant
    onClick?: () => void
}

export default function OutlineButtonHome(props: OutlineButtonHomeProps) {
    return <OutlineButton
        variant="menu"
        showRightArrow={true}
        {...props}
    />
}
