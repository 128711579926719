import { Grid } from '@material-ui/core';
import React from 'react';
import Paragraph from '../Paragraph/Paragraph';
import Typography from '../Typography/Typography';
import styles from './PresentationView.module.scss';

export default function PresentationView() {
    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid item xs={12} sm={4} className={styles.Image} />
            <Grid item xs={12} sm={8} className={styles.Text}>
                <main>
                    <Typography variant="h1colisseum">
                        Um símbolo do Porto
                    </Typography>

                    <Typography variant="subtitle2">
                        Desde 1941, o Coliseu é um espaço onde cabem todas as disciplinas artísticas e uma grande diversidade de públicos,
                        característica de uma cidade cosmopolita, múltipla, com uma forte identidade. É gerido desde 1995 pela Associação
                        Amigos do Coliseu do Porto, entidade sem fins lucrativos e de utilidade pública.
                    </Typography>

                    <section>

                        <Paragraph>
                            O Coliseu Porto Ageas é um símbolo da cidade. Não apenas pelo seu edifício único, mas porque ao
                            longo de mais de 80 anos acolheu os maiores nomes nacionais e internacionais de grande renome,
                            como Montserrat Caballé, Miles Davis, Bob Dylan, Mark Knopfler, Amália Rodrigues, Zeca Afonso, Cesária Évora,
                            Margot Fonteyn, Joaquín Cortés, Maria João Pires, Ray Charles, Philip Glass, Chico Buarque, Diana Krall,
                            Ryuichi Sakamoto, Paco de Lucía, B.B. King, Pat Metheny, Maria Callas, Elvis Costello, Morrissey, Bauhaus,
                            Radiohead, The Cult, Nick Cave & The Bad Seeds, The Smashing Pumpkins, Scorpions, Pixies ou Foo Fighters,
                            bem como a conferência “Climate Change Leadership”, onde o Presidente norte-americano Barack Obama foi Keynote Speaker.
                        </Paragraph>


                        <Paragraph>
                            Nesta sala democrática, sempre houve lugar para todos. O Coliseu presta serviço público na área da cultura
                            e espetáculos, tendo como associados institucionais o Estado português, o Município do Porto e a Área Metropolitana
                            do Porto. São ainda associadas instituições públicas e privadas da cidade e da região, bem como um conjunto
                            significativo de importantes empresas e coletividades e várias centenas de pessoas singulares que corporizam e
                            reforçam este sentido de pertença à cidade e à região. Todas as entidades, individuais ou coletivas, podem&nbsp;
                            <a href="https://amigos.coliseu.pt/" target="_blank" rel="noopener noreferrer">fazer parte da Associação</a>.
                        </Paragraph>

                        <Paragraph>
                            É com este objetivo de integração que o Coliseu Porto Ageas apresenta propostas ecléticas - da música ao teatro,
                            da dança à ópera, do circo à comédia -, articuladas entre programação própria, eventos institucionais e espetáculos
                            de acolhimento, nas áreas da cultura, do entretenimento e do corporativo / associativo. Tem, desde 2015, o Balleteatro
                            como escola e estrutura artística residente no seu edifício. E, desde 2023, um Serviço Educativo Coliseu dinâmico,
                            com propostas para todas as idades.
                        </Paragraph>


                        <Paragraph>
                            Deste equilíbrio entre abordagens criativas resulta hoje a grande rua coberta do Porto, que se transforma
                            e adapta a diferentes públicos e gerações. Um Coliseu inclusivo e mutante, de todos, para todos, motivo
                            de orgulho e entusiasmo que o tornam insubstituível.
                        </Paragraph>

                    </section>
                </main>
            </Grid>
        </Grid>
    )
}
