import backstage_palco_historias from '../../assets/images/educational/footer/backstage_palco_historias.webp';
import magia_sombras from '../../assets/images/educational/footer/magia_sombras.webp';
import palco_historias from '../../assets/images/educational/footer/palco_historias.webp';
import passaporte_coliseu from '../../assets/images/educational/footer/passaporte_coliseu.webp';
import rpg_coliseu from '../../assets/images/educational/footer/rpg_coliseu.webp';
import banner4 from '../../assets/images/educational/schools/banner_formacao_professores.webp';
import banner3 from '../../assets/images/educational/schools/banner_oficinas.webp';
import banner1 from '../../assets/images/educational/schools/banner_visita_jogo.webp';
import banner2 from '../../assets/images/educational/schools/banner_visitas_orientadas.webp';

export type EduActivityPreviewType = {
    id: number
    name: string
    slug: string
    category: string
    categoryId: number
    targetAudience: string
    targetAudienceIds: number[]
    link: string
    duration: string
    booking: string
    price: string
    description: string
    cover: string
    relatedActivities: number[]
}

export type EducationalCategory = {
    id: number
    name: string
    slug: string
    cover: string
}

export const educationalCategories: EducationalCategory[] = [
    { id: 1, name: "Visita Jogo", slug: "visita-jogo", cover: banner1 },
    { id: 2, name: "Visitas Orientadas", slug: "visitas-orientadas", cover: banner2 },
    { id: 3, name: "Oficinas", slug: "oficinas", cover: banner3 },
    { id: 4, name: "Formação Professores", slug: "formacao-professores", cover: banner4 },
]

export const targetAudiences = [
    { id: 1, name: "Geral" },
    { id: 2, name: "Pré-Escolar" },
    { id: 3, name: "1.º Ciclo" },
    { id: 4, name: "2.º Ciclo" },
    { id: 5, name: "3.º Ciclo" },
    { id: 6, name: "Secundário, Superior e Profissional" },
]

export function getActivityById(id: number): EduActivityPreviewType | undefined {
    return activities.find(activity => activity.id === id);
}

export function getCategoryBySlug(slug: string): EducationalCategory | undefined {
    return educationalCategories.find(category => category.slug === slug)
}

export function excludeCategoryFromList(category: EducationalCategory): EducationalCategory[] {
    return educationalCategories.filter(item => item.id !== category.id)
}


export const activities: EduActivityPreviewType[] = [
    {
        id: 1,
        name: "Passaporte Coliseu",
        slug: "passaporte-coliseu",
        category: "Visita Jogo",
        categoryId: 1,
        targetAudience: "Pré-escolar, 1.º e 2.º ciclo",
        targetAudienceIds: [2, 3, 4],
        link: "",
        duration: "1h00",
        booking: "2ª a 6ª (9h00 - 18h00)",
        price: "3€",
        description: "<p>Para os mais curiosos, recolectores de pistas, imagens e palavras, esta visita promete deixar lastro. Vamos percorrer um complexo labirinto de salas, escadas e corredores, munidos de um Passaporte que nos ajudará a descobrir as suas histórias, antigas, novas, misteriosas, surpreendentes! É de lápis na mão, entre palavras e desenhos, que fazemos esta viagem lembrando o passado, observando o presente e sonhando o futuro do Coliseu.</p>",
        cover: passaporte_coliseu,
        relatedActivities: [2, 3, 4, 5]
    },
    {
        id: 2,
        name: "Palco das Historias",
        slug: "palco-historias",
        category: "Visitas Orientadas",
        categoryId: 2,
        targetAudience: "1º, 2º e 3º ciclos, ensino secundário, profissional e superior",
        targetAudienceIds: [3, 4, 5, 6],
        link: "",
        duration: "1h00",
        booking: "2ª a 6ª (9h00 - 18h00)",
        price: "3€",
        description: "<p>O palco é o lugar de todas as histórias e o Coliseu já foi palco de muitas. Esta é uma visita múltipla, customizada, que permite a escolha do foco do discurso entre diferentes áreas: arquitetura, história e contexto sócio-cultural ou uma visita geral, que atravessa todos os temas de modo abrangente. Atendendo aos interesses e áreas de estudo de cada grupo, damos palco a diferentes olhares sobre este riquíssimo lugar, parte da identidade cultural da cidade do Porto e do país.</p>",
        cover: palco_historias,
        relatedActivities: [1, 3, 4, 5]
    },
    {
        id: 3,
        name: "Backstage Palco das Histórias",
        slug: "backstage-palco-historias",
        category: "Visitas Orientadas",
        categoryId: 2,
        targetAudience: "2º e 3ºciclo, ensino secundário, profissional e superior",
        targetAudienceIds: [4, 5, 6],
        link: "",
        duration: "1h00",
        booking: "2ª (9h00 - 18h00)",
        price: "4,5€",
        description: "<p>Se o palco é o lugar da magia, esta é a visita que revela como se fazem todos os truques. Desde o auditório ao palco, dos camarotes aos camarins, passando pelas inúmeras cordas e cortinas, escadas, cadeiras, labirintos e corredores subterrâneos, a experiência é total. Este acesso exclusivo aos bastidores do Coliseu faz-se acompanhar por um sem fim de histórias, desde a entrada e saída dos elefantes no circo antigo até à montagem e desmontagem de grandes eventos, de alguns dos maiores nomes da cultura internacional,separados por poucas horas. Como se monta um espetáculo? Como funciona o Coliseu? No backstage encontramos as respostas.</p>",
        cover: backstage_palco_historias,
        relatedActivities: [1, 2, 4, 5]
    },
    {
        id: 4,
        name: "A magia das sombras",
        slug: "magia-das-sombras",
        category: "Oficinas",
        categoryId: 3,
        targetAudience: "Pré-escolar; 1º e 2.º ciclo",
        targetAudienceIds: [2, 3, 4],
        link: "",
        duration: "1h30",
        booking: "3ª a 6ª (9h00 - 12h30)",
        price: "4,5€",
        description: "<p>Esta oficina faz-se de ilusão: escurecemos a sala, inventamos sombras e, no fim, nascem histórias! Desde figuras de sombras feitas com as mãos à construção de personagens e narrativas, vamos dar palco à imaginação! Narradores, personagens, cenários, há muito para organizar nesta intensa experiência de produção de espetáculos!</p>",
        cover: magia_sombras,
        relatedActivities: [1, 2, 3, 5]
    },
    {
        id: 5,
        name: "RPG Coliseu",
        slug: "rpg-coliseu",
        category: "Oficinas",
        categoryId: 3,
        targetAudience: "3º ciclo, ensino secundário, profissional e superior",
        targetAudienceIds: [5, 6],
        link: "",
        duration: "1h30",
        booking: "3ª a 6ª (9h00 - 12h30)",
        price: "4,5€",
        description: "<p>Fernando Pessoa, Álvaro de Campos, Ricardo Reis, Alberto Caeiro e muitos outros heterónimos de Pessoa juntam-se na Adega do Coliseu para uma aventura sem precedentes. Como seria se conseguíssemos entrar dentro da cabeça do Fernando Pessoa, atravessar ruas, prédios, lugares de poemas e de emoções, cheios de palavras e de pessoas que criou?</p><p> Através de um jogo  RPG(Role Playing Game/ jogo narrativo), cada um será um heterónimo numa aventura dentro de um mundo imaginado, inspirado nos textos deste grande e complexo escritor português.Num jogo de colaboração narrativa, onde ninguém perde nem ganha, é resolvendo enigmas, lançando dados e improvisando interações que exploramos a cidade - cabeça de Fernando Pessoa.</p>",
        cover: rpg_coliseu,
        relatedActivities: [1, 2, 3, 4]
    }
]
