import { Grid } from '@material-ui/core';
import React from 'react';
import gustavo from '../../assets/images/institution/residencies/gustavo_silvamaral.webp';
import lounge from '../../assets/images/institution/residencies/lounge_ageas_coliseu.webp';
import rafael from '../../assets/images/institution/residencies/rafael_oliveira.webp';
import Paragraph from '../Paragraph/Paragraph';
import Typography from '../Typography/Typography';
import styles from './ResidencyView.module.scss';

export default function ResidencyView() {
    const url = "https://drive.google.com/file/d/1NVE4Vl1gKLiFCsNs9j4mOl4oOxBd8LRE/view";
    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <main>

                <Typography variant="h1colisseum">
                    Residências Artísticas Lounge Ageas
		</Typography>

                <Typography variant="subtitle2">No dia do seu 80º aniversário, a 19 de dezembro de 2021, o Coliseu Porto Ageas inaugurou o Lounge Ageas, um novo espaço de residências artísticas e valorização dos profissionais das artes visuais.</Typography>

                <section className={styles.Content}>

                    <Paragraph>O Lounge Ageas situa-se no piso 1, ao nível dos camarotes, e assume-se como um pólo de criação cultural. O objetivo é estimular o desenvolvimento de carreiras artísticas em pintura, escultura, desenho, fotografia, joalharia, entre outras, colmatando as lacunas deste setor artístico que tantas vezes carece de espaços de trabalho, apoiando artistas no lançamento e emergência das suas carreiras. Ao mesmo tempo, o Coliseu expande a sua atividade para além das artes performativas, às quais sempre se tem dedicado.</Paragraph>

                    <img src={lounge} alt="Foto Lounge Coliseu Porto Ageas" />

                    <Typography variant="h6">
                        2023 - Rafael Oliveira
		    </Typography>

                    <Paragraph>Rafael Oliveira foi o artista em residência no Coliseu em 2023. “A Dream For Us To Dream” é o nome do projeto que o artista plástico português desenvolveu entre outubro e dezembro no Lounge Ageas, espaço que integra o programa InResidence.</Paragraph>

                    <img src={rafael} alt="Foto Rafael Oliveira" />

                    <Paragraph>“A Dream For Us To Dream” posiciona-se como uma metáfora à vida. “A conquista e a falha andam de mãos dadas, e é isso que nos torna humanos”, explica Rafael Oliveira, que sublinha neste trabalho o conceito de aceitação. Apresentam-se cenas de destruição e violência onde a vida insiste em florescer, bem como espaços desolados e destruídos, intempéries, incêndios e figuras em posições vulneráveis. Elementos visuais que procuram enaltecer o contraste entre o sublime e o feio, entre a ordem e caos.</Paragraph>

                    <Paragraph>Rafael Oliveira nasceu em 1996, em Guimarães, cidade onde reside e trabalha. Licenciado em Artes Plásticas na vertente da pintura pela Faculdade de Belas Artes da Universidade do Porto, a sua obra fez parte de inúmeras mostras e concursos nas mais diversas instituições e galerias de arte, destaca-se a sua participação na Bienal de Arquitetura de Veneza, em 2023.</Paragraph>

                    <Paragraph>No dia do seu 80º aniversário, a 19 de dezembro de 2021, o Coliseu Porto Ageas inaugurou o Lounge Ageas, um novo espaço de residências artísticas e valorização dos profissionais das artes visuais. É o segundo ano consecutivo em que o Coliseu participa no programa InResidence, apoiado pela Rede de Residências Artísticas da cidade do Porto, através da Ágora - Cultura e Desporto do Porto, E.M.. Rafael Oliveira ficará em residência artística entre os dias 23 de outubro e 17 de dezembro.</Paragraph>

                    <Typography variant="h6">
                        2022 - Gustavo Silvamaral
		    </Typography>

                    <Paragraph>Gustavo Silvamaral foi o primeiro artista visual em residência no Coliseu. Selecionado entre 50 candidaturas para passar dois meses na cidade do Porto, inspirar-se e criar arte, o artista natural de Brasília inspirou-se no processo de mineração do ouro no período colonial brasileiro e as suas consequências no Brasil, sendo uma delas a Independência. Os trabalhos, desenvolvidos entre setembro e novembro de 2022, tiveram como referência o facto de D. Pedro I, figura chave para a independência brasileira, ter escolhido no seu leito de morte deixar o seu coração na guarda da cidade do Porto. O resultado da residência artística pôde ser visto na exposição "Santo do Pau Oco", patente entre 21 a 27 de novembro de 2022 no Lounge Ageas, com entrada livre.</Paragraph>

                    <img src={gustavo} alt="Foto Gustavo Silvamaral" />

                    <Paragraph>Tendo em conta o bicentenário da Independência do Brasil e do centenário da Semana de Arte Moderna e de Brasília - Capital Ibero-americana das Culturas 2022, o Coliseu Porto Ageas estabeleceu parceria com o Camões - Centro Cultural Português em Brasília, em colaboração com o Governo do Distrito Federal de Brasília, e destinou a sua primeira residência artística a artistas visuais residentes em Brasília. A open call decorreu em junho e recebeu 51 candidaturas, entre as quais a de Gustavo Silvamaral. Nascido em Brasília em 1995, é licenciado em Artes Visuais pela Universidade de Brasília. Já expôs, por exemplo, no Museu de Arte de Brasília.</Paragraph>

                    <Typography variant="h6">
                        Regulamento
		    </Typography>

                    <Paragraph>Neste momento não há candidaturas a decorrer. Recorde o regulamento da Open Call 2022 <a href={url} target="_blank" rel="noopener noreferrer">aqui.</a></Paragraph>

                </section>
            </main>
        </Grid>
    )
}
