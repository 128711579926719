import banner4 from '../../assets/images/educational/schools/banner_formacao_professores.webp';
import banner3 from '../../assets/images/educational/schools/banner_oficinas.webp';
import banner2 from '../../assets/images/educational/schools/banner_visitas_orientadas.webp';
import banner1 from '../../assets/images/educational/schools/banner_visita_jogo.webp';

export const slides = [
    { id: 1, src: banner1, alt: "", url: "/educativo/programa-escolas/categoria/visita-jogo" },
    { id: 2, src: banner2, alt: "", url: "/educativo/programa-escolas/categoria/visitas-orientadas" },
    { id: 3, src: banner3, alt: "", url: "/educativo/programa-escolas/categoria/oficinas" },
    { id: 4, src: banner4, alt: "", url: "/educativo/programa-escolas/categoria/formacao-professores" }
]
