import { Grid } from '@material-ui/core'
import React from 'react'
import { Link } from '../Link/Link'
import Typography from '../Typography/Typography'
import getSections from './Helpers/getSections'
import getSectionSubsections from './Helpers/getSectionSubsections'
import styles from './Sitemap.module.scss'
import { Section } from './SitemapData'

interface SiteMapSectionProps {
    section: Section
}

function SiteMapSection(props: SiteMapSectionProps) {
    const { section } = props

    return (
        <div className={styles.Item}>
            <div style={{ marginBottom: 20 }}>
                <Typography variant="body2"
                    style={{
                        fontWeight: "bold",
                        textTransform: "uppercase"
                    }}
                >
                    <Link
                        name={section.name}
                        route={section.route}
                    />
                </Typography>
            </div>
            <SiteMapSubsection section={section} />
        </div >
    )
}

function SiteMapSubsection(props: SiteMapSectionProps) {
    const { section } = props
    const subsections = getSectionSubsections(section.id)

    return (
        <ul>
            {subsections?.map(subsection => {
                return (
                    <li key={subsection.name}>
                        <Typography variant="caption" style={{ lineHeight: 2 }}>
                            <Link
                                name={subsection.name}
                                route={subsection.route}
                            />
                        </Typography>
                    </li>
                )
            })}
        </ul >
    )
}

export default function Sitemap() {

    const sections = getSections()

    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid container component="section">
                <Grid container component="nav" justify="flex-start">
                    {sections?.map(section => <SiteMapSection
                        key={section.name}
                        section={section}
                    />)}
                </Grid>
            </Grid>
        </Grid>
    )
}
