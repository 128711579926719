import { Grid } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'
import Typography from '../../Typography/Typography'
import styles from './Circus2024View.module.scss'
import { Session as SessionType } from './Helpers/formatSession'
import { useGtXs } from '../../../hooks/useGtXs'

interface SessionProps {
    session: SessionType
}

export default function Session(props: SessionProps) {
    const { session } = props

    const gtXs = useGtXs()

    const classes = clsx({
        [styles.Session]: true,
        [styles.Disabled]: !session.isActive
    })

    const dateVariant = gtXs ? "RocGroteskMedium25" : "RocGroteskMedium18"
    const timeVariant = gtXs ? "RocGroteskRegular20" : "RocGroteskRegular14"

    const content = (
        <div className={classes}>
            <Typography variant={dateVariant} component="p">
                {session.date}
            </Typography>
            <Typography variant={timeVariant} component="p">
                {session.time}
            </Typography>
        </div >
    )

    return (
        <Grid item xs={6} md={4} lg={3}>
            {session.isActive ? (
                <Link to={session.url}>
                    {content}
                </Link>
            ) : (
                content
            )}
        </Grid>
    )
}
