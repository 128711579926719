import { SubSection } from "../../Sitemap/SitemapData"

export default function isRouteActive(path: string, route: string, subsections?: SubSection[]): boolean {

    if (path === route) {
        return true
    }

    const match = subsections?.some((subsection) => {
        if (subsection.route === path) {
            return true
        }
        return false
    })

    return match || false
}
