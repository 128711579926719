import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import getSectionSubsections from '../Sitemap/Helpers/getSectionSubsections';
import Submenu from '../Submenu/Submenu';

interface InformationViewProps extends RouteComponentProps<{ slug?: string }> { }

export default function InformationView(props: InformationViewProps) {
    const { match } = props

    const subsections = getSectionSubsections(7, true)
    const subsection = subsections.find(subsection => subsection.route === match.path)
    const component = subsection ? subsection.component : null

    return (
        <div>
            <Submenu items={subsections} path={match.path} />
            {component}
        </div>
    )
}
