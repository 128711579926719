import { Grid } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import clsx from 'clsx'
import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import useCarousel from '../../hooks/useCarousel'
import { HomeNewsAction, HomeNewsContent } from '../HomeNews/HomeNews'
import HomeNewsMobileItem from '../HomeNewsMobileItem/HomeNewsMobileItem'
import OutlineButtonHome from '../OutlineButtonHome/OutlineButtonHome'
import Typography from '../Typography/Typography'
import styles from './HomeNewsMobile.module.scss'

interface HomeNewsMobileProps {
    content: HomeNewsContent[]
    action: HomeNewsAction
}

export default function HomeNewsMobile(props: HomeNewsMobileProps) {
    const { content, action } = props
    const { next, previous, currentSlide, isStart, isEnd, carouselProps } = useCarousel(0, 1)

    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid container justify="center">
                <Typography variant="menu" className={styles.SectionName}>
                    Notícias
                </Typography>
            </Grid>
            <Grid container direction="column" justify="center" alignContent="center" className={styles.Arrow} onClick={previous}>
                <ArrowBackIosIcon className={clsx({ [styles.Disabled]: isStart })} />
            </Grid>
            <Carousel
                {...carouselProps}
                className={styles.Carousel}
                selectedItem={currentSlide}
                onChange={() => { }}
            >
                {content?.map(item => <HomeNewsMobileItem key={item.id} item={item} />)}
            </Carousel>
            <Grid container direction="column" justify="center" alignContent="center" className={styles.Arrow} onClick={next}>
                <ArrowForwardIosIcon className={clsx({ [styles.Disabled]: isEnd })} />
            </Grid>
            <Grid container justify="center">
                <Grid container justify="center" className={styles.OutlineButtonWrapper}>
                    <OutlineButtonHome route={action.route} name={action.linkName} />
                </Grid>
            </Grid>
        </Grid>
    )
}
