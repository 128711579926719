import { Grid } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/header/logo.webp'
import styles from './DesktopHeader.module.scss'

export default function DesktopHeader() {
    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid container component="section" alignContent="center">
                <Link to="/">
                    <img src={logo} alt="logo coliseu porto ageas" />
                </Link>
            </Grid>
        </Grid>
    )
}
