import classNames from 'classnames';
import React from 'react';
import styles from '../CircusRow/CircusRow.module.scss';
import CircusStar from '../CircusStar/CircusStar';

const CircusRow = ({ name, discipline, presentation, color }: { name: any, discipline: any, presentation: any, color: any }) => {
    return (
        <div className={classNames([styles.CircusRow, styles[color]])}>
            <h1>{name}</h1>
            <h3><b>{discipline}</b></h3>
            <h3>{presentation}</h3>
            <CircusStar />
        </div>
    );
};

export default CircusRow;
