import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { universalMedia } from '../../services/Utils';
import LightBox from '../LightBox/LightBox';
import styles from './EventGallery.module.scss';

const EventPhoto = ({ photo, show }: { photo: any, show: any }) => {

    const url = universalMedia(photo)

    const handleClick = () => {
        show(photo);
    };

    return (
        <div className={styles.EventPhoto}
            style={{ backgroundImage: `url(${url})` }}
            onClick={handleClick}
        />
    );
};

const EventGallery = ({ photos, hideTitle, title }: { photos: any, hideTitle?: boolean, title?: string }) => {

    const [selected, setSelected] = useState(null);
    const [open, setOpen] = useState(false);

    let _title = title || "Galeria do Evento";

    if (!photos || !photos.length) {
        return null;
    }

    const showPhoto = (photo: any) => {
        setSelected(photo);
        setOpen(true);
    };

    return (
        <Grid container direction="column">
            {!hideTitle && <h1 className={styles.GalleryTitle}>{_title}</h1>}
            <Grid container justify="center" alignItems="center">
                {photos.map((photo: any) => <EventPhoto key={Math.random()} photo={photo} show={showPhoto} />)}
            </Grid>
            <LightBox isActive={open} selected={selected} handleClose={() => setOpen(false)} />
        </Grid>
    );
};


export default EventGallery;
