import Grid from '@material-ui/core/Grid';
import React from 'react';
import useAgendaEvents from '../../hooks/useAgendaEvents';
import EventsGrid from '../EventsGrid/EventsGrid';
import LoadingView from '../LoadingView/LoadingView';
import styles from './ScheduleViewGrid.module.scss';

export default function ScheduleViewGrid() {

    const { data, loading } = useAgendaEvents()

    if (loading) {
        return <LoadingView />;
    }

    const events = data?.events?.nodes

    if (!events) {
        return <p>Não foram encontrados eventos</p>;
    }

    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <div className={styles.ScheduleView}>
                <EventsGrid events={events} showOptions={true} />
            </div>
        </Grid>
    )
}
