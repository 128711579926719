import { Grid } from '@material-ui/core';
import React from 'react';
import EducationalHero from '../EducationalHero/EducationalHero';
import GoBackToSchool from '../GoBackToSchool/GoBackToSchool';
import OutlineButton from '../OutlineButton/OutlineButton';
import { EducationalCategory } from '../SchoolsView/Activities';
import styles from './TeacherTraining.module.scss';

interface TeacherTrainingProps {
    category: EducationalCategory
}

export default function TeacherTraining(props: TeacherTrainingProps) {
    const { category } = props
    return (
        <div>
            <GoBackToSchool />
            <EducationalHero category={category.name} />
            <Grid container justify="center" className={styles.Wrapper}>
                <main>
                    <div className={styles.Title}>O uso da Voz (ACD Acreditada)</div>
                    <div className={styles.Date}>Abril 2025</div>
                    <div className={styles.Message}>
                        <p>Siga a newsletter do Coliseu para informações em primeira mão</p>
                    </div>
                    <Grid container justify="center">
                        <OutlineButton
                            name="Subscrever Newsletter"
                            route="/newsletter/subscrever/"
                            fullWidth={false}
                        />
                    </Grid>
                </main>
            </Grid>
        </div>
    )
}
