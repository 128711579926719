import { MenuItem } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import { Select } from 'formik-material-ui';
import * as React from 'react';
import { REQUIRED_FIELD } from '../../services/Config';
import Show from '../Show/Show';
import styles from './SelectField.module.scss';

interface SelectOption {
    id: string
    name: string
}

interface SelectFieldProps {
    name: string
    options: SelectOption[]
    variant?: "standard" | "outlined"
    fullWidth?: boolean
}

export default function SelectField(props: SelectFieldProps) {

    const { name, options, variant = "outlined", fullWidth = true } = props
    const context = useFormikContext()

    // @ts-ignore
    const hasError = context?.errors[name]

    return (
        <div>
            <Field
                component={Select}
                formControl={{ fullWidth }}
                variant={variant}
                fullWidth={true}
                name={name}
                error={hasError}

            >
                {options.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>)
                )}
            </Field >
            <Show on={hasError}>
                <p className={styles.RequiredField}>
                    {REQUIRED_FIELD}
                </p>
            </Show>
        </div>
    )
}
