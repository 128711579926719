import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import styles from './Loading.module.scss';

const Loading = () => (
    <Grid container justify="center" alignItems="center" className={styles.Loading}>
        <CircularProgress />
    </Grid>
);

export default Loading;
