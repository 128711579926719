import { Grid } from '@material-ui/core';
import React from 'react';
import Paragraph from '../Paragraph/Paragraph';
import Typography from '../Typography/Typography';
import styles from './PrivacyView.module.scss';

export default function PrivacyView() {
    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid item xs={12} sm={4} className={styles.Image} />
            <Grid item xs={12} sm={8} className={styles.Text}>
                <main>

                    <Typography variant="h1colisseum">
                        Política de Privacidade
		    </Typography>

                    <Typography variant="subtitle2">
                        A recolha e tratamento de dados pessoais foi delineada para estar em concordância com o Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho, de 27 de abril de 2016, relativo à proteçcão das pessoas singulares no que diz respeito ao tratamento de dados pessoais e à livre circulação desses dados e que revoga a Diretiva 95/46/CE (Regulamento Geral sobre a Proteção de Dados).
		    </Typography>

                    <section>
                        <Paragraph>Esta Politica de Privacidade explica:</Paragraph>
                        <Paragraph>Quais as informações pessoais que recolhemos e tratamos e por que o fazemos;</Paragraph>

                        <div className={styles.List}>
                            <Paragraph>
                                Como usamos essas informações;
 			    </Paragraph>
                            <Paragraph>
                                As opções que oferecemos, incluindo o modo de acesso e retificação de informações.
 			    </Paragraph>
                        </div>

                        <Paragraph>A Associação Amigos do Coliseu do Porto poderá, unilateralmente e a qualquer momento, alterar, modificar, aditar ou retificar a presente Política de Privacidade, sem necessidade de qualquer aviso prévio.</Paragraph>

                        <Paragraph>A Associação Amigos do Coliseu do Porto desenvolverá todos os esforços para garantir, de forma transparente e demonstrável, a conformidade com o RGPD e restante legislação relativa à protecção de dados.</Paragraph>
                        <Paragraph>A Associação Amigos do Coliseu do Porto garante o cumprimento de todos os princípios previstos no RGPD, quer nos processos de tratamento actuais, quer naqueles que venham a ser considerados, como por exemplo, na adopção de uma nova solução de sistemas de informação, dos quais se dastacam: </Paragraph>


                        <div className={styles.List}>
                            <Paragraph>Licitude, Lealdade e Transparência</Paragraph>
                            <Paragraph>Limitação das Finalidades</Paragraph>
                            <Paragraph>Minimização dos Dados</Paragraph>
                            <Paragraph>Exactidão</Paragraph>
                            <Paragraph>Limitação da Conservação</Paragraph>
                            <Paragraph>Integridade e Confidencialidade</Paragraph>
                        </div>

                        <Paragraph>A Associação Amigos do Coliseu do Porto garante os direitos dos titulares dos dados, garantindo que a resposta ao seu exercício é fornecida no tempo adequado de acordo os tempos de resposta definidos no RGPD, nomeadamente: </Paragraph>

                        <div className={styles.List}>
                            <Paragraph>Direito a ser informado</Paragraph>
                            <Paragraph>Direito de acesso</Paragraph>
                            <Paragraph>Direito de rectificação</Paragraph>
                            <Paragraph>Direito ao apagamento dos dados ("direito ao esquecimento")</Paragraph>
                            <Paragraph>Direito à limitação de tratamento</Paragraph>
                            <Paragraph>Direito à portabilidade dos dados</Paragraph>
                            <Paragraph>Direito de oposição</Paragraph>
                            <Paragraph>Direitos relativos a decisões automatizadas e definição de perfis</Paragraph>
                        </div>

                        <Paragraph>A Associação Amigos do Coliseu do Porto compromete-se adoptar o princípio da Privacy by Design, garantindo que todos os novos processos bem como as alterações aos processos existentes de recolha e tratamento de dados pessoais, serão planeadas com o respeito intrínseco das questões da privacidade. A adopção de novos processos de tratamento ou sistemas de informação será precedida de uma avaliação de impacto.</Paragraph>

                        <Paragraph>A avaliação de impacto na Protecção de Dados contemplará:</Paragraph>

                        <div className={styles.List}>
                            <Paragraph>Estudo do processo de tratamento e dos seus fundamentos</Paragraph>
                            <Paragraph>Verificação se o novo processo é adequado e necessário</Paragraph>
                            <Paragraph>Análise de risco do processo de tratamento para os titulares</Paragraph>
                            <Paragraph>Identificação dos mecanismos de controlo e mitigação dos riscos e demonstração de conformidade do processo com a legislação</Paragraph>
                        </div>

                        <Paragraph>Os novos processos de tratamento cumprirão sempre o princípio da minimização dos dados e, sempre que possível recorrerão a técnicas de pseudonimização ou anonimização.</Paragraph>
                        <Paragraph>A Associação Amigos do Coliseu do Porto assegura que todos os relacionamentos com outras entidades que envolvam tratamento de dados pessoais, são regulados por contrato escrito que contemplam informação específica e clara sobre o compromisso de cumprimento do RGPD.</Paragraph>
                        <Paragraph>As transferências de dados pessoais para entidades ou locais fora do território da Área Económica Europeia (União Europeia, Noruega, Islândia e Liechtenstein) são cuidadosa e previamente avaliadas, assegurando-se que as mesmas são autorizadas ao abrigo do RGPD.</Paragraph>
                        <Paragraph>A Associação Amigos do Coliseu do Porto compromete-se, de forma transparente, a avaliar a necessidade e proporcionalidade de comunicação às entidades afectadas por uma violação de dados pessoais. Respeitando o RGPD, sempre que a violação de dados possa representar um risco para os direitos e liberdades das pessoas, a autoridade de supervisão será informada, sem demora injustificada, no período máximo de 72 horas após a tomada de conhecimento da ocorrência.</Paragraph>

                        <Paragraph>Assim, de forma a garantir a conformidade da Associação Amigos do Coliseu do Porto com o RGPD, garantimos que:</Paragraph>

                        <div className={styles.List}>
                            <Paragraph>As bases de licitude do tratamento são claras e inequívocas; </Paragraph>
                            <Paragraph>Assim que se revelar necessário, um Encarregado de Protecção de Dados será nomeado para garantir o cumprimento das condições de Protecção e Privacidade;</Paragraph>
                            <Paragraph>Todos os colaboradores envolvidos no tratamento de dados pessoais têm consciência das suas responsabilidades e comprometem-se a seguir as boas práticas de protecção de dados;</Paragraph>
                            <Paragraph>Todos os colaboradores receberam formação em segurança da informação;</Paragraph>
                            <Paragraph>Os consentimentos foram obtidos de acordo com as regras do RGPD, e a Associação Amigos do Coliseu do Porto, Lda. mantém o registo das evidências desse acto;</Paragraph>
                            <Paragraph>São disponibilizados mecanismos ágeis de exercício dos direitos dos titulares dos dados;</Paragraph>
                            <Paragraph>É assegurada a revisão periódica dos procedimentos de protecção de dados</Paragraph>
                            <Paragraph>Privacy by design é contemplada em todos os novos sistemas de informação ou processos e ainda nos casos de alteração relevantes aos sistemas e processos existentes;</Paragraph>
                            <Paragraph>Manutenção de documentação e registos de:</Paragraph>
                            <div className={styles.List}>
                                <Paragraph>Identificação da organização e informação relevante das actividades desenvolvidas;</Paragraph>
                                <Paragraph>Justificação dos tratamentos de dados existentes;</Paragraph>
                                <Paragraph>Classificação da informação pessoal tratada;</Paragraph>
                                <Paragraph>Identificação dos destinatários dos dados pessoais;</Paragraph>
                                <Paragraph>Acordos e mecanismos de transferência de dados pessoais para fora da Área Económica Europeia, bem como dos mecanismos de controlo e monitorização aplicados;</Paragraph>
                                <Paragraph>Período de retenção de dados pessoais (por categoria de dados)</Paragraph>
                                <Paragraph>Medidas técnicas e organizativas de controlo da segurança dos dados.</Paragraph>
                            </div>
                        </div>

                        <Paragraph>Questões, comentários e pedidos relacionados com esta Política de Privacidade e os seus direitos deverão ser endereçados para <a href="mailto:privacidade@coliseu.pt">privacidade@coliseu.pt</a> ou via comunicação escrita para o seguinte endereço:</Paragraph>

                        <Paragraph>Rua de Passos Manuel 137<br />
 			    4000-385 Porto <br />
 			    Portugal<br />
                        </Paragraph>

                    </section>
                </main>
            </Grid>
        </Grid>
    )
}
