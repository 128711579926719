import { Grid } from '@material-ui/core'
import React from 'react'
import { HomeNewsContent } from '../HomeNews/HomeNews'
import { Link } from '../Link/Link'
import Typography from '../Typography/Typography'
import styles from './HomeNewsMobileItem.module.scss'

interface HomeNewsMobileItemProps {
    item: HomeNewsContent
}

export default function HomeNewsMobileItem(props: HomeNewsMobileItemProps) {

    const { date, name, image, route } = props.item

    return (
        <Grid container direction="column" className={styles.Wrapper}>
            <Grid container>
                <Typography variant="subtitle2" gutterBottom={false}>
                    {date}
                </Typography>
            </Grid>
            <Grid container className={styles.Image}>
                <Link route={route}>
                    <img src={image} alt={name} />
                </Link>
            </Grid>
            <Grid container>
                <Typography variant="body1" className={styles.Name}>
                    <Link name={name} route={route} />
                </Typography>
            </Grid>
        </Grid>
    )
}
