import Grid from '@material-ui/core/Grid';
import React from 'react';
import history1 from '../../assets/images/institution/history/history_1.jpg';
import history10 from '../../assets/images/institution/history/history_10.jpg';
import history11 from '../../assets/images/institution/history/history_11.jpg';
import history12 from '../../assets/images/institution/history/history_12.jpg';
import history13 from '../../assets/images/institution/history/history_13.jpg';
import history14 from '../../assets/images/institution/history/history_14.jpg';
import history15 from '../../assets/images/institution/history/history_15.jpg';
import history16 from '../../assets/images/institution/history/history_16.jpg';
import history18 from '../../assets/images/institution/history/history_18.jpg';
import history19 from '../../assets/images/institution/history/history_19.jpg';
import history2 from '../../assets/images/institution/history/history_2.png';
import history20 from '../../assets/images/institution/history/history_20.jpg';
import history21 from '../../assets/images/institution/history/history_21.jpg';
import history22 from '../../assets/images/institution/history/history_22.jpg';
import history23 from '../../assets/images/institution/history/history_23.jpg';
import history24 from '../../assets/images/institution/history/history_24.jpg';
import history25 from '../../assets/images/institution/history/history_25.jpg';
import history26 from '../../assets/images/institution/history/history_26.jpg';
import history27 from '../../assets/images/institution/history/history_27.jpg';
import history28 from '../../assets/images/institution/history/history_28.jpg';
import history29 from '../../assets/images/institution/history/history_29.jpg';
import history3 from '../../assets/images/institution/history/history_3.jpg';
import history30 from '../../assets/images/institution/history/history_30.jpg';
import history31 from '../../assets/images/institution/history/history_31.jpg';
import history4 from '../../assets/images/institution/history/history_4.jpg';
import history5 from '../../assets/images/institution/history/history_5.jpg';
import history7 from '../../assets/images/institution/history/history_7.jpg';
import history8 from '../../assets/images/institution/history/history_8.jpg';
import history9 from '../../assets/images/institution/history/history_9.jpg';
import history32 from '../../assets/images/institution/history/history_32.png';
import history33 from '../../assets/images/institution/history/history_33.jpg';
import Paragraph from '../Paragraph/Paragraph';
import Typography from '../Typography/Typography';
import styles from './HistoryView.module.scss';

export default function HistoryView() {
    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <main>

                <Typography variant="h1colisseum">
                    História
                </Typography>

                <div className={styles.TextImage}>
                    <img src={history1} alt="" className={styles.PageImage} />
                    <Typography variant="subtitle2">
                        A história do Coliseu Porto começa antes mesmo da construção do seu edifício em 1941. O Salão Jardim Passos Manuel foi o precursor da grande sala que hoje conhecemos e foi este espaço que começou por moldar os hábitos de entretenimento da sociedade portuense do início do século passado. Nesta cronologia, contamos os momentos mais importantes da sua história. História que se quer escrita e construída por todos, a cada dia.
                    </Typography>
                </div>

                <section>

                    <div className={styles.TextImage}>
                        <img src={history2} alt="" className={styles.PageImage} />
                        <Typography variant="h6">
                            O Jardim Passos Manuel
                        </Typography>
                        <Paragraph>O Salão Jardim Passos Manuel, que começou por ser Salão de Cynematographo, foi inaugurado em 1908 no preciso local onde hoje vemos o edifício do Coliseu. O imediato sucesso do espaço levou a que o seu dono, o empresário de teatro Luíz Alberto Faria de Guimarães, avançasse poucos anos depois com uma profunda remodelação e ampliação do salão, de forma a acolher uma sala de espetáculos com jardim-esplanada, salão de festas, pavilhão restaurante, music-hall e um pequeno teatro. Em 1938, o Salão Jardim Passos Manuel foi desativado para dar início à construção do edifício do Coliseu Porto.</Paragraph>
                    </div>

                    <Grid container direction="row" className={styles.RowImage} spacing={2}>
                        <Grid item xs={3}>
                            <img src={history19} alt="" />
                        </Grid>
                        <Grid item xs={3}>
                            <img src={history3} alt="" />
                        </Grid>
                        <Grid item xs={3}>
                            <img src={history21} alt="" />
                        </Grid>
                        <Grid item xs={3}>
                            <img src={history20} alt="" />
                        </Grid>
                    </Grid>


                    <div className={styles.TextImage}>
                        <img src={history4} alt="" className={styles.PageImage} style={{ marginBottom: 40 }} />

                        <Typography variant="h6">
                            O Coliseu do Porto
                        </Typography>

                        <Paragraph>Propriedade da companhia de seguros Garantia, após 22 meses de obra e com uma construção no valor de 11 mil contos (aproximadamente 55 mil euros), o Coliseu do Porto abriu as suas portas ao público pela primeira vez a 19 de dezembro de 1941 com um Sarau de Gala. Desde logo a programação dava particular atenção à ópera, ao ballet e aos espetáculos de variedades. Um ecletismo que faz parte do ADN desta sala. Da década de 40 até ao final dos anos 60, pisaram o palco do Coliseu figuras como Beatriz Costa, Marcel Marceau, Hermínia Silva, Rudolf Nureyev, entre tantas outras. As óperas La Bohème e Rigoletto pela Orquestra Sinfónica Nacional e pela Escola do Corpo Coral do Teatro São Carlos, os filmes musicais West Side Story e Música no Coração, e o Festival da Canção Portuguesa são exemplos da popularidade de alguns géneros artísticos à época.</Paragraph>
                    </div>

                    <Grid container direction="row" className={styles.RowOfImages} wrap="nowrap">
                        <Grid item xs={3}>
                            <img src={history24} alt="" />
                        </Grid>
                        <Grid item xs={3}>
                            <img src={history5} alt="" />
                        </Grid>
                        <Grid item xs={2}>
                            <img src={history23} alt="" />
                        </Grid>
                        <Grid item xs={2}>
                            <img src={history25} alt="" />
                        </Grid>
                        <Grid item xs={2}>
                            <img src={history22} alt="" />
                        </Grid>

                    </Grid>

                    <Grid container direction="row" className={styles.RowOfImages} wrap="nowrap">
                        <Grid>
                            <img src={history26} alt="" />
                        </Grid>
                        <Grid>
                            <img src={history27} alt="" />
                        </Grid>
                        <Grid>
                            <img src={history18} alt="" />
                        </Grid>
                    </Grid>

                    <Grid container className={styles.RowOfImages} wrap="nowrap">
                        <Grid>
                            <img src={history30} alt="" />
                        </Grid>
                        <Grid>
                            <img src={history31} alt="" />
                        </Grid>
                        <Grid>
                            <img src={history7} alt="" />
                        </Grid>
                        <Grid>
                            <img src={history29} alt="" />
                        </Grid>
                        <Grid>
                            <img src={history28} alt="" />
                        </Grid>
                    </Grid>

                    <div className={styles.TextImage}>
                        <img src={history8} alt="" className={styles.PageImage} />
                        <Typography variant="h6">
                            O Cine-Estúdio Passos Manuel
                        </Typography>
                        <Paragraph>O Passos Manuel inaugurou em novembro de 1971 com a projeção de Vanishing Point e continuou a sua programação com filmes considerados polémicos à época, como O Charme Discreto da Burguesia, de Luis Buñuel. A sala tinha sido concebida especialmente com a finalidade de se dedicar ao cinema de autor, razão pela qual tinha lotação reduzida. No Programa de Cinema do Coliseu do Porto de 1973, pode ler-se claramente este posicionamento: “(…) ir de encontro a um público que deseja estar em contacto com os grandes autores e as obras que fazem a História da Arte Cinematográfica. Assim, pelo écran do Passos Manuel – e correndo mesmo todos os riscos comerciais que isso implica – passará uma selecção muito rigorosa de filmes da mais alta qualidade, representativos de diversas cinematografias, muitos dos quais já aureolados por sólida reputação mundial.” O Passos Manuel, com uma programação própria e independente do Coliseu, continua hoje a dedicar uma parte da sua atividade à projeção de cinema de autor e funciona também como bar e sala de concertos.</Paragraph>
                    </div>


                    <div className={styles.TextImage}>
                        <img src={history9} alt="" className={styles.PageImage} />
                        <Typography variant="h6">
                            Cinquentenário
                        </Typography>
                        <Paragraph>Em 1991 o Coliseu celebra o seu cinquentenário com um grande concerto de homenagem ao espetáculo de inauguração do edifício, em 1941. O Concerto Inaugural recriou esse primeiro momento musical, com a Orquestra do Porto Régie-Sinfonia e dirigido pelo maestro Jan Lathan-Koenig. Ao piano estava Helena Moreira Sá e Costa, acompanhada pelo seu discípulo Pedro Burmester e, juntos, tocaram obras de Bach. Foi também no início dos anos 90 que Maria João Pires regressou ao palco do Coliseu Porto, 38 anos depois de ter tocado, ainda criança, com a Orquestra Sinfónica da Câmara Municipal do Porto, sob a orientação do maestro Ino Savini. Neste mesmo ano, o mítico trompetista Miles Davis pisou o palco do Coliseu.</Paragraph>
                    </div>



                    <div className={styles.TextImage}>
                        <img src={history10} alt="" className={styles.PageImage} style={{ marginBottom: 60 }} />
                        <Typography variant="h6">
                            Os Amigos do Coliseu
                        </Typography>
                        <Paragraph>Em 1995, depois de virem a público alguns rumores sobre a eventual venda do Coliseu do Porto à IURD (Igreja Universal do Reino de Deus), pela Empresa Artística, SA/Grupo Aliança - UAP, a população saiu à rua para se manifestar contra esta negociação. Aos artistas e agentes culturais juntaram-se cidadãos anónimos, instituições e empresas privadas, todos a reclamarem o Coliseu como património da cidade. Após alguns dias de pressão, a empresa gestora do Coliseu disponibilizou-se para negociar uma nova solução. Gerou-se espontaneamente uma onda de solidariedade, que culminou na criação da Associação dos Amigos do Coliseu do Porto, formalmente constituída a 17 de novembro de 1995, presidida por José António Barros, que se manteve na direção da Associação até 2014. A 2 de agosto de 1996 é feita a escritura pública de compra e venda do Coliseu por 680 mil contos (aproximadamente 3 400 000,00€).</Paragraph>
                    </div>


                    <div className={styles.TextImage}>
                        <img src={history11} alt="" className={styles.PageImage} />
                        <Typography variant="h6">
                            O incêndio
                        </Typography>
                        <Paragraph>No dia 28 de setembro de 1996, poucas horas após o evento Portugal Fashion, que contou com a participação da top model Claudia Schiffer, ocorre um incêndio que destrói por completo a caixa de palco e faz graves estragos na sala principal e camarins. Ainda com a memória do ano anterior. Gera-se uma nova onda de solidariedade que reúne o apoio de instituições, empresas e particulares. Só assim foi possível a reabilitação do edifício em poucos meses, permitindo que o Coliseu abrisse novamente as portas a 12 de dezembro. Mesmo a tempo de não quebrar a tradição do Circo de Natal.</Paragraph>
                    </div>

                    <Grid container direction="row" className={styles.RowImage} spacing={2}>
                        <Grid item xs={3}>
                            <img src={history13} alt="" />
                        </Grid>
                        <Grid item xs={3}>
                            <img src={history12} alt="" />
                        </Grid>
                        <Grid item xs={3}>
                            <img src={history15} alt="" />
                        </Grid>
                        <Grid item xs={3}>
                            <img src={history14} alt="" />
                        </Grid>
                    </Grid>

                    <div className={styles.TextImage}>
                        <img src={history16} alt="" className={styles.PageImage} />
                        <Typography variant="h6">
                            Renascer das cinzas
                        </Typography>
                        <Paragraph>Entre 1997 e 2001, as preocupações dos responsáveis de então pelo Coliseu convergiram para a modernização do equipamento técnico destruído e para a persecução da atividade. Seguiram-se obras de requalificação, a atualização de equipamentos técnicos e a criação de um novo projeto cénico, intervenções que foram concluídas no final de 1998 e após as quais o Coliseu reabriu com Carmen, de Bizet, uma co-produção do Círculo Portuense de Ópera e da Orquestra Nacional do Porto. Uma última intervenção nas infraestruturas do edifício foi a colocação da pista de circo em 1999. Com 13 metros de diâmetro e assente numa placa elevatória hidráulica, esta era, à altura em que foi construída, a maior pista de circo da Europa.</Paragraph>
                    </div>

                    <div className={styles.TextImage}>
                        <img src={history32} alt="" className={styles.PageImage} />
                        <Typography variant="h6">
                            O Coliseu Porto e a Ageas: Mais Futuro Juntos
                        </Typography>
                        <Paragraph>
                            Em abril de 2018, o prestigiado grupo segurador Ageas viu no Coliseu uma instituição de
                            confiança com uma marca forte. Da parceria entre as duas instituições nasceu o primeiro
                            naming cultural na Região Norte: Coliseu Porto Ageas. Associada fundadora da Associação
                            dos Amigos do Coliseu, a Ageas apoia o Coliseu desde então. "Ter o Coliseu como parceiro,
                            presente e futuro, foi uma escolha óbvia. Pelo simbolismo e pela
                            diversidade, mas também pela evolução e visão futura que se pretende para este espaço, pelo
                            que achamos que será a união perfeita. Orgulhamo-nos muito desta parceria e estamos fortemente
                            empenhados em contribuir para que o Coliseu continue a ser uma referência na cultura nacional",
                            referiu Steven Braekeveldt, CEO do Grupo Ageas Portugal.
                        </Paragraph>

                    </div>

                    <div className={styles.TextImage}>
                        <img src={history33} alt="" className={styles.PageImage} />
                        <Typography variant="h6">
                            A fundação de um Serviço Educativo Coliseu
                        </Typography>
                        <Paragraph>
                            Pela primeira vez em 80 anos de história, o Coliseu lançou, no verão de 2023, um Serviço
                            Educativo para reforçar a ligação de crianças, jovens e de todos os públicos, quer às artes,
                            quer a esta histórica instituição. "Uma Casa com tanta história para contar deve ter um Serviço
                            Educativo. Este será um projeto identitário para o presente e de projeção de futuro, o cimento da
                            relação que o Coliseu terá com a cidade", explicou Miguel Guedes, Presidente do Coliseu, à imprensa.
                            Os programas, transversais e multidisciplinares, desdobram-se em vários formatos: oficinas para todas
                            as idades, campos de férias para crianças e jovens, programas escolares, programas sociais, parcerias
                            com outras instituições culturais da cidade, visitas guiadas temáticas e até um atelier de pintura.
                            A programação inclui atividades para todos, com especial destaque para as famílias, implementada
                            por uma equipa com currículo em grandes instituições educativas, sociais e culturais,
                            relacionados com educação através das artes.
                        </Paragraph>
                    </div>

                </section>
            </main>
        </Grid >
    )
}
