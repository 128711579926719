import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useGtXs } from '../../hooks/useGtXs';
import ActionButton from '../ActionButton/ActionButton';
import Gallery from '../Gallery/Gallery';
import Modal from '../Modal/Modal';
import OfferingDetails from '../OfferingDetails/OfferingDetails';
import Typography from '../Typography/Typography';
import styles from './Offering.module.scss';

interface OfferingProps {
    offering: any
}

const Actions = (props: any) => {
    const { actions, onClick, fullWidth = false } = props
    return (
        <div className={styles.Actions} >
            {actions?.map((action: any, index: number) => {
                return (
                    <ActionButton
                        key={index}
                        onClick={onClick}
                        fullWidth={fullWidth}
                        {...action}
                    />
                )
            })}
        </div >
    )
}

export default function Offering(props: OfferingProps) {
    const { offering } = props

    const { name, area, description, photos, actions, promos } = offering

    const gtXs = useGtXs()
    const [isOpen, _setIsOpen] = useState<boolean>(false)


    const setIsOpen = () => {
        _setIsOpen(!isOpen)
    }

    if (!gtXs) {
        return (
            <div className={styles.Offering}>
                <div className={styles.Name}>
                    <Typography variant="RocGroteskBold25">
                        {name}
                    </Typography>
                </div>
                <div className={styles.Area}>
                    <Typography variant="RocGroteskRegular20">
                        {area}
                    </Typography>
                </div>
                <div className={styles.Gallery}>
                    <Gallery photos={photos} />

                    <Grid container spacing={1} className={styles.Promos}>
                        {promos?.map((promo: string, index: number) => {
                            return (
                                <Grid key={index} item xs={6} className={styles.Promo}>
                                    <img src={promo} alt="" />
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Modal isOpen={isOpen} onClose={() => _setIsOpen(false)} fullScreen onTop>
                        <div>
                            <OfferingDetails offering={offering} />
                        </div>
                    </Modal>

                </div>
                <div className={styles.Description}>
                    <p>{description}</p>
                </div>
                <Actions actions={actions} onClick={() => setIsOpen()} fullWidth={true} />
            </div>
        )
    }

    return (
        <Grid container spacing={10} className={styles.Offering}>
            <Grid item md={5}>
                <div className={styles.Name}>
                    <Typography variant="RocGroteskBold33">
                        {name}
                    </Typography>
                </div>
                <div className={styles.Area}>
                    <Typography variant="RocGroteskRegular25">
                        {area}
                    </Typography>
                </div>
                <div className={styles.Description}>
                    <p>{description}</p>
                </div>
                <Actions actions={actions} onClick={() => setIsOpen()} />
            </Grid>

            <Grid container item md={7} className={styles.Gallery}>
                <Gallery photos={photos} />

                <Grid container spacing={2} className={styles.Promos}>
                    {promos?.map((promo: string, index: number) => {
                        return (
                            <Grid key={index} item xs={6} className={styles.Promo}>
                                <img src={promo} alt="" />
                            </Grid>
                        )
                    })}
                </Grid>
                <Modal isOpen={isOpen} onClose={() => _setIsOpen(false)}>
                    <div>
                        <OfferingDetails offering={offering} />
                    </div>
                </Modal>
            </Grid>
        </Grid >
    )
}
