import React from 'react';
import { Helmet } from "react-helmet";
import { Post } from '../../generated/graphql';
import { Config } from '../../services/Config';
import { htmlToText } from '../../services/Utils';
import { DeepPartial } from '../../types/types';

interface PostDetailsHelmetProps {
    post: DeepPartial<Post>
}

export default function PostDetailsHelmet(props: PostDetailsHelmetProps) {
    const { post } = props
    return (
        <Helmet>
            <meta property="og:site_name" content="Coliseu Porto Ageas" />
            <meta property="og:url" content={`${Config.APP}noticia/${post.slug}`} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={post?.name || undefined} />
            <meta property="og:description" content={htmlToText(post.description)} />
            <meta property="og:image" content={`${Config.MEDIA_URL}${post.cover}`} />
            <meta property="og:image:width" content={String(post.coverWidth)} />
            <meta property="og:image:height" content={String(post.coverHeight)} />
        </Helmet>
    )
}
