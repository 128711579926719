import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useGtXs } from '../../hooks/useGtXs';
import { Link } from '../Link/Link';
import { SubSection } from '../Sitemap/SitemapData';
import Typography from '../Typography/Typography';
import styles from './Submenu.module.scss';

interface SubmenuProps {
    items: SubSection[]
    path: string
}

export default function Submenu(props: SubmenuProps) {

    const gtXs = useGtXs()

    const justify = gtXs ? "center" : "flex-start"

    const { items, path } = props

    if (!items || items.length < 1) return null;

    return (
        <Grid container justify={justify} alignItems="center" wrap="nowrap" className={styles.Submenu}>
            {items.map(({ id, route, name, hide }) => {
                if (hide) return null
                const classes = path === route ? styles.Active : null
                return (
                    <Typography variant="menu" className={styles.Test}>
                        <Link key={id} name={name} route={route} className={classes} />
                    </Typography>
                )
            })}
        </Grid>
    )
}
