import { EventFilter, EventSortFields, SortDirection, useEventsQuery } from "../generated/graphql"

interface UseAgendaEventsParams {
    limit?: number
    variant?: "agenda" | "homeAgenda"
}

export default function useAgendaEvents(params: UseAgendaEventsParams = {}) {

    const { limit = 9999, variant = "agenda" } = params

    const filter: EventFilter = {
        isArchived: { is: false },
        isVisible: { is: true },
        category: { id: { notIn: ["28"] } }
    }

    if (variant === "homeAgenda") {
        filter.isVisibleHomeAgenda = { is: true }
    }

    return useEventsQuery({
        variables: {
            paging: { limit },
            sorting: [{ field: EventSortFields.StartDate, direction: SortDirection.Asc }],
            filter
        }
    })
}
