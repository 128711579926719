import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import logo from '../../assets/images/header/logo.webp';
import MobileMenu from '../MobileMenu/MobileMenu';
import styles from './MobileHeader.module.scss';

export default function MobileHeader() {

    const [menuState, setMenuState] = useState(false);

    const handleClose = () => {
        setMenuState(false)
    }

    return (
        <>
            <Drawer open={menuState} onClose={() => false} anchor="top" elevation={1} style={{ zIndex: 100000 }}>
                <MobileMenu close={handleClose} />
            </Drawer>
            <Grid container justify="space-between" alignItems="center" className={styles.MobileHeader}>
                <Grid item xs={8} style={{ textAlign: 'center' }}>
                    <Link to={'/'}>
                        <img src={logo} alt='' />
                    </Link>
                </Grid>
                <Grid item xs={2}>
                    <IconButton className={styles.menuButton} onClick={() => setMenuState(true)}>
                        <MenuIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </>
    )
}
