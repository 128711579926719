import { Grid } from '@material-ui/core';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import { addDays } from 'date-fns';
import { Form, Formik, FormikValues } from 'formik';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from "yup";
import mobile_logo from '../../assets/images/educational/informations/educational_service_logo_mobile.webp';
import { useCreateOneActivityRegistrationMutation } from '../../generated/graphql';
import { useGtXs } from '../../hooks/useGtXs';
import { formInit } from '../../utils/forms';
import CheckboxField from '../CheckboxField/CheckboxField';
import { ActivityName, DetailsAndAreas } from '../EducationalActivityView/EducationalActivityView';
import Hide from '../Hide/Hide';
import InputDateTimeField from '../InputDateTimeField/InputDateTimeField';
import InputTextField from '../InputTextField/InputTextField';
import OutlineButton from '../OutlineButton/OutlineButton';
import { activities } from '../SchoolsView/Activities';
import SelectField from '../SelectField/SelectField';
import Show from '../Show/Show';
import styles from './EducationalActivityEnrollView.module.scss';
import { prepareOptions } from './Helpers/prepareOptions';

type Enroll = {
    teacher: string
    phone: string
    email: string
    school: string
    city: string
    participants: string
    educationalLevel: string
    year: string
    area: string
    preferedDate: Date
    alternativeDate: Date
    confirmation: boolean
    subscription: boolean
}

function schemaGetter() {
    return Yup.object().shape({
        teacher: Yup.string().required().default(""),
        phone: Yup.string().required().default(""),
        email: Yup.string().email().required().default(""),
        school: Yup.string().required().default(""),
        city: Yup.string().required().default(""),
        participants: Yup.string().required().default(""),
        educationalLevel: Yup.string().required().default(""),
        year: Yup.string().required().default(""),
        area: Yup.string().nullable().default(""),
        preferedDate: Yup.date().required().default(null),
        alternativeDate: Yup.date().required().default(null),
        confirmation: Yup.boolean().required().default(false).oneOf([true]),
        subscription: Yup.boolean().default(false)
    })
}


export default function EducationalActivityEnrollView(props: any) {

    const gtXs = useGtXs()
    const history = useHistory()
    const [hideConditions, setHideConditions] = useState<boolean>(true)
    const slug = props.match.params.slug
    const activity = activities.find(act => act.slug === slug)
    const [createRegistration] = useCreateOneActivityRegistrationMutation()
    const spacing = gtXs ? 8 : 0;

    if (!activity) {
        return <div>Atividade Não Encontrada</div>
    }

    const { schema, initial } = formInit<Enroll>(schemaGetter)

    const formatDate = (date: Date) => {
        return date.toLocaleDateString("pt-PT", {
            year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'
        })
    }

    const handleSubmit = async (values: FormikValues) => {
        if (values.confirmation) {
            const activityName = activity.name
            values.preferedDate = formatDate(values.preferedDate)
            values.alternativeDate = formatDate(values.alternativeDate)
            const response = await createRegistration({
                // @ts-ignore
                variables: { input: { activityRegistration: { activity: activityName, ...values } } }
            })
            if (response.data) {
                history.push(`/educativo/programa-escolas/submissao/${slug}`)
            }
        }
    }

    const options = prepareOptions(activity.targetAudienceIds)
    const minDate = addDays(new Date(), 5);
    const classes = clsx({ [styles.Underlined]: !hideConditions })

    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <main>


                <Grid container spacing={spacing} justify="space-between" className={styles.Wrapper}>

                    <Show on={!gtXs}>
                        <div className={styles.Logo}>
                            <img src={mobile_logo} alt="logo serviço educativo" />
                        </div>
                    </Show>

                    <Grid container item xs={12} sm={5} style={{ paddingBottom: 0 }}>
                        <ActivityName activity={activity} />
                    </Grid>

                    <Grid container item xs={5} style={{ paddingBottom: 0 }}>
                    </Grid>


                    <Grid container item xs={12} sm={5} alignContent="space-between" style={{ paddingBottom: 0 }}>

                        <div>
                            <DetailsAndAreas activity={activity} />
                        </div>

                        <Show on={!gtXs}>
                            <div onClick={() => setHideConditions(!hideConditions)} className={styles.ToogleConditions}>
                                <span className={classes}>Marcações e Condições</span> {hideConditions ? <ChevronRight /> : <ExpandMore />}
                            </div>
                        </Show>

                        <Hide on={!gtXs && hideConditions}>

                            <div className={styles.Enrollments}>
                                <h3>Marcações:</h3>
                                <p>
                                    Sendo uma sala de espetáculos com uma intensa e dinâmica agenda, a disponibilidade do acesso aos diferentes espaços
                                    carece de avaliação e confirmação por parte da equipa do Serviço Educativo Coliseu.
                                </p>

                                <h3>Condições:</h3>

                                <p>As atividades carecem de pagamento prévio, por transferência bancária, seguindo as indicações dadas pela equipa do serviço educativo, via e-mail.</p>
                                <p>Por cada grupo de estudantes é obrigatório a presença de um adulto responsável, cuja participação é gratuita.</p>
                            </div>

                        </Hide>

                    </Grid>

                    <Grid item xs={12} sm={5} className={styles.Form} style={{ paddingBottom: 0 }}>
                        <Formik initialValues={initial} onSubmit={handleSubmit} validationSchema={schema} validateOnBlur={false} validateOnChange={false}>
                            {({ submitForm, isSubmitting, errors }) => {
                                return (
                                    <Form autoComplete="off">
                                        <p>
                                            <span>Nome do docente</span>
                                            <InputTextField name="teacher" variant="outlined" fullWidth />
                                        </p>
                                        <p>
                                            <span>Contacto Telefónico</span>
                                            <InputTextField name="phone" variant="outlined" fullWidth />
                                        </p>
                                        <p>
                                            <span>Email</span>
                                            <InputTextField name="email" variant="outlined" fullWidth />
                                        </p>
                                        <p>
                                            <span>Nome da Escola</span>
                                            <InputTextField name="school" variant="outlined" fullWidth />
                                        </p>
                                        <p>
                                            <span>Cidade</span>
                                            <InputTextField name="city" variant="outlined" fullWidth />
                                        </p>
                                        <p>
                                            <span>Número de participantes <small>(alunos e professores)</small></span>
                                            <InputTextField name="participants" variant="outlined" fullWidth />
                                            <small style={{ fontSize: 14, color: "#4a4a4a" }}>ex: 20 alunos, 2 professores</small>
                                        </p>

                                        <p>
                                            <span>Nível de Ensino</span>
                                            <SelectField name="educationalLevel" options={options} />
                                        </p>

                                        <p>
                                            <span>Ano de estudos</span>
                                            <InputTextField name="year" variant="outlined" fullWidth />
                                        </p>
                                        <p>
                                            <span>Área de estudo <small>(se aplicável)</small></span>
                                            <InputTextField name="area" variant="outlined" fullWidth />
                                        </p>


                                        <p>
                                            <span>Dia e hora <small>(opção preferencial)</small></span>
                                            <InputDateTimeField name="preferedDate" minDate={minDate} />
                                        </p>

                                        <p>
                                            <span>Dia e hora <small>(opção alternativa)</small></span>
                                            <InputDateTimeField name="alternativeDate" minDate={minDate} />
                                        </p>

                                        <Grid container justify="space-between" alignItems="flex-start">
                                            <Grid item xs={2}>
                                                <CheckboxField name="confirmation" />
                                            </Grid>
                                            <Grid item xs={8}>
                                                {/* <p>Confirmo que tomei conhecimento das condições de marcação e de cancelamento</p> */}
                                                <p>Confirmo que tomei conhecimento das condições de marcação</p>
                                            </Grid>
                                        </Grid>

                                        <Grid container justify="space-between" alignItems="flex-start" style={{ marginTop: 20 }}>
                                            <Grid item xs={2}>
                                                <CheckboxField name="subscription" />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <p>Subscrever a Newsletter do Coliseu para saber notícias em primeira mão</p>
                                            </Grid>
                                        </Grid>


                                        <div style={{ marginTop: 30, marginBottom: 30 }}>
                                            <Show on={!!errors?.confirmation}>
                                                <p><small style={{ color: "red" }}>
                                                    Obrigatório assinalar caixa "condições de marcação e cancelamento"
                                                </small></p>
                                            </Show>
                                            <OutlineButton
                                                name={isSubmitting ? "..." : "Submeter"}
                                                fullWidth
                                                onClick={submitForm}
                                            />
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Grid>

                    <Grid item xs={5} style={{ paddingTop: 0 }}></Grid>
                    <Grid item xs={12} sm={5} style={{ paddingTop: 0 }}>
                        <p>Este formulário inicia o processo de pedido de reserva. Esta apenas é válida após confirmação pela equipa do Serviço Educativo Coliseu.</p>
                    </Grid>

                </Grid>
            </main>
        </Grid>
    )
}
