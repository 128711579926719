import { Grid } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import { useGtXs } from '../../hooks/useGtXs';
import { filterByCategory } from '../EducationalActivitySearch/Helpers/filterActivities';
import { ActivityName, DetailsAndAreas } from '../EducationalActivityView/EducationalActivityView';
import EducationalHero from '../EducationalHero/EducationalHero';
import GoBackToSchool from '../GoBackToSchool/GoBackToSchool';
import OutlineButton from '../OutlineButton/OutlineButton';
import { activities, EduActivityPreviewType, getCategoryBySlug } from '../SchoolsView/Activities';
import SeeOtherCategories from '../SeeOtherCategories/SeeOtherCategories';
import Show from '../Show/Show';
import TeacherTraining from '../TeacherTraining/TeacherTraining';
import Typography from '../Typography/Typography';
import styles from './EducationalCategoryView.module.scss';

const EducationalCategoryEmpty = () => {
    return (
        <div>
            <GoBackToSchool />
            <Grid container justify="center">
                <div style={{ marginTop: 100, marginBottom: 100, marginLeft: 25, marginRight: 25, textAlign: "center" }}>
                    <Typography variant="h4">
                        Atividades Não Disponíveis
                    </Typography>
                </div>
            </Grid>

        </div>
    )
}

export default function EducationalCategoryView(props: any) {

    const slug = props.match.params.slug
    const category = getCategoryBySlug(slug)
    const history = useHistory()
    const gtXs = useGtXs()

    const spacing = gtXs ? 10 : 0
    const secondColJustify = gtXs ? "flex-end" : "flex-start"

    if (category?.id === 4) {
        return <TeacherTraining category={category} />
    }

    if (!category) return <EducationalCategoryEmpty />

    const categoryActivities = filterByCategory(activities, category.id)

    if (!categoryActivities.length) return <EducationalCategoryEmpty />

    const handleClick = (activity: EduActivityPreviewType) => {
        history.push(`/educativo/programa-escolas/inscrever/${activity.slug}`)
    }

    return (
        <div>
            <div className={styles.BackToSchool}>
                <GoBackToSchool />
            </div>
            <EducationalHero category={category.name} />
            <Grid container justify="center" className={styles.Wrapper}>
                <main>

                    <Grid container spacing={spacing} className={styles.Wrapper}>
                        <Grid container item xs={12} sm={6} justify="flex-start">
                            <div style={{ width: "90%" }}>
                                <div className={styles.NameWrapper}>
                                    <ActivityName activity={categoryActivities[0]} />
                                </div>
                                <div className={styles.DetailsWrapper}>
                                    <DetailsAndAreas activity={categoryActivities[0]} />
                                </div>
                                <div className={styles.DescriptionWrapper}>
                                    <div dangerouslySetInnerHTML={{ __html: categoryActivities[0].description }} className={styles.Description} />
                                </div>
                                <div className={styles.ActionWrapper}>
                                    <OutlineButton
                                        name="Reservar"
                                        fullWidth
                                        onClick={() => handleClick(categoryActivities[0])}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid container item xs={12} sm={6} justify={secondColJustify}>
                            <Show on={categoryActivities.length > 1}>
                                <div style={{ width: "90%" }}>
                                    <div className={styles.NameWrapper}>
                                        <ActivityName activity={categoryActivities[1]} />
                                    </div>
                                    <div className={styles.DetailsWrapper}>
                                        <DetailsAndAreas activity={categoryActivities[1]} />
                                    </div>
                                    <div className={styles.DescriptionWrapper}>
                                        <div dangerouslySetInnerHTML={{ __html: categoryActivities[1]?.description }} className={styles.Description} />
                                    </div>
                                    <div className={styles.ActionWrapper}>
                                        <OutlineButton
                                            name="Reservar"
                                            fullWidth
                                            onClick={() => handleClick(categoryActivities[1])}
                                        />
                                    </div>
                                </div>
                            </Show>
                        </Grid>
                    </Grid>

                    <SeeOtherCategories category={category} />
                </main>
            </Grid>
        </div>
    )
}
