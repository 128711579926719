import React from 'react'
import Sitemap from '../Sitemap/Sitemap'
import Supporters from '../Supporters/Supporters'

export default function Footer() {
    return (
        <>
            <Sitemap />
            <Supporters />
        </>
    )
}
