import { Grid } from '@material-ui/core';
import React from 'react';
import logo_desktop from '../../assets/images/educational/informations/banner_servico_educativo.webp';
import logo_mobile from '../../assets/images/educational/informations/educational_service_logo_mobile.webp';
import { useGtXs } from '../../hooks/useGtXs';
import styles from './EducationalInformationView.module.scss';

export default function EducationalInformationView() {

    const gtXs = useGtXs()
    const imageUrl = gtXs ? logo_desktop : logo_mobile

    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <main>

                <Grid container justify="space-between" className={styles.Wrapper} alignItems="center">
                    <Grid item xs={12} sm={4} className={styles.Team} direction="column" justify="space-between">
                        <div className={styles.Section}>
                            <p><b>FILIPA GODINHO</b></p>
                            <p>Direção e Coordenação Pedagógica</p>
                        </div>

                        <div className={styles.Section}>
                            <p><b>TIAGO AZEVEDO</b></p>
                            <p>Gestão e Produção</p>
                        </div>
                        <div className={styles.Section}>
                            <p><b>EDUCATIVO@COLISEU.PT</b></p>
                            <p>223 395 092</p>
                        </div>
                        <div className={styles.Section}>
                            <p><b>MORADA</b></p>
                            <p>Rua Passos Manuel 137</p>
                            <p>4000-385 Porto</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={7} className={styles.Banner}>
                        <img src={imageUrl} alt="logo serviço educativo" />
                    </Grid>
                </Grid>
            </main>
        </Grid>
    )
}
