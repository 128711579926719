import 'moment/locale/pt';
import React from 'react';
import Moment from 'react-moment';
import { capitalize } from '../../services/Utils';
import 'moment-timezone';

const FormatDate = ({ date, format, monthCaps }: { date: any, format?: any, monthCaps?: boolean }) => {

    /** For unknown reasons moment is not capitalizing the month, this is a workaround */
    const filter = (date: string): string => {
        if (monthCaps) {
            const parts = date.split(' ')
            parts[parts.length - 1] = capitalize(parts[parts.length - 1])
            return parts.join(' ')
        }
        return date
    }

    return (
        <Moment locale="pt" filter={filter} format={format ? format : "ddd DD MMMM"} tz="Europe/Lisbon">
            {date}
        </Moment>);
};


export default FormatDate;
