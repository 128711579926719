import { Grid, GridJustification, GridSize } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useCollectionCategories from '../../hooks/useCollectionCategories';
import { useGtXs } from '../../hooks/useGtXs';
import Typography from '../Typography/Typography';
import styles from './CollectionCategories.module.scss';

interface CollectionCategoriesProps {
    hideItemId?: number | number[]
    itemsPerRow?: 1 | 2 | 3 | 4
    justify?: GridJustification
}

const getXsValue = (itemsPerRow: number): GridSize => {

    const xsMap: { [key: number]: number } = {
        1: 12,
        2: 6,
        3: 4,
        4: 3
    }

    const xs = xsMap[itemsPerRow] || 12
    return xs as GridSize
}


export default function CollectionCategories(props: CollectionCategoriesProps) {
    const {
        hideItemId = 0,
        itemsPerRow = 4,
        justify = "flex-start"
    } = props

    const { collectionCategories } = useCollectionCategories()

    const history = useHistory()
    const gtXs = useGtXs()
    const xs = getXsValue(itemsPerRow)
    const ids = Array.isArray(hideItemId) ? hideItemId : [hideItemId]

    const categories = collectionCategories.filter(
        collectionCategory => !ids.includes(collectionCategory.id))

    const handleGoTo = (slug?: string) => {
        history.push(`${slug}`)
    }

    const variant = gtXs ? "RocGroteskBold25" : "RocGroteskBold20"

    return (
        <Grid container justify={justify} className={styles.Wrapper} wrap="wrap" spacing={2}>
            {categories?.map(category => {
                return (
                    <Grid item xs={xs}>
                        <img src={category?.thumbnail}
                            onClick={() => handleGoTo(category.slug)}
                            alt={category.name}
                        />
                        <Typography variant={variant}>
                            {category.name.toLocaleUpperCase()}
                        </Typography>
                    </Grid>
                )
            })}
        </Grid >
    )
}
